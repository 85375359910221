import { Flex, FlexProps, Skeleton } from "@chakra-ui/react";
import { NotAllowedIcon } from "@chakra-ui/icons";
import { ReactNode, useEffect, useState } from "react";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";

type TabProps = {
    tabIndex: number;
    scale?: number;
    isSelected?: boolean;
    isDisabled?: boolean;
    isSkeleton?: boolean;
    onTabSelect?: (tabIndex: any, event?: any) => void;
    children: ReactNode;
};

export const Tab = ({
    tabIndex,
    scale = 1,
    isSelected = false,
    isDisabled = false,
    isSkeleton = false,
    onTabSelect,
    children,
    ...props
}: TabProps & FlexProps) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleOnMouseEnter = (e) => {
        setIsHovering(true);
    };

    const handleOnMouseLeave = (e) => {
        setIsHovering(false);
    };

    const handleOnClick = (e) => {
        if (!isSelected && !isDisabled && onTabSelect) {
            onTabSelect(tabIndex, e);
        }
    };

    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    return (
        <>
            <Skeleton flex={1} isLoaded={!isSkeleton} borderTopRadius={scaledPixel(15)}>
                <Flex
                    flex={1}
                    position="relative"
                    p={scaledPixel(8)}
                    borderTopRadius={scaledPixel(8)}
                    // borderTop={`${scaledPixel(2)} solid`}
                    // borderTopColor={isSelected && !isDisabled ? "sorbet" : "transparent"}
                    bgColor={isSelected && !isDisabled ? "spaceCadet" : "spaceCadetLight"}
                    justifyContent="center"
                    alignItems="center"
                    onClick={handleOnClick}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                    cursor={isDisabled || isSelected ? undefined : "pointer"}
                    color={isDisabled ? "#fff2" : isSelected ? "white" : "rythm"}
                    transition="none"
                    {...props}
                >
                    {children}
                    {/* {isDisabled && isHovering ? (
                    <NotAllowedIcon position="absolute" color="sorbet" fontSize={`${(scalePercent + 50)}%`}/>
                ) : null} */}
                </Flex>
            </Skeleton>
        </>
    );
};

export default Tab;
