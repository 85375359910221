import React, { forwardRef, useImperativeHandle } from "react";
import { useRadio, useRadioGroup, Box, Center, Flex, BoxProps, Icon } from "@chakra-ui/react";
import { getDefaultSortOptions } from "@store/sessionStore";
import { MdLock } from "react-icons/md";

const GroupRadioOption = (props) => {
    const { index, amount, children, isDisabled } = props;
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    let borderStyle: BoxProps = {
        borderColor: "steelblue.900 !important",
        borderRight: "1px solid",
    };

    if (index === 0) {
        borderStyle.borderLeftRadius = "md";
    } else if (index === amount - 1) {
        borderStyle.borderRightRadius = "md";
        borderStyle.borderRight = "none";
    }

    return (
        <Box as="label" position="relative" w="33.3333%">
            <input {...input} />
            <Center
                {...checkbox}
                {...borderStyle}
                opacity={isDisabled ? 0.7 : 1}
                cursor={isDisabled ? "default" : "pointer"}
                h="24px"
                minW="88px"
                fontSize="12px"
                color={isDisabled ? "steelblue.400" : "white"}
                bg={isDisabled ? "steelblue.900" : "lightblue.500"}
                _checked={{
                    ring: "none",
                    bg: "gold.500",
                    color: "blue.darker",
                }}
                _focus={{
                    ring: "none",
                }}
            >
                {children}
            </Center>
        </Box>
    );
};

const GroupedRadio = forwardRef<GroupedRadioExposedMethods, GroupedRadioProps>(
    ({ options, onChange, currentSortBy }: GroupedRadioProps, ref): JSX.Element => {
        const { getRootProps, getRadioProps } = useRadioGroup({
            name: "sort",
            defaultValue: currentSortBy,
            onChange: onChange,
        });

        useImperativeHandle(ref, () => ({
            setDefaultValue: (): void => {
                const { sortCriteria } = getDefaultSortOptions();

                // @ts-ignore: Wrong ChakraUI type definition for onChange!
                getRadioProps().onChange(sortCriteria);
            },
        }));

        const group = getRootProps();

        return (
            <Flex
                {...group}
                gap={0}
                borderRadius="md"
                overflow="hidden"
                boxShadow="0px 1px 4px rgba(0, 0, 0, 0.5)"
            >
                {options.map((option, index) => {
                    const radio = getRadioProps({ value: option.value });

                    return (
                        <GroupRadioOption
                            key={option.value}
                            index={index}
                            amount={options.length}
                            isDisabled={option.isDisabled}
                            {...radio}
                        >
                            <Box pos="relative">
                                {option.label}
                                {option.isLocked ? (
                                    <Flex
                                        pos="absolute"
                                        w="100%"
                                        h="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        top={0}
                                    >
                                        <Icon as={MdLock} color="white" boxSize="16px" />
                                    </Flex>
                                ) : null}
                            </Box>
                        </GroupRadioOption>
                    );
                })}
            </Flex>
        );
    },
);

GroupedRadio.displayName = "GroupedRadio";

export default GroupedRadio;
