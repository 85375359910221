import { Box, BoxProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type TokenHotColdVerticalBandProps = {
    show: boolean;
    isHot: boolean;
}

export const TokenHotColdVerticalBand = ({show, isHot, ...props} : TokenHotColdVerticalBandProps & BoxProps) => {
    if (!show) return null;
    return (
        <>
            <Box
                position="absolute"
                bgGradient={
                    isHot
                        ? "linear(to-b, hotGradient.start 8%, hotGradient.end 20%, #FA960000 70%)"
                        : "linear(to-b, coldGradient.start 10%, coldGradient.end 20%,, #AFD7F800 70%)"
                }
                width="6px"
                height="100%"
                {...props}
            />
        </>
    );
}

export default TokenHotColdVerticalBand;