import React, { useState, useRef } from "react";
import { Box, Center, HStack, VStack, Radio, RadioGroup, Text } from "@chakra-ui/react";
import shallow from "zustand/shallow";
import useFiltersStore, { setSortValues } from "@store/filtersStore";
import { getDefaultSortOptions } from "@store/sessionStore";
import { Button, GroupedRadio } from "@components/ui";
import { getSortOrderLabels } from "@utils/helpers";

const TokenListSortMenu = ({ options, isMobile, onApply }: TokenListSortMenuProps): JSX.Element => {
    const { sortBy, sortOrder } = useFiltersStore(
        (state) => ({
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
        }),
        shallow,
    );

    const { sortCriteria: defaultSortBy, sortOrder: defaultSortOrder } = getDefaultSortOptions();

    const sortRef = useRef<GroupedRadioExposedMethods>();
    const [userSortBy, setUserSortBy] = useState<string | SortCriteria>(sortBy);
    const [userSortOrder, setUserSortOrder] = useState<string | SortOrder>(sortOrder);

    const onOrderChange = (order: SortOrder) => {
        setUserSortOrder(order);
    };

    const onDefaultClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        const { sortOrder } = getDefaultSortOptions();
        if (sortRef.current) sortRef.current.setDefaultValue();
        setUserSortOrder(sortOrder);
    };

    const onApplyClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        setSortValues(userSortBy as SortCriteria, userSortOrder as SortOrder);
        onApply?.();
    };

    const layerStyle = !isMobile ? "sortMenu.desktop" : "sortMenu.mobile";
    const applyDisabled = sortBy === userSortBy && sortOrder === userSortOrder;
    const resetDisabled = defaultSortBy === userSortBy && defaultSortOrder === userSortOrder;

    const { orderLabel: ascOrderLabel } = getSortOrderLabels(userSortBy as SortCriteria, "asc");
    const { orderLabel: descOrderLabel } = getSortOrderLabels(userSortBy as SortCriteria, "desc");

    return (
        <HStack layerStyle={layerStyle}>
            <VStack gap={3} w="100%">
                <Box w="100%">
                    <GroupedRadio
                        ref={sortRef}
                        options={options}
                        onChange={setUserSortBy}
                        currentSortBy={sortBy}
                    />
                </Box>
                <Center w="100%">
                    <RadioGroup
                        name="order"
                        onChange={onOrderChange}
                        value={userSortOrder || sortOrder}
                    >
                        <HStack gap={4}>
                            <Radio value="asc">
                                <Text textStyle="traitOption">{ascOrderLabel}</Text>
                            </Radio>
                            <Radio value="desc">
                                <Text textStyle="traitOption">{descOrderLabel}</Text>
                            </Radio>
                        </HStack>
                    </RadioGroup>
                </Center>
                <HStack w="170px">
                    <Button
                        size="sm"
                        variant="solid"
                        fullWidth
                        onClick={onDefaultClick}
                        disabled={resetDisabled}
                    >
                        Default
                    </Button>
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="gold"
                        fullWidth
                        onClick={onApplyClick}
                        disabled={applyDisabled}
                    >
                        Apply
                    </Button>
                </HStack>
            </VStack>
        </HStack>
    );
};

export default TokenListSortMenu;
