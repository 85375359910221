import React, { useState, useRef, useEffect } from "react";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { NumericInput, Button, FilterInputTitle } from "@components/ui";
import useFiltersStore, { resetRankFilter, setRankFilter } from "@store/filtersStore";
import useCollectionTokensStore from "@store/collectionTokensStore";

const RankFilters = ({}: RankFiltersProps): JSX.Element => {
    const minValueInput = useRef<NumericInputExposedMethods>();
    const maxValueInput = useRef<NumericInputExposedMethods>();
    const currentMinRank = useFiltersStore((state) => state.minRank);
    const currentMaxRank = useFiltersStore((state) => state.maxRank);
    const collectionMinRank = useCollectionTokensStore((state) => state.topRank);
    const collectionMaxRank = useCollectionTokensStore((state) => state.bottomRank);

    const [minRank, setMinRank] = useState<number>(collectionMinRank);
    const [maxRank, setMaxRank] = useState<number>(collectionMaxRank);
    const [applyBtnEnabled, setApplyBtnEnabled] = useState<boolean>(true);

    const resetButtonDisabled = currentMinRank === null && currentMaxRank === null;

    useEffect(() => {
        minValueInput.current?.setValue(collectionMinRank);
        maxValueInput.current?.setValue(collectionMaxRank);
    }, [collectionMinRank, collectionMaxRank]);

    useEffect(() => {
        if (currentMinRank === minRank && currentMaxRank === maxRank) {
            if (applyBtnEnabled) setApplyBtnEnabled(false);
        } else if (!applyBtnEnabled) {
            setApplyBtnEnabled(true);
        }
    }, [currentMinRank, currentMaxRank, minRank, maxRank]);

    const onMinChange = (value: number): void => {
        setMinRank(value);
    };

    const onMaxChange = (value: number): void => {
        setMaxRank(value);
    };

    const onResetClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        minValueInput.current?.setValue(collectionMinRank);
        maxValueInput.current?.setValue(collectionMaxRank);
        resetRankFilter();
    };

    const onApplyClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        setRankFilter(minRank, maxRank);
    };

    const onTop1Click = (e: React.MouseEvent<HTMLButtonElement>): void => {
        const newMinValue = collectionMinRank;
        const newMaxValue = Math.round(collectionMaxRank * 0.01);

        minValueInput.current?.setValue(newMinValue);
        maxValueInput.current?.setValue(newMaxValue);

        setRankFilter(newMinValue, newMaxValue /*, "Top 1%"*/);
    };

    const onBottom1Click = (e: React.MouseEvent<HTMLButtonElement>): void => {
        const newMinValue = collectionMaxRank - Math.round(collectionMaxRank * 0.01);
        const newMaxValue = collectionMaxRank;

        minValueInput.current?.setValue(newMinValue);
        maxValueInput.current?.setValue(newMaxValue);

        setRankFilter(newMinValue, newMaxValue /*, "Bottom 1%"*/);
    };

    return (
        <VStack alignItems="flex-start" w="100%">
            <VStack gap={2} w="100%">
                <HStack w="100%">
                    <Box w="50%">
                        <FilterInputTitle label="Min" />
                        <NumericInput
                            ref={minValueInput}
                            minValue={collectionMinRank}
                            maxValue={maxRank}
                            defaultValue={minRank}
                            onChange={onMinChange}
                        />
                    </Box>
                    <Box w="50%">
                        <FilterInputTitle label="Max" />
                        <NumericInput
                            ref={maxValueInput}
                            minValue={minRank}
                            maxValue={collectionMaxRank}
                            defaultValue={maxRank}
                            onChange={onMaxChange}
                        />
                    </Box>
                </HStack>
                <HStack w="100%">
                    <Button
                        size="sm"
                        variant="solid"
                        fullWidth
                        disabled={resetButtonDisabled}
                        onClick={onResetClick}
                    >
                        Reset
                    </Button>
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="gold"
                        fullWidth
                        disabled={!applyBtnEnabled}
                        onClick={onApplyClick}
                    >
                        Apply
                    </Button>
                </HStack>
            </VStack>
            <Box w="100%">
                <FilterInputTitle label="Shortcuts" />
                <HStack alignItems="flex-start">
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="dimmed"
                        fullWidth
                        onClick={onTop1Click}
                    >
                        Top 1%
                    </Button>
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="dimmed"
                        fullWidth
                        onClick={onBottom1Click}
                    >
                        Bottom 1%
                    </Button>
                </HStack>
            </Box>
        </VStack>
    );
};

export default RankFilters;
