import React, { useRef } from "react";
import { Accordion, CloseButton, Box, Icon, Text, Flex } from "@chakra-ui/react";
import {
    TraitsFilterAccordion,
    SaleStatusFilter,
    RankFilters,
    PriceFilters,
    MinDeviationFilters,
    VibesFilters,
    TokenListSortAccordion,
    MobileBreadcrumbs,
    FiltersButton,
    AddTokenInput,
    SkeletonBox,
} from "@components/ui";
import { FilterExpandable } from "@components/ui";
import { FaSortAmountDown, FaFilter } from "react-icons/fa";
import useSessionStore from "@store/sessionStore";
import useCollectionTokensStore from "@store/collectionTokensStore";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { disableBodyScroll, enableBodyScroll } from "@utils/helpers";
import shallow from "zustand/shallow";
import useIsMounted from "@hooks/useIsMounted";
import MarketplaceFilters from "./MarketplaceFilters";

type MobileFiltersDrawerProps = {};

const MobileFiltersDrawer = ({}: MobileFiltersDrawerProps): JSX.Element => {
    const isMounted = useIsMounted();
    const drawerEl = useRef<HTMLDivElement>();
    const drawerOpen = useRef<boolean>(false);
    const scrollEl = useRef<HTMLDivElement>();
    const closeBtn = useRef<HTMLButtonElement>();
    const isConnected = useSessionStore((state) => state.connected);
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });
    const { startTokenId, endTokenId, loading } = useCollectionTokensStore(
        (state) => ({
            startTokenId: state.startTokenId,
            endTokenId: state.endTokenId,
            loading: state.loading,
        }),
        shallow,
    );

    const onOpenDrawer = () => {
        if (!drawerOpen.current) {
            drawerOpen.current = true;
            disableBodyScroll();
            if (drawerEl.current) {
                if (scrollEl.current) scrollEl.current.scrollTo(0, 0);
                drawerEl.current.style.transform = "translateX(0%)";
                closeBtn.current.style.display = "block";
                closeBtn.current.style.pointerEvents = "auto";
            }
        }
    };

    const onCloseDrawer = () => {
        if (drawerOpen.current) {
            drawerOpen.current = false;
            enableBodyScroll();

            if (drawerEl.current) {
                drawerEl.current.style.transform = "translateX(-100%)";
                closeBtn.current.style.display = "none";
                closeBtn.current.style.pointerEvents = "none";
            }
        }
    };

    if (!isMounted()) return null;

    const renderSortOption = (): JSX.Element =>
        !loading ? (
            <>
                <Box color="gray.faint" mb={1}>
                    <Icon as={FaSortAmountDown} pb="2px" mr="4px" />
                    <Text as="span" verticalAlign="text-bottom">
                        Sorting
                    </Text>
                </Box>
                <TokenListSortAccordion />
            </>
        ) : (
            <Flex flexDirection="column" gap={1} w="100%" mb={1}>
                <SkeletonBox h="23px" w="33%" mb={1} />
                <SkeletonBox h="39px" />
            </Flex>
        );

    const renderFilters = (): JSX.Element =>
        !loading ? (
            <>
                <Box color="gray.faint" mb={1}>
                    <Icon as={FaFilter} pb="2px" mr="4px" />
                    <Text as="span" verticalAlign="text-bottom">
                        Filters
                    </Text>
                </Box>
                <Box w="100%">
                    <SaleStatusFilter />
                </Box>
                <Box w="100%" mt={3} mb={4}>
                    <AddTokenInput
                        placeholder={`Add token ID (${startTokenId} - ${endTokenId})`}
                        min={startTokenId}
                        max={endTokenId}
                    />
                </Box>
                <Accordion
                    allowMultiple
                    rounded="lg"
                    mb={6}
                    reduceMotion={!isDesktop}
                    defaultIndex={[]}
                >
                    <FilterExpandable label="Rarity Rank">
                        <RankFilters />
                    </FilterExpandable>
                    <FilterExpandable label="Price">
                        <PriceFilters />
                    </FilterExpandable>
                    <FilterExpandable label="Min Absolute Deviation" disabled={!isConnected}>
                        <MinDeviationFilters />
                    </FilterExpandable>
                    <FilterExpandable label="Marketplace">
                        <MarketplaceFilters />
                    </FilterExpandable>
                </Accordion>
            </>
        ) : (
            <Flex flexDirection="column" gap={1} w="100%" mb={6} mt={6}>
                <SkeletonBox h="23px" w="33%" mb={1} />
                <SkeletonBox height="30px" mb={2} rounded="50em" />
                <SkeletonBox h="34px" mb={3} />
                <SkeletonBox h="38px" mb={1} />
                <SkeletonBox h="38px" mb={1} />
                <SkeletonBox h="38px" />
            </Flex>
        );

    return (
        <>
            <FiltersButton onClick={onOpenDrawer} position="fixed" bottom="10px" right="10px" />
            <CloseButton
                ref={closeBtn}
                display="none"
                pointerEvents="none"
                position="fixed"
                bottom="10px"
                right="10px"
                zIndex={10000}
                w="40px"
                h="40px"
                rounded="50em"
                bg="gold.500"
                color="blue.darker"
                _hover={{
                    bg: "gold.700",
                }}
                _focus={{
                    ring: 0,
                    bg: "gold.700",
                }}
                boxShadow="0px 0px 20px 10px #0009"
                onClick={onCloseDrawer}
            />

            <Box ref={drawerEl} layerStyle="filters.drawerContainer">
                <Box
                    ref={scrollEl}
                    layerStyle="filters.drawerScroll"
                    overflowY={!loading ? "auto" : "hidden"}
                >
                    <MobileBreadcrumbs loading={loading} />

                    <Box px={3} w="100%">
                        {renderSortOption()}
                        {renderFilters()}
                        <TraitsFilterAccordion label="Traits" w="100%" loading={loading} />
                        {!loading && <VibesFilters label="Vibes" mt={8} />}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

MobileFiltersDrawer.displayName = "MobileFiltersDrawer";

export default MobileFiltersDrawer;
