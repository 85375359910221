import React, { useEffect, useState } from "react";
import {
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
    Flex,
    Box,
} from "@chakra-ui/react";
import { TraitValuesFiltersList, TraitsValuesFiltersSearchInput } from "@components/ui";
import useFiltersStore from "@store/filtersStore";
import RankedNumericallyTooltipIcon from "./ui/RankedNumericallyTooltipIcon";

const TraitFiltersGroup = ({
    id,
    name,
    rankedNumerically = false,
    values,
    search = "",
    onAddFilter,
    onRemoveFilter,
}: TraitFiltersGroupProps): JSX.Element => {
    const selectedTraitValues = useFiltersStore((state) => state.traits.get(id)?.values);
    const [groupSearch, setGroupSearch] = useState<string>("");
    const [filteredTraits, setFilteredTraits] = useState(values);

    useEffect(() => {
        if (search || groupSearch) {
            onSearchChange(search || groupSearch);
        } else {
            setFilteredTraits(values);
        }
    }, [values]);

    useEffect(() => {
        if (filteredTraits !== values) setFilteredTraits(values);
        if (search) onSearchChange(search);
    }, [search]);

    const onSearchChange = (value: string): void => {
        if (value) {
            const filteredTraitsList: TraitValuesMap = new Map();

            for (let [traitValueId, traitValueData] of values.entries()) {
                if (traitValueData.label?.toString().toLowerCase().includes(value))
                    filteredTraitsList.set(traitValueId, traitValueData);
            }

            setFilteredTraits(filteredTraitsList);
        }
    };

    const onSearchInputClear = (): void => {
        setGroupSearch("");
        setFilteredTraits(values);
    };

    const onSearchInputChange = (value: string): void => {
        setGroupSearch(value);
        onSearchChange(value);
    };

    if (search?.length > 0 && (!filteredTraits || !filteredTraits.size)) return null;

    return (
        <AccordionItem layerStyle="filters.traits.accordion.item">
            <AccordionButton
                layerStyle="filters.traits.accordion.buttons"
                _focus={{ ring: "none" }}
                px={3}
            >
                <Flex layerStyle="filters.traits.accordion.labelContainer" fontFamily="regular">
                    <Flex justify="center" align="center" gap={1}>
                        <Box
                            layerStyle="filters.traits.accordion.buttonLabel"
                            noOfLines={1}
                            title={name}
                        >
                            {name}
                        </Box>
                        {rankedNumerically && <RankedNumericallyTooltipIcon usePortal />}
                    </Flex>
                    <Text layerStyle="filters.traits.accordion.labelCount">
                        ({filteredTraits?.size || 0})
                    </Text>
                </Flex>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
                {!search && (
                    <TraitsValuesFiltersSearchInput
                        groupName={name}
                        onChange={onSearchInputChange}
                        onClear={onSearchInputClear}
                    />
                )}
                <TraitValuesFiltersList
                    traitId={id}
                    rankedNumerically={rankedNumerically}
                    values={filteredTraits}
                    selectedValues={selectedTraitValues}
                    onTraitValueChecked={onAddFilter}
                    onTraitValueUnchecked={onRemoveFilter}
                />
            </AccordionPanel>
        </AccordionItem>
    );
};

export default TraitFiltersGroup;
