import React from "react";
import TokenListDesktop from "./TokenListDesktop";
import TokenListMobile from "./TokenListMobile";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";

const TokenList = (): JSX.Element => {
    const { isDesktop } = useDebouncedWindowSize(
        { useIsDesktop: true },
        // { debounceRateMilliseconds: 0 },
    );
    if (isDesktop) return <TokenListDesktop />;
    return <TokenListMobile />;
};

export default TokenList;
