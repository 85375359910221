import React from "react";
import { Box } from "@chakra-ui/react";

const Separator = ({ orientation = "vertical" }: SeparatorProps): JSX.Element => {
    if (orientation === "vertical")
        return (
            <Box
                borderLeft="1px solid #979797"
                borderRight="1px solid #020202"
                alignSelf="stretch"
                opacity={0.3}
            />
        );

    return (
        <Box
            borderTop="1px solid #020202"
            borderBottom="1px solid #979797"
            alignSelf="stretch"
            opacity={0.3}
        />
    );
};

export default Separator;
