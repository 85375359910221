import React, { useRef, useState } from "react";
import {
    InputGroup,
    Input,
    InputLeftElement,
    InputRightElement,
    IconButton,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";

const TraitsValuesFiltersSearchInput = ({
    groupName,
    onChange,
    onClear,
}: TraitsValuesFiltersSearchInputProps): JSX.Element => {
    const searchInput = useRef<HTMLInputElement>(null);
    const [hasSearch, setHasSearch] = useState(false);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target?.value?.trim().toLowerCase();

        if (value) {
            if (!hasSearch) setHasSearch(true);
            onChange?.(value);
        } else {
            onClear?.();
            if (hasSearch) setHasSearch(false);
        }
    };

    const onClearSearch = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (searchInput.current) {
            searchInput.current.value = "";
            setHasSearch(false);
            onClear?.();
        }
    };

    return (
        <InputGroup mb={3}>
            <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" h="20px" />
            </InputLeftElement>
            <Input
                ref={searchInput}
                placeholder={`Search ${groupName.toLowerCase()}`}
                rounded="4px"
                onChange={onSearchChange}
                borderColor="#6D707D"
                _hover={{ borderColor: "steelblue.100" }}
                _focus={{ borderColor: "gold.900", boxShadow: "none" }}
                _active={{ borderColor: "gold.900", boxShadow: "none" }}
            />
            {hasSearch && (
                <InputRightElement>
                    <IconButton
                        icon={<CloseIcon />}
                        color="gray.300"
                        h="20px"
                        aria-label="Search"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _focus={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        onClick={onClearSearch}
                    />
                </InputRightElement>
            )}
        </InputGroup>
    );
};

export default TraitsValuesFiltersSearchInput;
