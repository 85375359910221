import { useEffect, useState } from "react";
import { Flex, FlexProps, useMediaQuery } from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";

type SortColumnProps = {
    cId: number;
    buttonSize?: string | number;
    acitveColor?: string;
    inActiveColor?: string;
    hoverColor?: string;
    sortOrder?: "asc" | "desc" | "none";
    afterToggle?: Function;
    clickOnColumnName?: boolean;
};

export const SortColumn = ({
    cId,
    buttonSize,
    acitveColor = "lavenderGray",
    inActiveColor = "#fff3",
    hoverColor = "gold.500",
    sortOrder = "none",
    afterToggle,
    clickOnColumnName = true,
    children,
    fontSize,
    ...props
}: SortColumnProps & FlexProps) => {
    const [internalSortOrder, setInternalSortOrder] = useState(sortOrder);
    const [isHovering, setIsHovering] = useState(false);
    const [hasHover] = useMediaQuery(["(hover: hover)"]);

    // console.debug("hasHover", hasHover);

    useEffect(() => {
        setInternalSortOrder(sortOrder);
    }, [sortOrder]);

    const handleOnMouseEnter = (e) => {
        setIsHovering(true);
    };

    const handleOnMouseLeave = (e) => {
        setIsHovering(false);
    };

    const handleOnClick = (e) => {
        e.stopPropagation();

        let newSortOrder;
        if (internalSortOrder == "none" || internalSortOrder == "desc") newSortOrder = "asc";
        else if (internalSortOrder == "asc") newSortOrder = "desc";

        setInternalSortOrder(newSortOrder);
        if (afterToggle) afterToggle(cId, newSortOrder, e);
    };

    const ColumnNamePart = () => {
        if (clickOnColumnName) {
            return (
                <>
                    <Flex
                        _hover={{ cursor: "pointer" }}
                        onClick={handleOnClick}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    >
                        {children}
                    </Flex>
                </>
            );
        }

        return (
            <>
                <Flex>{children}</Flex>
            </>
        );
    };

    return (
        <>
            <Flex alignItems="center" gap={1} fontFamily="regular" fontSize={fontSize} color={acitveColor} {...props}>
                <ColumnNamePart />
                <Flex
                    direction="column"
                    h={buttonSize ? buttonSize : fontSize ? fontSize : "1.5em"}
                    _hover={{ cursor: "pointer" }}
                    onClick={handleOnClick}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                >
                    <TriangleUpIcon
                        h="50%"
                        color={
                            hasHover && isHovering && internalSortOrder != "asc"
                                ? hoverColor
                                : internalSortOrder == "asc"
                                ? acitveColor
                                : inActiveColor
                        }
                    />
                    <TriangleDownIcon
                        h="50%"
                        color={
                            hasHover && isHovering && internalSortOrder == "asc"
                                ? hoverColor
                                : internalSortOrder == "desc"
                                ? acitveColor
                                : inActiveColor
                        }
                    />
                </Flex>
            </Flex>
        </>
    );
};

export default SortColumn;
