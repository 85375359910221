import React from "react";
import {
    Box,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";

type FilterExpandableProps = {
    label: string;
    disabled?: boolean;
    children: React.ReactNode;
};

const FilterExpandable = ({ label, disabled, children }: FilterExpandableProps): JSX.Element => {
    return (
        <AccordionItem border="1px solid #464E6C" rounded="md" mb={2} bg="spaceCadetLight">
            <AccordionButton _focus={{ ring: "none" }} py={2}>
                <Box
                    flex="1"
                    textAlign="left"
                    fontSize="14px"
                    fontFamily="regular"
                    h="20px"
                    color={disabled ? "whiteAlpha.500" : undefined}
                >
                    {label}
                </Box>
                <AccordionIcon color={disabled ? "whiteAlpha.500" : undefined} />
            </AccordionButton>
            <AccordionPanel pb={4}>{children}</AccordionPanel>
        </AccordionItem>
    );
};

export default FilterExpandable;
