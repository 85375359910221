import React, { useState } from "react";
import { Accordion, Box, Flex, Text } from "@chakra-ui/react";
import { SkeletonBox, TraitFiltersGroup, TraitsValuesFiltersSearchInput } from "@components/ui";
import { addTraitFilter, removeTraitFilter } from "@store/filtersStore";
import useCollectionTokensStore from "@store/collectionTokensStore";
import useCollectionStore from "@store/collectionStore";
import debounce from "lodash.debounce";

const TraitsFilterAccordion = ({
    label,
    loading,
    ...props
}: TraitsFilterAccordionProps): JSX.Element => {
    const allTraitsMap = useCollectionStore((state) => state.traitsMap);
    const filteredTraitsMap = useCollectionTokensStore((state) => state.filteredTraitsMap);
    const [search, setSearch] = useState<string>("");

    // use the filtered traits map, if doesn't exist use all the traits (no filters applied)
    const traitsMap = filteredTraitsMap || allTraitsMap;

    const onAddFilter = (traitId: TraitId, traitValueId: TraitValueId): void => {
        const trait = traitsMap.get(traitId);
        const value = trait.values.get(traitValueId);

        // console.debug("trait.rankedNumerically: ", trait.rankedNumerically);

        if (trait && value)
            addTraitFilter(
                { id: trait.id, name: trait.name, rankedNumerically: trait.rankedNumerically },
                { id: value.id, label: value.label },
            );
    };
    const onRemoveFilter = (traitId: TraitId, traitValueId: TraitValueId): void => {
        removeTraitFilter(traitId, traitValueId);
    };

    const onDebouncedChangeHandler = React.useMemo(() => debounce(setSearch, 200), []);

    const onSearchClear = () => {
        setSearch("");
    };

    if (loading) {
        return (
            <Box {...props} overflow="hidden">
                <SkeletonBox h="24px" w="33%" mb={2} />
                {[...Array(22)].map((e, i) => (
                    <SkeletonBox h="32px" mb={3} key={`sk-${i}`} />
                ))}
            </Box>
        );
    }

    if (!traitsMap || !traitsMap.size) {
        return (
            <Box {...props} overflow="hidden">
                <Text fontFamily="regular" color="rythm" mb={2}>
                    {label}
                </Text>
                <Flex layerStyle="filters.traits.accordion.labelContainer" fontFamily="regular" fontSize="12px">
                    <Flex justify="center" align="center" gap={1}>
                        <Box
                            layerStyle="filters.traits.accordion.buttonLabel"
                            noOfLines={1}
                            color="rythm"
                        >
                            This collection has no traits.
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        );
    }

    return (
        <Box {...props}>
            <Text fontFamily="regular" color="rythm" mb={2}>
                {label}
            </Text>
            <TraitsValuesFiltersSearchInput
                groupName="All Traits"
                onChange={onDebouncedChangeHandler}
                onClear={onSearchClear}
            />
            <Accordion allowMultiple rounded="lg" gap={2} reduceMotion={false} defaultIndex={[]}>
                {[...traitsMap].map(([traitId, traitData]) => (
                    <TraitFiltersGroup
                        id={traitId}
                        key={traitId}
                        name={traitData.name}
                        rankedNumerically={traitData.rankedNumerically}
                        values={traitData.values}
                        search={search}
                        onAddFilter={onAddFilter}
                        onRemoveFilter={onRemoveFilter}
                    />
                ))}
            </Accordion>
        </Box>
    );
};

export default TraitsFilterAccordion;
