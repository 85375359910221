import React, { useState, useRef, useEffect } from "react";
import { Box, Flex, HStack, Icon, VStack } from "@chakra-ui/react";
import { NumericInput, Button, FilterInputTitle } from "@components/ui";
import useFiltersStore, {
    resetMinDeviationFilter,
    setMinDeviationFilter,
} from "@store/filtersStore";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { useSessionStore } from "@store/sessionStore";
import { MdLock } from "react-icons/md";
import MinDeviationFilterTooltip from "./MinDeviationFilterTooltip";

const MinDeviationFilters = ({}: MinDeviationFiltersProps): JSX.Element => {
    const isConnected = useSessionStore((state) => state.connected);
    const currentMinDeviation = useFiltersStore((state) => state.minDeviation);
    const highestDeviation: number = useCollectionTokensStore((state) =>
        Math.ceil(state.highestDeviation),
    );

    // const lowestDeviation: number = useCollectionTokensStore((state) =>
    //     Math.floor(state.lowestDeviation),
    // );

    const minDeviationInput = useRef<NumericInputExposedMethods>();

    const [minDeviation, setMinDeviation] = useState<number | undefined>();
    const [applyBtnEnabled, setApplyBtnEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (currentMinDeviation === minDeviation) {
            if (applyBtnEnabled) setApplyBtnEnabled(false);
        } else if (!applyBtnEnabled && minDeviation !== undefined) {
            setApplyBtnEnabled(true);
        }
    }, [currentMinDeviation, minDeviation]);

    const onChange = (value: number): void => {
        if (!applyBtnEnabled) {
            setApplyBtnEnabled(true);
        }
        setMinDeviation(value);
    };

    // the input value is non numeric
    const onError = (): void => {
        setApplyBtnEnabled(false);
    };

    const onClearClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        minDeviationInput.current?.setValue(0);
        resetMinDeviationFilter();
        setMinDeviation(undefined);
        setApplyBtnEnabled(false);
    };

    const onApplyClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        if (minDeviation !== undefined) {
            setMinDeviationFilter(minDeviation);
        }
    };

    useEffect(() => {
        if (!isConnected) onClearClick(null);
    }, [isConnected]);

    return (
        <Box pos="relative">
            <VStack display="inline-flex" alignItems="flex-start" gap={2} w="100%">
                <Box w="100%">
                    <FilterInputTitle label="Min Absolute Deviation" showEthIcon />
                    <NumericInput
                        ref={minDeviationInput}
                        minValue={0}
                        maxValue={highestDeviation}
                        defaultValue={0}
                        onChange={onChange}
                        onError={onError}
                        disabled={!isConnected}
                    />
                </Box>
                <HStack w="100%">
                    <Button
                        size="sm"
                        variant="solid"
                        fullWidth
                        disabled={currentMinDeviation === null || !isConnected}
                        onClick={onClearClick}
                    >
                        Clear
                    </Button>
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="gold"
                        fullWidth
                        disabled={!applyBtnEnabled || !isConnected}
                        onClick={onApplyClick}
                    >
                        Apply
                    </Button>
                </HStack>
                <Flex w="100%" justifyContent="end" alignItems="center" color="spaceCadet">
                    <MinDeviationFilterTooltip />
                </Flex>
            </VStack>
            {isConnected ? null : (
                <Flex
                    pos="absolute"
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    top="calc(50% - 32px)"
                    // zIndex={10}
                >
                    <Icon as={MdLock} color="white" boxSize="32px" />
                </Flex>
            )}
        </Box>
    );
};

export default MinDeviationFilters;
