import { Box, Flex } from "@chakra-ui/react";
import { TokenListItemSkeleton } from "@components/ui";
import React from "react";

type TokenListSkeletonProps = {
    isDesktop: boolean;
};

const TokenListSkeleton = ({ isDesktop }: TokenListSkeletonProps): JSX.Element => {
    if (isDesktop) {
        return (
            <Box w="100%" h="100%" p="0 10px" mb="5px" overflow="hidden" position="relative">
                <Box w="100%" h="100%" position="absolute" overflow="hidden">
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                    <TokenListItemSkeleton isDesktop={isDesktop} />
                </Box>
            </Box>
        );
    } else {
        return (
            <Flex
                h="215px"
                w="100%"
                maxW="100%"
                gap="22px"
                overflow="hidden"
                bg="blue.darker"
                p="14px 10px 10px 14px"
                mb="26px"
            >
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
                <TokenListItemSkeleton isDesktop={isDesktop} />
            </Flex>
        );
    }
};

export default TokenListSkeleton;
