import React, { useState, useEffect } from "react";
import { Box, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { TraitFilterCheckbox, TraitsValuesFiltersSearchInput } from "@components/ui";
import useCollectionStore from "@store/collectionStore";
import useFiltersStore, { addVibeFilter, removeVibeFilter } from "@store/filtersStore";

const VibesFilters = ({ label, ...props }: VibesFiltersProps): JSX.Element => {
    const vibes: Vibe[] = useCollectionStore((state) => state.vibes);
    const filteredVibes = useFiltersStore((state) => state.vibes);
    const [vibesList, setVibesList] = useState<Vibe[]>(() => vibes);

    useEffect(() => {
        setVibesList(vibes);
    }, [vibes]);

    const onAddVibeFilter = (vibeId: VibeId): void => {
        addVibeFilter(vibeId);
    };

    const onRemoveVibeFilter = (vibeId: VibeId): void => {
        removeVibeFilter(vibeId);
    };

    const onSearchChange = (value: string): void => {
        if (value) {
            value = value.toLowerCase();

            const filteredVibesList: Vibe[] = vibes.filter((vibe) =>
                vibe.name.toLowerCase().includes(value),
            );
            setVibesList(filteredVibesList);
        }
    };

    const onSearchClear = (): void => {
        setVibesList(vibes);
    };

    if (!vibes || !vibes.length) return null;

    return (
        <Box {...props}>
            <Text fontFamily="regular" color="rythm" mb={2}>
                {label}
            </Text>
            <TraitsValuesFiltersSearchInput
                groupName="All Vibes"
                onChange={onSearchChange}
                onClear={onSearchClear}
            />
            <Box layerStyle="filters.traits.accordion.item" px={4} pt={2} pb={4}>
                {vibesList?.length ? (
                    <UnorderedList spacing={1} styleType="none" marginInlineStart={0}>
                        {vibesList.map(({ id, name, count }) => (
                            <ListItem key={`${id}-${name}`}>
                                <TraitFilterCheckbox
                                    traitValueId={id}
                                    traitId={id}
                                    label={name || "(None)"}
                                    amount={count}
                                    isChecked={filteredVibes.has(id)}
                                    onChecked={onAddVibeFilter}
                                    onUnchecked={onRemoveVibeFilter}
                                />
                            </ListItem>
                        ))}
                    </UnorderedList>
                ) : (
                    <Text>No results</Text>
                )}
            </Box>
        </Box>
    );
};

export default VibesFilters;
