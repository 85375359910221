import { Icon, IconProps } from "@chakra-ui/react";
import { FaHotjar as HotIcon, FaSnowflake as ColdIcon } from "react-icons/fa";

type TokenHotColdBadgeProps = {
    show: boolean | 0;
    isHot: boolean;
};

export const TokenHotColdBadge = ({
    show,
    isHot,
    ...props
}: TokenHotColdBadgeProps & IconProps) => {
    if (!show) return null;

    return (
        <>
            <Icon
                as={isHot ? HotIcon : ColdIcon}
                position="absolute"
                bgGradient={
                    isHot
                        ? "linear(to-t,hotGradient.start 0%, hotGradient.end 70%)"
                        : "linear(to-t, coldGradient.start 80%, coldGradient.end 100%)"
                }
                borderRadius="50%"
                {...props}
            />
        </>
    );
};

export default TokenHotColdBadge;
