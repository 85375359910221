import React from "react";
import { IconButton, Icon } from "@chakra-ui/react";
import { Button } from "@components/ui";
import { FaSlidersH } from "react-icons/fa";

type FiltersButtonProps = {} & ButtonProps;

const FiltersButton = ({ onClick, ...props }: FiltersButtonProps): JSX.Element => {
    return (
        <IconButton
            w="40px"
            h="40px"
            minH="40px"
            rounded="50em"
            colorScheme="gold"
            boxShadow="0px 0px 20px 10px #0009"
            icon={<Icon as={FaSlidersH} h="20px" w="20px" color="steelblue.900" />}
            onClick={onClick}
            {...props}
        />
    );
};

export default FiltersButton;
