import { Box, Flex, ResponsiveValue } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

type PortalTooltipProps = {
    content: string | React.ReactNode;
    whiteSpace?: ResponsiveValue<any>;
    wordBreak?: ResponsiveValue<any>;
    children: React.ReactNode;
    allowSelect?: boolean;
    edgeGutter?: number;
};

const PortalTooltip = ({
    children,
    content,
    whiteSpace,
    wordBreak,
    allowSelect,
    edgeGutter = 2,
}: PortalTooltipProps): JSX.Element => {
    const anchorRef = useRef<HTMLDivElement>();
    const tooltipRef = useRef<HTMLDivElement>();
    const tooltipContentRef = useRef<HTMLDivElement>();
    const tooltipArrowRef = useRef<HTMLDivElement>();
    const isVisible = useRef<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);

    useLayoutEffect(() => {
        // adjust size and position relative to anchor (children)
        if (visible && tooltipRef.current && anchorRef.current) {
            const { width: tooltipWidth, height: tooltipHeight } =
                tooltipRef.current.getBoundingClientRect();
            const { x, y, width } = anchorRef.current.getBoundingClientRect();

            tooltipRef.current.style.top = `${Math.round(
                window.scrollY + y - tooltipHeight - 15,
            )}px`;

            let tooltipLeft = Math.round(x + width / 2 - tooltipWidth / 2);
            if (tooltipLeft + tooltipWidth > window.innerWidth - edgeGutter) {
                tooltipLeft = window.innerWidth - edgeGutter - tooltipWidth;
            } else if (tooltipLeft < edgeGutter) {
                tooltipLeft = edgeGutter;
            }

            tooltipRef.current.style.left = `${tooltipLeft}px`;
            tooltipArrowRef.current.style.left = `${x + width / 2 - tooltipLeft}px`;
        }
    }, [visible]);

    useEffect(() => {
        return () => {
            window.removeEventListener("touchend", onTouchEnd);
            window.addEventListener("touchmove", onTouchMove);
        };
    }, []);

    const setVisibility = (visible: boolean) => {
        if (visible && !isVisible.current) {
            isVisible.current = true;
            setVisible(true);
        } else if (!visible && isVisible.current) {
            isVisible.current = false;
            setVisible(false);
        }
    };

    const onTouchEnd = (e: TouchEvent) => {
        e.preventDefault();
        window.removeEventListener("touchend", onTouchEnd);
        window.addEventListener("touchmove", onTouchMove);
        window.oncontextmenu = null;
        if (visible) setVisible(false);
    };

    const onTouchMove = (e: TouchEvent) => {
        setVisibility(false);
    };

    const onShow = () => {
        setVisibility(true);
    };

    const onShowTouchStart = (e: React.TouchEvent) => {
        setVisibility(true);

        window.oncontextmenu = (event: any) => {
            const pointerEvent = event as PointerEvent;
            if (pointerEvent.pointerType === "touch") {
                // context menu was triggerd by long press
                return false;
            }
        };

        window.addEventListener("touchend", onTouchEnd);
        window.addEventListener("touchmove", onTouchMove);
    };

    const onHide = () => {
        setVisibility(false);
    };

    const onHideTouchEnd = () => {
        setVisibility(false);
    };

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const renderTooltip = () => {
        const tooltip = (
            <AnimatePresence>
                {visible && (
                    <motion.div
                        ref={tooltipRef}
                        key="tooltip"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.15 }}
                        style={{
                            position: "absolute",
                            zIndex: "10000",
                            transformOrigin: "bottom center",
                            pointerEvents: !allowSelect ? "none" : "all",
                        }}
                        onClick={stopPropagation}
                    >
                        <Box
                            position="relative"
                            bg="blue.dark"
                            p="5px 10px"
                            rounded="md"
                            border="1px solid #acacac"
                            whiteSpace={whiteSpace || "nowrap"}
                            wordBreak={wordBreak || undefined}
                        >
                            <div ref={tooltipArrowRef} className="tooltipArrow"></div>
                            {content}
                            {allowSelect && (
                                <div
                                    className="tooltipHover"
                                    style={{ width: anchorRef.current.offsetWidth }}
                                />
                            )}
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        );

        return ReactDOM.createPortal(tooltip, document.body);
    };

    return (
        <Flex
            ref={anchorRef}
            style={{
                position: "relative",
                WebkitTouchCallout: "none",
            }}
            onMouseOver={onShow}
            onMouseLeave={onHide}
            onTouchStart={onShowTouchStart}
            onTouchEnd={onHideTouchEnd}
        >
            {renderTooltip()}
            {children}
        </Flex>
    );
};

export default PortalTooltip;
