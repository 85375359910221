import React from "react";
import { Box, Circle, Flex, Square } from "@chakra-ui/react";
import { SkeletonBox } from "@components/ui";

type TokenListItemSkeletonProps = {
    isDesktop?: boolean;
};
const TokenListItemSkeleton = ({ isDesktop }: TokenListItemSkeletonProps): JSX.Element => {
    return isDesktop ? (
        <Box position="relative" p="13px 7px 2px 2px">
            <SkeletonBox borderRadius="12px" w="445px" h="145px" position="relative">
                <Flex flexDirection="row" h="100%">
                    <Square size="145px" bg="#2F3546" />
                    <Box w="6px" h="100%" bg="panel.bg.dark" />
                </Flex>
            </SkeletonBox>
            <Circle position="absolute" top="2px" left="137px" size="26px" bg="#2F3546" />
        </Box>
    ) : (
        <SkeletonBox borderRadius="10px" w="145px" h="184px" flex="1 0 145px" />
    );
};

export default TokenListItemSkeleton;
