import React, { useRef, useState } from "react";
import { addTokenIdFilter } from "@store/filtersStore";
import { tokenExists } from "@store/collectionTokensStore";
import {
    InputGroup,
    Input,
    InputLeftElement,
    InputRightAddon,
    Icon,
    Button,
    Text,
    Box,
    InputRightElement,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearchAlt2 } from "react-icons/bi";
import { isInteger } from "@utils/helpers";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import SkeletonBox from "./SkeletonBox";

type AddTokenInputProps = {
    loading?: boolean;
    placeholder?: string;
    min: number;
    max: number;
    onAddToken?: (number) => void;
};

const SubmitBtn = ({ disabled = false, onClick }) => {
    return (
        <Button
            disabled={disabled}
            w="30px"
            minH="100%"
            rounded="0"
            bg="#464E6C"
            _hover={{ bg: "steelblue.500" }}
            _active={{ bg: "gold.500", color: "steelblue.500" }}
            _disabled={{ bg: "steelblue.900", color: "steelblue.500", pointerEvents: "none" }}
            onClick={onClick}
        >
            <Icon as={AiOutlinePlus} w="20px" h="20px" />
        </Button>
    );
};

const AddTokenInput = ({
    loading,
    placeholder,
    min,
    max,
    onAddToken,
}: AddTokenInputProps): JSX.Element => {
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });
    const input = useRef<HTMLInputElement>();
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const isValidTokenId = (value: string): boolean => {
        if (!isInteger(value)) return false;
        const intValue = parseInt(value);
        return intValue >= min && intValue <= max;
    };

    const onChange = (e) => {
        const { value } = e.target;
        if (errorMessage) setErrorMessage("");
        setSubmitEnabled(isValidTokenId(value));
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && submitEnabled) onSubmit(e);
        if (e.keyCode === 27) input.current.value = "";
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const { value } = input.current;
        if (!isValidTokenId(value)) {
            setErrorMessage("Not a valid TokenID");
            input.current.focus();
            return;
        }

        if (!tokenExists(value)) {
            setErrorMessage(`Token ${value} not found!`);
            setSubmitEnabled(false);
            input.current.focus();
            return;
        }

        // TODO: Check that Token id is in range inside the collection
        addTokenIdFilter(value);
        onAddToken?.(parseInt(value));
        input.current.value = "";
        input.current.focus();
    };

    // This is needed to submit the value in Android Chrome!
    const onFormSubmit = (e) => {
        e.preventDefault();
    };

    if (loading) return <SkeletonBox h="32px" mb={4} />;

    return (
        <form name="addTokenId" onSubmit={onFormSubmit}>
            <InputGroup
                size="sm"
                borderColor="#6D707D"
                mt={isDesktop ? 2 : 0}
                mb={isDesktop ? 4 : 0}
            >
                <InputLeftElement pointerEvents="none">
                    <Icon as={BiSearchAlt2} h="16px" w="16px" />
                </InputLeftElement>
                <Input
                    ref={input}
                    type="number"
                    placeholder={placeholder}
                    _hover={{ borderColor: "steelblue.100" }}
                    rounded="4px"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    _focus={{ borderColor: "gold.900", boxShadow: "none" }}
                    _active={{ borderColor: "gold.900", boxShadow: "none" }}
                />
                {errorMessage && (
                    <Box layerStyle="filters.addToken.errorMsg">
                        <WarningIcon h="10px" w="10px" mr={1} mb="1px" color="gold.500" />
                        <Text fontSize="12px" fontWeight="400" color="gold.500">
                            {errorMessage}
                        </Text>
                    </Box>
                )}
                <InputRightElement p="1px" mr="1px" borderRightRadius="5px" overflow="hidden">
                    <SubmitBtn disabled={!submitEnabled} onClick={onSubmit} />
                </InputRightElement>
            </InputGroup>
        </form>
    );
};

export default AddTokenInput;
