import React, { useState, useRef, useEffect } from "react";
import { VStack } from "@chakra-ui/react";
import { MARKETPLACES } from "@config/app";
import useFiltersStore, {
    addMarketplaceFilter,
    removeMarketplaceFilter,
} from "@store/filtersStore";
import MarketplaceCheckbox from "@components/MarketplaceCheckbox";
import useCollectionTokensStore from "@store/collectionTokensStore";

const MarketplaceFilters = (): JSX.Element => {
    const marketplaces = useFiltersStore((state) => state.marketplaces);
    const tokensPerMarketplaceMap = useCollectionTokensStore(
        (state) => state.tokensPerMarketplaceMap,
    );
    const tokensList = useCollectionTokensStore((state) => state.tokensList);

    const onCheck = (marketplaceId: number) => {
        addMarketplaceFilter(marketplaceId);
    };
    const onUncheck = (marketplaceId: number) => {
        removeMarketplaceFilter(marketplaceId);
    };

    return (
        <VStack alignItems="flex-start" w="100%">
            {MARKETPLACES.map((marketplace) => (
                <MarketplaceCheckbox
                    key={`marketplace-${marketplace.id}`}
                    marketplaceId={marketplace.id}
                    label={marketplace.name}
                    amount={tokensPerMarketplaceMap.get(marketplace.id)?.size || 0}
                    tokensAmount={tokensList.length}
                    isChecked={marketplaces?.get(marketplace.id) || false}
                    onChecked={onCheck}
                    onUnchecked={onUncheck}
                />
            ))}
        </VStack>
    );
};

export default MarketplaceFilters;
