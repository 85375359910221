import { Fragment } from "react";
import shallow from "zustand/shallow";
import useSessionStore from "@store/sessionStore";
import useCollectionStore from "@store/collectionStore";
import { Flex, Grid, GridItem } from "@chakra-ui/react";
import SortColumn from "@components/ui/SortColumn";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";

type TokenVibesTabPanelProps = {
    collection: CollectionData;
    token: Token;
    scale?: number;
};

export const TokenVibesTabPanel = ({ collection, token, scale=1 }: TokenVibesTabPanelProps) => {
    const { sortColumn, sortOrder } = useSessionStore(
        (state) => ({ sortColumn: state.vibeSortColumn, sortOrder: state.vibeSortOrder }),
        shallow,
    );
    const vibesMap = useCollectionStore((state) => state.vibesMap);

    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    const parsedVibes: { tokenVibe: any; vibeDef: Vibe }[] = token?.vibes?.map(
        (tokenVibe, index) => {
            const vibeDef = vibesMap.get(tokenVibe[0]);

            return { tokenVibe: tokenVibe, vibeDef: vibeDef };
        },
    );

    if (sortOrder != "none" && parsedVibes) {
        parsedVibes.sort((a, b) => {
            if (sortColumn == 1) {
                if (sortOrder == "asc") {
                    return a.vibeDef?.name.localeCompare(b.vibeDef?.name);
                } else if (sortOrder == "desc") {
                    return b.vibeDef?.name.localeCompare(a.vibeDef?.name);
                }
            } else if (sortColumn == 2) {
                if (sortOrder == "asc") {
                    if (a.tokenVibe[1]?.length == b.tokenVibe[1]?.length) {
                        return b.vibeDef?.traits?.length - a.vibeDef?.traits?.length;
                    }
                    return a.tokenVibe[1]?.length - b.tokenVibe[1]?.length;
                } else if (sortOrder == "desc") {
                    if (a.tokenVibe[1]?.length == b.tokenVibe[1]?.length) {
                        return a.vibeDef?.traits?.length - b.vibeDef?.traits?.length;
                    }
                    return b.tokenVibe[1]?.length - a.tokenVibe[1]?.length;
                }
            } else if (sortColumn == 3) {
                if (sortOrder == "asc") {
                    return a.vibeDef?.count - b.vibeDef?.count;
                } else if (sortOrder == "desc") {
                    return b.vibeDef?.count - a.vibeDef?.count;
                }
            }
        });
    }

    const afterSortToggle = (cId, sortOrder, e) => {
        useSessionStore.setState(() => ({ vibeSortColumn: cId, vibeSortOrder: sortOrder }));
    };

    return (
        <>
            <Grid
                h="100%"
                templateRows={`auto 1fr`}
                templateAreas={`"header" "body"`}
                fontSize={scaledPixel(16)}
            >
                <GridItem area="header" bgColor="spaceCadet">
                    <Grid templateColumns={`1fr ${scaledPixel(200)} ${scaledPixel(150)}`}>
                        <GridItem p={scaledPixel(8)}>
                            <SortColumn
                                cId={1}
                                sortOrder={sortColumn == 1 ? sortOrder : "none"}
                                afterToggle={afterSortToggle}
                            >
                                Vibe
                            </SortColumn>
                        </GridItem>
                        <GridItem p={scaledPixel(8)}>
                            <Flex direction="column" w="100%" alignItems="center">
                                <SortColumn
                                    cId={2}
                                    sortOrder={sortColumn == 2 ? sortOrder : "none"}
                                    afterToggle={afterSortToggle}
                                >
                                    # of Traits Matched
                                </SortColumn>
                            </Flex>
                        </GridItem>
                        <GridItem p={scaledPixel(8)}>
                            <Flex direction="column" w="100%" alignItems="end">
                                <SortColumn
                                    cId={3}
                                    sortOrder={sortColumn == 3 ? sortOrder : "none"}
                                    afterToggle={afterSortToggle}
                                >
                                    Occurances
                                </SortColumn>
                            </Flex>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem area="body" overflowY="scroll">
                    <Grid
                        templateColumns={`1fr ${scaledPixel(200)} ${scaledPixel(150)}`}
                        fontSize={scaledPixel(18)}
                        color="lavenderGray"
                    >
                        {parsedVibes?.map((parsed, index) => {
                            const bgColor = index % 2 ? "table.even" : "table.odd";
                            return (
                                <Fragment key={`row-${index}`}>
                                    <GridItem bgColor={bgColor} p={scaledPixel(8)}>
                                        {parsed.vibeDef?.name}
                                    </GridItem>
                                    <GridItem bgColor={bgColor} p={scaledPixel(8)} textAlign="center">
                                        {parsed.tokenVibe[1]?.length} of{" "}
                                        {parsed.vibeDef?.traits?.length}
                                    </GridItem>
                                    <GridItem bgColor={bgColor} p={scaledPixel(8)} textAlign="right">
                                        {parsed.vibeDef?.count} (
                                        {(
                                            (parsed.vibeDef?.count / collection?.itemsAmount) *
                                            100
                                        ).toFixed(2)}
                                        %)
                                    </GridItem>
                                </Fragment>
                            );
                        })}
                    </Grid>
                </GridItem>
            </Grid>
        </>
    );
};

export default TokenVibesTabPanel;
