import { useEffect, useRef, useState } from "react";
import { Box, Grid, GridItem, Text, Icon, Flex, VStack } from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { setChartActiveToken, useChartStore } from "@store/chartStore";
import { useSessionStore } from "@store/sessionStore";
import { ClickHoverIconButton } from "@components/ui";
import TokenLayoutValue from "@components/tokenCommon/TokenLayoutValue";
import TokenHotColdVerticalBand from "@components/tokenCommon/TokenHotColdVerticalBand";
import TokenHotColdBadge from "@components/tokenCommon/TokenHotColdBadge";
import TokenImagePlayer from "@components/tokenCommon/TokenImagePlayer";
import PortalTooltip from "@components/ui/PortalTooltip";
import OwnedDiamondImage from "@components/images/OwnedDiamondImage";

const VALUE_LABEL_WIDTH = "30px";
const VALUE_INFO_WIDTH = "13px";

const EthIcon = ({ ...props }) => (
    <Flex>
        <Icon as={FaEthereum} color="whiteAlpha.500" textStyle="mobileTokenItem.label" {...props} />
    </Flex>
);

const TokenListItemMobile = ({
    token,
    itemIndex,
    collection,
    onTokenSelected,
}: TokenListItemProps): JSX.Element => {
    const isConnected = useSessionStore((state) => state.connected);
    const expanded = useSessionStore((state) => state.mobileTokenItemExpanded);
    const currentChartActiveToken = useChartStore((state) => state.activeToken);
    const highlightMobileToken = useCollectionTokensStore((state) => state.highlightMobileToken);
    const [briteInfoIsOpen, setBriteInfoOpen] = useState(false);
    const [deviationInfoIsOpen, setDeviationInfoOpen] = useState(false);

    const tokenIdContainerRef = useRef(null);
    const tokenIdRef = useRef(null);
    const [isTokenIdOverflown, setTokenIdOverflown] = useState(false);
    useEffect(() => {
        const element = tokenIdRef?.current;

        setTokenIdOverflown(element?.scrollWidth > element?.clientWidth);
    }, [tokenIdRef, tokenIdContainerRef, token?.tokenId]);

    const handleOnTokenClick = (e) => {
        e.stopPropagation();
        setChartActiveToken({
            tokenStringId: token.tokenStringId,
            currentPrice: token.currentPrice,
            activationType: "selected",
        });
        onTokenSelected(token.tokenStringId, itemIndex);
    };

    const DeviationInfo = () => {
        if (!deviationInfoIsOpen) return null;

        return (
            <>
                <Flex
                    position="absolute"
                    bottom="100%"
                    direction="column"
                    w="100%"
                    justifyContent="center"
                    bgColor="blue.med"
                    p="5px"
                    pt="8px"
                    textAlign="center"
                    borderRadius="6px 6px 0px 0px"
                    gap="3px"
                >
                    <Box textStyle="mobileTokenItem.deviationInfo.header">
                        What&apos;s the Deviation?
                    </Box>
                    <Box textStyle="mobileTokenItem.deviationInfo.subheader">
                        The difference between the List &amp; <b>BR</b>ITE Prices.
                    </Box>
                    <Box textStyle="mobileTokenItem.deviationInfo.body">
                        Bigger deviations are farther from the&nbsp;<b>BR</b>ITE Price.
                    </Box>
                </Flex>
            </>
        );
    };

    const BriteInfo = () => {
        if (!briteInfoIsOpen) return null;

        return (
            <>
                <Flex
                    position="absolute"
                    bottom="100%"
                    direction="column"
                    w="100%"
                    justifyContent="center"
                    bgColor="blue.dark"
                    p="5px"
                    pt="8px"
                    textAlign="center"
                    borderRadius="6px 6px 0px 0px"
                    gap="3px"
                >
                    <Box textStyle="mobileTokenItem.deviationInfo.header">
                        What&apos;s the <b>BR</b>ITE Price?
                    </Box>
                    <Box textStyle="mobileTokenItem.deviationInfo.subheader">
                        Beyond Rarity&apos;s Intelligent Trusted Estimate
                    </Box>
                    <Box textStyle="mobileTokenItem.deviationInfo.body">
                        Our algorithm integrates sales history and other complex factors to create
                        the most accurate price projection and help you make your best decisions.
                    </Box>
                </Flex>
            </>
        );
    };

    const onOpenDeviation = () => {
        setDeviationInfoOpen(true);
    };

    const onCloseDeviation = () => {
        setDeviationInfoOpen(false);
    };

    const onOpenBriteInfo = () => {
        if (deviationInfoIsOpen) onCloseDeviation();
        setBriteInfoOpen(true);
    };

    const onCloseBriteInfo = () => {
        setBriteInfoOpen(false);
    };

    const getTooltipContent = (value: string) => (
        <Box maxW="calc(100vw - 30px)" textAlign="center" lineHeight="120%">
            {value}
        </Box>
    );

    let borderColor = "panel.bg.dark";

    if (currentChartActiveToken?.tokenStringId === token.tokenStringId) {
        if (currentChartActiveToken.activationType === "selected") {
            borderColor = "gold.500";
        } else if (!highlightMobileToken) {
            borderColor = "whiteAlpha.700";
        }
    }

    return (
        <Box
            w="145px"
            pos="relative"
            _hover={{ cursor: "pointer" }}
            onClick={handleOnTokenClick}
            userSelect="none"
        >
            <Box
                position="relative"
                borderRadius="12px"
                border="2px solid"
                transition="border-color 0.3s"
                borderColor={borderColor}
            >
                <Box bgColor="gray.tokenListItem" borderRadius="10px" overflow="hidden">
                    <Box w="100%" borderTopRadius="10px" overflow="hidden">
                        <TokenImagePlayer
                            name={token.name}
                            collectionName={collection.name}
                            tokenStringId={token.tokenStringId}
                            imageUrl={token.imageUrl?.replace(/(\?.*)(w=.+)(&.+)/gm, "$1w=150$3")}
                            animationUrl={token.animationUrl}
                        />
                        {token?.own && (
                            <Flex
                                position="absolute"
                                direction="row"
                                bgColor="raisinBlackAlpha.70"
                                borderRadius="4px"
                                p="4px"
                                top="6px"
                                left="6px"
                                justify="center"
                                align="center"
                                gap="3px"
                            >
                                <OwnedDiamondImage w="8px" />
                                <Box fontSize="10px" lineHeight="100%" mt=".2em">
                                    Owned
                                </Box>
                            </Flex>
                        )}
                    </Box>
                    <VStack p="6px" w="100%" spacing="8px">
                        <Box w="100%">
                            <Grid
                                templateColumns="auto auto"
                                templateRows="auto auto auto"
                                templateAreas={`
                                "tokenIdLabel rankLabel"
                                "tokenId rank"
                            `}
                                w="100%"
                            >
                                <GridItem
                                    area="tokenIdLabel"
                                    pb="2px"
                                    textStyle="mobileTokenItem.label"
                                >
                                    Token ID
                                </GridItem>
                                <GridItem
                                    area="rankLabel"
                                    textAlign="right"
                                    pb="2px"
                                    textStyle="mobileTokenItem.label"
                                    whiteSpace="nowrap"
                                >
                                    Rarity Rank
                                </GridItem>
                            </Grid>
                            <Grid
                                templateColumns="auto auto"
                                templateRows="auto auto auto"
                                templateAreas={`
                                "tokenIdLabel rankLabel"
                                "tokenId rank"
                            `}
                                w="100%"
                            >
                                <GridItem area="tokenId" textStyle="mobileTokenItem.id">
                                    <Box w="90px">
                                        {isTokenIdOverflown ? (
                                            <PortalTooltip
                                                content={getTooltipContent(token.tokenStringId)}
                                                whiteSpace="normal"
                                            >
                                                <Box
                                                    ref={tokenIdRef}
                                                    textOverflow="ellipsis"
                                                    overflow="hidden"
                                                    whiteSpace="nowrap"
                                                    lineHeight="100%"
                                                >
                                                    {token.tokenStringId}
                                                </Box>
                                            </PortalTooltip>
                                        ) : (
                                            <Box
                                                ref={tokenIdRef}
                                                textOverflow="ellipsis"
                                                overflow="hidden"
                                                whiteSpace="nowrap"
                                                lineHeight="100%"
                                            >
                                                {token.tokenStringId}
                                            </Box>
                                        )}
                                    </Box>
                                </GridItem>
                                <GridItem
                                    area="rank"
                                    textAlign="right"
                                    textStyle="mobileTokenItem.rank"
                                >
                                    {token.rank}
                                </GridItem>
                            </Grid>
                        </Box>
                        {expanded && (
                            <VStack w="100%" spacing="4px">
                                <Flex
                                    w="100%"
                                    h="28px"
                                    borderRadius="6px"
                                    bgColor="blue.dark"
                                    p="4px"
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Flex minW={VALUE_INFO_WIDTH} pr="2px" />
                                    <Flex
                                        w={VALUE_LABEL_WIDTH}
                                        textStyle="mobileTokenItem.label"
                                        mt=".7px"
                                    >
                                        List
                                    </Flex>
                                    <Flex>
                                        <EthIcon />
                                    </Flex>
                                    <Flex
                                        flex={1}
                                        textStyle="mobileTokenItem.price"
                                        justifyContent="end"
                                        mt=".7px"
                                        pl="8px"
                                    >
                                        <TokenLayoutValue
                                            value={token.currentPrice}
                                            requiresAuth={false}
                                            showOutOfRangeTooltip
                                            useTooltipPortal
                                            mode="free"
                                        />
                                    </Flex>
                                </Flex>
                                <Box position="relative" w="100%">
                                    <Flex
                                        w="100%"
                                        h="28px"
                                        borderRadius={
                                            deviationInfoIsOpen ? "0px 0px 6px 6px" : "6px"
                                        }
                                        bgColor="blue.med"
                                        p="4px"
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <ClickHoverIconButton
                                            mode="click"
                                            onOpen={onOpenDeviation}
                                            onClose={onCloseDeviation}
                                            textStyle="mobileTokenItem.label"
                                            iconProps={{ boxSize: VALUE_INFO_WIDTH }}
                                            pl="2px"
                                        />
                                        <Flex
                                            w={VALUE_LABEL_WIDTH}
                                            textStyle="mobileTokenItem.label"
                                            mt=".7px"
                                        >
                                            Dev.
                                        </Flex>
                                        <Flex>
                                            <EthIcon />
                                        </Flex>
                                        <Flex
                                            flex={1}
                                            textStyle="mobileTokenItem.deviation"
                                            justifyContent="end"
                                            mt=".7px"
                                            pl="8px"
                                        >
                                            <TokenLayoutValue
                                                value={token.deviation}
                                                showPositiveSign
                                                showOutOfRangeTooltip
                                                useTooltipPortal
                                                mode="free"
                                            />
                                        </Flex>
                                    </Flex>
                                    <DeviationInfo />
                                </Box>
                                <Box position="relative" w="100%">
                                    <Flex
                                        w="100%"
                                        h="28px"
                                        borderRadius={briteInfoIsOpen ? "0px 0px 6px 6px" : "6px"}
                                        bgColor="blue.dark"
                                        p="4px"
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <ClickHoverIconButton
                                            mode="click"
                                            onOpen={onOpenBriteInfo}
                                            onClose={onCloseBriteInfo}
                                            textStyle="mobileTokenItem.label"
                                            iconProps={{ boxSize: VALUE_INFO_WIDTH }}
                                            pl="2px"
                                        />
                                        <Flex
                                            w={VALUE_LABEL_WIDTH}
                                            textStyle="mobileTokenItem.label"
                                            mt=".7px"
                                        >
                                            <b>BR</b>ITE
                                        </Flex>
                                        <Flex>
                                            <EthIcon />
                                        </Flex>
                                        <Flex
                                            flex={1}
                                            textStyle="mobileTokenItem.projection"
                                            justifyContent="end"
                                            mt="1px"
                                            pl="8px"
                                        >
                                            <TokenLayoutValue
                                                value={token.priceProjection}
                                                showOutOfRangeTooltip
                                                useTooltipPortal
                                                mode="free"
                                            />
                                        </Flex>
                                    </Flex>
                                    <BriteInfo />
                                </Box>
                                <Box
                                    w="100%"
                                    textAlign="right"
                                    textStyle="mobileTokenItem.confidence"
                                >
                                    {isConnected ? (
                                        <>
                                            Confidence{" "}
                                            <Text as="span" color="white">
                                                {token.confidence?.toFixed(0)}%
                                            </Text>
                                        </>
                                    ) : null}
                                </Box>
                            </VStack>
                        )}
                    </VStack>
                </Box>
            </Box>
            {isConnected ? (
                <>
                    <TokenHotColdVerticalBand
                        show={token.currentPrice != null && isConnected}
                        isHot={token.deviation > 0}
                        width="6px"
                        height="145px"
                        top="0px"
                        right="2px"
                    />
                    <TokenHotColdBadge
                        show={token.currentPrice != null && isConnected}
                        isHot={token.deviation > 0}
                        width="26px"
                        height="26px"
                        top="-8px"
                        right="-8px"
                        padding="3px"
                    />
                    {/* {token.own && (
                        <OwnedDiamondImage position="absolute" w="16px" top="-9px" left="10px" />
                    )} */}
                </>
            ) : null}
        </Box>
    );
};

export default TokenListItemMobile;
