export const tokenModalConfig = {
    landscape: {
        baseWidth: 1400, 
        infoSectionPadding: 20,
    },
    
    portrait: {
        baseWidth: 500, 
    }
}

export default tokenModalConfig;