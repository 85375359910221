import React from "react";
import {
    Popover,
    PopoverArrow,
    PopoverTrigger,
    PopoverContent,
    HStack,
    Text,
} from "@chakra-ui/react";
import { Button } from "@components/ui";
import TokenListSortMenu from "./TokenListSortMenu";
import { getSortOptions } from "@store/sessionStore";

type TokenListSortPopoverProps = {
    sortLabel: string;
    orderLabel: string;
};

const TokenListSortPopover = ({
    sortLabel,
    orderLabel,
}: TokenListSortPopoverProps): JSX.Element => {
    const sortOptions = getSortOptions();

    const renderSortButton = () => {
        return (
            <Button fullWidth variant="outline" size="sm">
                <HStack alignItems="baseline" py="3px">
                    <Text>{sortLabel}</Text>
                    <Text fontStyle="italic" fontSize="10px" opacity=".5">
                        ({orderLabel})
                    </Text>
                </HStack>
            </Button>
        );
    };

    return (
        <Popover
            isLazy
            returnFocusOnClose={false}
            placement="bottom"
            closeOnBlur={true}
            arrowSize={16}
            gutter={12}
            arrowShadowColor="blue.darker"
        >
            {({ onClose }) => (
                <>
                    <PopoverTrigger>{renderSortButton()}</PopoverTrigger>
                    <PopoverContent
                        w="auto"
                        border="1px solid"
                        borderColor="#6D707D"
                        background="none"
                        rounded="lg"
                        boxShadow="0px 5px 10px 5px rgba(0,0,0,0.35)"
                        _focus={{
                            ring: "0px 5px 10px 5px rgba(0,0,0,0.35)",
                            boxShadow: "0px 5px 10px 5px rgba(0,0,0,0.35)",
                        }}
                        layerStyle="popoverContent"
                    >
                        <PopoverArrow bg="blue.darker" border="1px solid #6D707D" />
                        <TokenListSortMenu options={sortOptions} onApply={onClose} />
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};

export default TokenListSortPopover;
