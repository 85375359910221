import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";

type BreadcrumbButtonProps = {
    label: string;
    onClick?: () => void;
};

const BreadcrumbButton = ({ label, onClick }: BreadcrumbButtonProps): JSX.Element => {
    return (
        <Flex direction="row" layerStyle="breadcrumbs.button" onClick={onClick}>
            {label && (
                <Text textStyle="chipLabel" color="inherit">
                    {label}{" "}
                    <FaTrash
                        style={{
                            height: "10px",
                            marginLeft: "3px",
                            display: "inline",
                            verticalAlign: "top",
                        }}
                    />
                </Text>
            )}
        </Flex>
    );
};

export default BreadcrumbButton;
