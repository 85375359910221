import React from "react";
import { FaSortAmountDown } from "react-icons/fa";
import { HStack, Icon, Text, Box } from "@chakra-ui/react";
import TokenListSortPopover from "./TokenListSortPopover";
import useFiltersStore from "@store/filtersStore";
import shallow from "zustand/shallow";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { SkeletonBox } from "@components/ui";
import { getSortOrderLabels } from "@utils/helpers";

const TokenListSortBar = (): JSX.Element => {
    const loading = useCollectionTokensStore((state) => state.loading);
    const { sortBy, sortOrder } = useFiltersStore(
        (state) => ({
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
        }),
        shallow,
    );

    const { sortLabel, orderLabel } = getSortOrderLabels(sortBy, sortOrder);

    if (loading) {
        return (
            <HStack gap={3} h="26px" alignItems="flex-start">
                <SkeletonBox w="68px" />
                <SkeletonBox w="170px" />
            </HStack>
        );
    }

    return (
        <HStack gap={3} h="26px" overflow="hidden" alignItems="flex-start">
            <Box color="gray.faint">
                <Icon as={FaSortAmountDown} pb="2px" mr="4px" />
                <Text as="span" verticalAlign="text-bottom">
                    Sort by
                </Text>
            </Box>
            <Box w="210px">
                <TokenListSortPopover sortLabel={sortLabel} orderLabel={orderLabel} />
            </Box>
        </HStack>
    );
};

export default TokenListSortBar;
