import { useRouter } from "next/router";
import { setSelectedToken } from "@store/collectionTokensStore";

const useTokenModal = (): any => {
    const router = useRouter();

    const showModalToken = async (tokenStringId: TokenStringId): Promise<void> => {
        const { collectionId } = router.query;

        router.push(`/c/${collectionId}/${tokenStringId}`, `/c/${collectionId}/${tokenStringId}`, {
            shallow: true,
        });
    };

    const closeTokenModal = async (): Promise<void> => {
        const { collectionId } = router.query;

        setSelectedToken(null);
        router.push(`/c/${collectionId}`, `/c/${collectionId}`, { shallow: true });
    };

    return { showModalToken, closeTokenModal };
};

export default useTokenModal;
