import React, { FC, ReactElement, useRef } from "react";
import { UnorderedList, ListItem, Text, Box } from "@chakra-ui/react";
import { TraitFilterCheckbox } from "@components/ui";
import { useVirtualizer } from "@tanstack/react-virtual";

const MAX_VISIBLE_TRAITS = 30;
const LIST_ITEM_HEIGHT = 25;

const TraitValuesFiltersList: FC<TraitValuesFiltersListProps> = ({
    traitId,
    rankedNumerically = false,
    values,
    selectedValues,
    onTraitValueChecked,
    onTraitValueUnchecked,
}: TraitValuesFiltersListProps): ReactElement => {
    const scrollRef = useRef<HTMLDivElement>();

    const sortedValues =
        values?.size > 0
            ? [...values.values()].sort((a, b) => {
                  if (rankedNumerically) {
                      if (b.label == null && a.label == null) return 0;
                      if (b.label == null && a.label != null) return 1;
                      if (b.label != null && a.label == null) return -1;
                      return +b.label - +a.label;
                  }

                  if (a.amount == b.amount) {
                      if (a.label == null && b.label == null) return 0;
                      if (a.label == null && b.label != null) return 1;
                      if (a.label != null && b.label == null) return -1;
                      return a.label.toString().localeCompare(b.label.toString());
                  }

                  return a.amount - b.amount;
              })
            : [];

    const rowVirtualizer = useVirtualizer({
        count: sortedValues.length,
        overscan: 10,
        getScrollElement: () => scrollRef.current,
        estimateSize: () => LIST_ITEM_HEIGHT,
    });

    const onTraitChecked = (traitValueId: number) => {
        const traitValue = values.get(traitValueId);
        if (traitValue) onTraitValueChecked?.(traitValue.traitId, traitValue.id);
    };

    const onTraitUnchecked = (traitValueId: number) => {
        const traitValue = values.get(traitValueId);
        if (traitValue) onTraitValueUnchecked?.(traitValue.traitId, traitValue.id);
    };

    if (!sortedValues.length) return <Text>No results</Text>;

    if (sortedValues.length <= MAX_VISIBLE_TRAITS) {
        return (
            <UnorderedList spacing={1} styleType="none" marginInlineStart={0}>
                {sortedValues.map(({ traitId, id, label, amount }) => {
                    return (
                        <ListItem key={`${id}-${values.size}`}>
                            <TraitFilterCheckbox
                                traitValueId={id}
                                traitId={traitId}
                                label={label || "(None)"}
                                amount={amount}
                                isChecked={!!selectedValues?.get(id)}
                                onChecked={onTraitChecked}
                                onUnchecked={onTraitUnchecked}
                            />
                        </ListItem>
                    );
                })}
            </UnorderedList>
        );
    } else {
        return (
            <Box
                className="smallScroll"
                ref={scrollRef}
                layerStyle="filters.traits.accordion.virtualListScroll"
                maxH={MAX_VISIBLE_TRAITS * LIST_ITEM_HEIGHT}
            >
                <Box w="100%" h={`${rowVirtualizer.getTotalSize()}px`} position="relative">
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                        const traitValue = sortedValues[virtualItem.index];
                        const { traitId, id, label, amount } = traitValue;

                        return (
                            <div
                                key={virtualItem.key}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "calc(100% - 5px)",
                                    height: `${virtualItem.size}px`,
                                    transform: `translateY(${virtualItem.start}px)`,
                                }}
                            >
                                <TraitFilterCheckbox
                                    traitValueId={id}
                                    traitId={traitId}
                                    label={label || "(None)"}
                                    amount={amount}
                                    isChecked={!!selectedValues?.get(id)}
                                    onChecked={onTraitChecked}
                                    onUnchecked={onTraitUnchecked}
                                />
                            </div>
                        );
                    })}
                </Box>
            </Box>
        );
    }
};

export default TraitValuesFiltersList;
