import React, { FC, ReactElement } from "react";
import { forwardRef, Button as ChakraButton } from "@chakra-ui/react";

const colorSchemes = {
    "solid-default": {
        fontFamily: "regular",
        background: "button.default.default",
        color: "white",
        // boxShadow: " 0px 1px 4px #00000080, inset 0px 0px 8px #ffffff0d",
        _focus: {
            background: "button.default.hover",
        },
        _hover: {
            background: "button.default.hover",
        },
        _active: {
            background: "button.default.active",
        },
        _disabled: {
            color: "rythm",
            boxShadow: "none",
            cursor: "not-allowed",
            pointerEvents: "none",
            background: "button.default.disabled",
        },
    },
    "solid-gold": {
        fontFamily: "regular",
        background: "button.gold.default",
        color: "spaceCadet",
        // boxShadow:
        //     "0px 1px 4px #00000080, inset 0px -1px 0px #ffffff72, inset 0px 0px 8px #FFB706",
        _hover: {
            background: "button.gold.hover",
        },
        _focus: {
            background: "button.gold.hover",
        },
        _active: {
            background: "button.gold.active",
        },
        _disabled: {
            cursor: "not-allowed",
            boxShadow: "none",
            pointerEvents: "none",
            background: "button.gold.disabled",
        },
    },
    "solid-dimmed": {
        fontFamily: "regular",
        background: "button.dimmed.default",
        color: "spaceCadet",
        // boxShadow:
        //     "0px 1px 4px #00000080, inset 0px -1px 0px #ffffff1f, inset 0px 0px 8px #536186",
        _hover: {
            background: "button.dimmed.hover",
        },
        _focus: {
            background: "button.dimmed.hover",
        },
        _active: {
            background: "button.dimmed.active",
        },
        _disabled: {
            cursor: "not-allowed",
            boxShadow: "none",
            pointerEvents: "none",
            background: "button.dimmed.disabled",
        },
    },
};

const Button: FC<ButtonProps> = forwardRef<ButtonProps, typeof ChakraButton>(
    (
        {
            size = "md",
            variant = "solid",
            colorScheme = "default",
            fullWidth,
            onClick,
            children,
            ...props
        }: ButtonProps,
        ref,
    ): ReactElement => {
        const btnColorScheme = colorSchemes[`${variant}-${colorScheme}`] || "";

        return (
            <ChakraButton
                ref={ref}
                size={size}
                variant={variant}
                onClick={onClick}
                w={fullWidth ? "100%" : undefined}
                fontWeight="normal"
                {...btnColorScheme}
                {...props}
            >
                {children}
            </ChakraButton>
        );
    },
);

export default Button;
