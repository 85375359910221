import React from "react";
import { Box, SkeletonProps } from "@chakra-ui/react";

type SkeletonBoxProps = {
    shadeColor?: string;
    animated?: boolean;
    staticBgColor?: string;
} & SkeletonProps;

const SkeletonBox = ({
    shadeColor = "#ffffff08",
    animated = true,
    staticBgColor = "none",
    ...props
}: SkeletonBoxProps): JSX.Element => {
    return (
        <Box
            w="100%"
            h="100%"
            position="relative"
            overflow="hidden"
            bg="#2F3546"
            rounded="md"
            _before={{
                content: '""',
                display: "block",
                position: "absolute",
                left: "-50%",
                top: 0,
                height: "100%",
                width: "80%",
                background: !animated
                    ? staticBgColor
                    : `linear-gradient(to right, transparent 0%, ${shadeColor} 50%, transparent 100%)`,
                animation: "skeleton 1.75s linear infinite",
            }}
            {...props}
        />
    );
};

export default SkeletonBox;
