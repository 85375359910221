import { BoxProps, Flex, Icon, IconProps } from "@chakra-ui/react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { MdInfo } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";

type ClickHoverIconButtonProps = {
    mode?: "click" | "hover";
    onOpen?: Function;
    onClose?: Function;
    isOpen?: boolean;
    useIsOpenIconOnHover?: boolean;
    iconProps?: IconProps;
    isOpenIcon?: IconType;
    isClosedIcon?: IconType;
    disableHover?: boolean;
};

//
export const ClickHoverIconButton = ({
    mode,
    onOpen,
    onClose,
    isOpen,
    useIsOpenIconOnHover,
    iconProps,
    isOpenIcon = RiCloseCircleFill,
    isClosedIcon = MdInfo,
    disableHover = false,
    ...props
}: ClickHoverIconButtonProps & BoxProps) => {
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });

    const [internalMode, setInternalMode] = useState(
        mode == null ? (isDesktop ? "hover" : "click") : mode,
    );
    const [isInternalOpen, setInternalIsOpen] = useState(isOpen == true);

    const handleOnClick = (e) => {
        e.stopPropagation();
        if (internalMode == "click") {
            if (isInternalOpen) {
                if (onClose) onClose();
            } else {
                if (onOpen) onOpen();
            }
            if (isOpen == null) setInternalIsOpen(!isInternalOpen);
        }
    };

    const handleOnEnter = (e) => {
        e.stopPropagation();
        if (internalMode == "hover") {
            if (onOpen) onOpen();
            if (isOpen == null && !isInternalOpen) setInternalIsOpen(true);
        }
    };

    const handleOnLeave = (e) => {
        e.stopPropagation();
        if (internalMode == "hover") {
            if (onClose) onClose();
            if (isOpen == null && isInternalOpen) setInternalIsOpen(false);
        }
    };

    useEffect(() => {
        setInternalMode(mode == null ? (isDesktop ? "hover" : "click") : mode);
    }, [mode, isDesktop]);

    useEffect(() => {
        setInternalIsOpen(isOpen == true);
    }, [isOpen]);

    return (
        <>
            <Flex
                onClick={handleOnClick}
                onMouseEnter={handleOnEnter}
                onMouseLeave={handleOnLeave}
                onBlur={handleOnLeave}
                color={isInternalOpen ? "gold.500" : undefined}
                _hover={
                    disableHover
                        ? undefined
                        : {
                              color: "gold.500",
                              cursor: internalMode == "hover" ? "default" : "pointer",
                          }
                }
                {...props}
            >
                {isInternalOpen && (internalMode == "click" || useIsOpenIconOnHover) ? (
                    <Icon as={isOpenIcon} {...iconProps} />
                ) : (
                    <Icon as={isClosedIcon} {...iconProps} />
                )}
            </Flex>
        </>
    );
};

export default ClickHoverIconButton;
