import {
    Box,
    Flex,
    Grid,
    GridItem,
    Icon,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { MdInfo } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";

const DesktopTooltip = () => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <>
            <Popover placement="right">
                {({ isOpen }) => (
                    <>
                        <PopoverTrigger>
                            <Flex
                                color={isOpen ? "gold.500" : "rythm"}
                                _hover={{
                                    color: "gold.500",
                                    cursor: "pointer",
                                }}
                                onClick={onToggle}
                            >
                                {isOpen ? <Icon as={RiCloseCircleFill} /> : <Icon as={MdInfo} />}
                            </Flex>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent
                                w="450px"
                                bgColor="blue.darker"
                                border="none"
                                outline={0}
                                _focus={{ border: "none", boxShadow: "none" }}
                            >
                                <PopoverArrow bgColor="blue.darker" boxShadow="none !important" />
                                <PopoverBody>
                                    <VStack
                                        w="100%"
                                        justifyContent="center"
                                        textAlign="center"
                                        gap="3px"
                                        fontWeight="lighter"
                                    >
                                        <Box fontWeight="bold" color="white" fontSize="20px">
                                            What&apos;s a Min Absolute Deviation?
                                        </Box>
                                        <Box
                                            w="100%"
                                            borderRadius="6px"
                                            py="2px"
                                            bgColor="sorbet"
                                            fontSize="16px"
                                        >
                                            The minimum difference between the List &amp; <b>BR</b>
                                            ITE Prices
                                        </Box>
                                        <Grid templateColumns="auto 60%" gap="10px" fontSize="14px">
                                            <GridItem>
                                                <VStack
                                                    h="100%"
                                                    gap="3px"
                                                    textAlign="left"
                                                    justifyContent="center"
                                                >
                                                    <Box>
                                                        This lets you filter out items that are too
                                                        similarly priced to the projected estimate
                                                        to make it worth your while.
                                                    </Box>
                                                    <Box>
                                                        The bigger the deviation, the farther from
                                                        the <b>BR</b>ITE Price projection.
                                                    </Box>
                                                </VStack>
                                            </GridItem>
                                            <GridItem>
                                                <Image
                                                    src="/images/min-deviation-tooltip.png"
                                                    alt="Beyond Rarity - What's a Min Absolute Deviation?"
                                                />
                                            </GridItem>
                                        </Grid>
                                    </VStack>
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </>
                )}
            </Popover>
        </>
    );
};

const MobileTooltip = () => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <>
            <Popover>
                {({ isOpen }) => (
                    <>
                        <PopoverTrigger>
                            <Flex
                                color={isOpen ? "gold.500" : "rythm"}
                                _hover={{
                                    color: "gold.500",
                                    cursor: "pointer",
                                }}
                                onClick={onToggle}
                            >
                                {isOpen ? <Icon as={RiCloseCircleFill} /> : <Icon as={MdInfo} />}
                            </Flex>
                        </PopoverTrigger>
                        <PopoverContent
                            mx="20px"
                            p="4px"
                            bgColor="blue.darker"
                            color="white"
                            _focus={{ boxShadow: "none" }}
                        >
                            <PopoverArrow bgColor="blue.darker" />
                            <PopoverBody>
                                <VStack
                                    w="100%"
                                    justifyContent="center"
                                    textAlign="center"
                                    gap="3px"
                                    fontWeight="lighter"
                                >
                                    <Box fontWeight="bold" color="white" fontSize="18px">
                                        What&apos;s a Min Absolute Deviation?
                                    </Box>
                                    <Box
                                        w="100%"
                                        borderRadius="6px"
                                        py="2px"
                                        bgColor="sorbet"
                                        fontSize="15px"
                                    >
                                        The minimum difference between <br />
                                        the List &amp; <b>BR</b>ITE Prices
                                    </Box>
                                    <Grid templateRows="auto auto" gap="10px" fontSize="14px">
                                        <GridItem px="15px">
                                            <VStack h="100%" gap="3px" justifyContent="center">
                                                <Box>
                                                    This lets you filter out items that are too
                                                    similarly priced to the projected estimate to
                                                    make it worth your while.
                                                </Box>
                                                <Box>
                                                    The bigger the deviation, the farther from the{" "}
                                                    <b>BR</b>ITE Price projection.
                                                </Box>
                                            </VStack>
                                        </GridItem>
                                        <GridItem>
                                            <Image
                                                src="/images/deviation-tooltip.png"
                                                alt="Beyond Rarity - What's a Min Absolute Deviation?"
                                            />
                                        </GridItem>
                                    </Grid>
                                </VStack>
                            </PopoverBody>
                        </PopoverContent>
                    </>
                )}
            </Popover>
        </>
    );
};

export const MinDeviationFilterTooltip = () => {
    const { isDesktop } = useDebouncedWindowSize({useIsDesktop: true});

    return <>{isDesktop ? <DesktopTooltip /> : <MobileTooltip />}</>;
};

export default MinDeviationFilterTooltip;
