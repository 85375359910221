import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { useSessionStore, toggleMobileTokenItemExpanded } from "@store/sessionStore";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const TokenListMobileExpandButton = ({ onExpandedChange }) => {
    const expanded = useSessionStore((state) => state.mobileTokenItemExpanded);

    const onToggleMobileExpandedState = () => {
        onExpandedChange?.(!expanded);
        toggleMobileTokenItemExpanded();
    };

    return (
        <Flex alignItems="center" justifyContent="center" pt="4px" mb={2}>
            <Flex
                alignItems="center"
                justifyContent="center"
                onClick={onToggleMobileExpandedState}
                fontSize="14px"
                fontWeight={300}
                color="whiteAlpha.700"
            >
                Show {expanded ? "Less" : "More"} Details{" "}
                <Icon
                    as={expanded ? MdKeyboardArrowUp : MdKeyboardArrowDown}
                    pl="5px"
                    w="22px"
                    h="22px"
                />
            </Flex>
        </Flex>
    );
};

export default TokenListMobileExpandButton;
