import { Fragment } from "react";
import shallow from "zustand/shallow";
import useSessionStore from "@store/sessionStore";
import useCollectionStore from "@store/collectionStore";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import SortColumn from "@components/ui/SortColumn";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";
import INVALID_TOKEN_TRAITS from "@data/INVALID_TOKEN_TRAITS";
import RankedNumericallyTooltipIcon from "@components/ui/RankedNumericallyTooltipIcon";

type TokenTraitsTabPanelProps = {
    collection: CollectionData;
    token: Token;
    scale?: number;
};

export const TokenTraitsTabPanel = ({ collection, token, scale = 1 }: TokenTraitsTabPanelProps) => {
    const { sortColumn, sortOrder } = useSessionStore(
        (state) => ({ sortColumn: state.traitSortColumn, sortOrder: state.traitSortOrder }),
        shallow,
    );
    const traitsMap = useCollectionStore((state) => state.traitsMap);

    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    const parsedTraits: { trait: TraitMapItem; value: TraitValue }[] = token?.traits?.map(
        (tokenTrait, index) => {
            const trait = traitsMap.get(tokenTrait[0]);
            const traitValue = trait?.values.get(tokenTrait[1]);

            return { trait: trait, value: traitValue };
        },
    );

    if (sortOrder != "none" && parsedTraits) {
        parsedTraits.sort((a, b) => {
            if (sortColumn == 1) {
                if (sortOrder == "asc") {
                    return a.trait?.name.toString().localeCompare(b.trait?.name);
                } else if (sortOrder == "desc") {
                    return b.trait?.name.toString().localeCompare(a.trait?.name);
                }
            } else if (sortColumn == 2) {
                const aValue = a.value?.label ? a.value?.label : "";
                const bValue = b.value?.label ? b.value?.label : "";

                if (a.trait?.rankedNumerically && b.trait?.rankedNumerically) {
                    if (sortOrder == "asc") {
                        if (a.value?.label == null && b.value?.label == null) return 0;
                        if (a.value?.label == null && b.value?.label != null) return 1;
                        if (a.value?.label != null && b.value?.label == null) return -1;
                        return +aValue - +bValue;
                    } else if (sortOrder == "desc") {
                        if (a.value?.label == null && b.value?.label == null) return 0;
                        if (a.value?.label == null && b.value?.label != null) return 1;
                        if (a.value?.label != null && b.value?.label == null) return -1;
                        return +bValue - +aValue;
                    }
                }

                if (sortOrder == "asc") {
                    if (a.value?.label == null && b.value?.label == null) return 0;
                    if (a.value?.label == null && b.value?.label != null) return 1;
                    if (a.value?.label != null && b.value?.label == null) return -1;
                    return aValue.toString().localeCompare(bValue);
                } else if (sortOrder == "desc") {
                    if (a.value?.label == null && b.value?.label == null) return 0;
                    if (a.value?.label == null && b.value?.label != null) return 1;
                    if (a.value?.label != null && b.value?.label == null) return -1;
                    return bValue.toString().localeCompare(aValue);
                }
            } else if (sortColumn == 3) {
                if (sortOrder == "asc") {
                    return a.value?.amount - b.value?.amount;
                } else if (sortOrder == "desc") {
                    return b.value?.amount - a.value?.amount;
                }
            }
        });
    }

    const afterSortToggle = (cId, sortOrder, e) => {
        useSessionStore.setState(() => ({ traitSortColumn: cId, traitSortOrder: sortOrder }));
    };

    const parsedTraitsList: { trait: TraitMapItem; value: TraitValue }[] = token.isInvalid
        ? INVALID_TOKEN_TRAITS
        : parsedTraits;

    return (
        <>
            <Grid
                h="100%"
                templateRows={`auto 1fr`}
                templateAreas={`"header" "body"`}
                fontSize={scaledPixel(16)}
            >
                <GridItem area="header" bgColor="spaceCadet">
                    <Grid templateColumns={`1fr 1.5fr ${scaledPixel(150)}`}>
                        <GridItem p={scaledPixel(8)}>
                            <SortColumn
                                cId={1}
                                sortOrder={sortColumn == 1 ? sortOrder : "none"}
                                afterToggle={afterSortToggle}
                                position="sticky"
                            >
                                Trait
                            </SortColumn>
                        </GridItem>
                        <GridItem p={scaledPixel(8)}>
                            <SortColumn
                                cId={2}
                                sortOrder={sortColumn == 2 ? sortOrder : "none"}
                                afterToggle={afterSortToggle}
                            >
                                Value
                            </SortColumn>
                        </GridItem>
                        <GridItem p={scaledPixel(8)}>
                            <Flex direction="column" w="100%" alignItems="end">
                                <SortColumn
                                    cId={3}
                                    sortOrder={sortColumn == 3 ? sortOrder : "none"}
                                    afterToggle={afterSortToggle}
                                >
                                    Occurances
                                </SortColumn>
                            </Flex>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem area="body" overflowY="auto" wordBreak="break-word">
                    <Grid
                        templateColumns={`1fr 1.5fr ${scaledPixel(150)}`}
                        fontSize={scaledPixel(18)}
                        color="lavenderGray"
                    >
                        {parsedTraitsList.map((parsed, index) => {
                            const bgColor = index % 2 ? "table.even" : "table.odd";
                            return (
                                <Fragment key={`row-${index}`}>
                                    <GridItem bgColor={bgColor} p={scaledPixel(8)}>
                                        <Flex justify="start" align="center" gap={1}>
                                            {parsed.trait?.name}
                                            {parsed.trait?.rankedNumerically && (
                                                <RankedNumericallyTooltipIcon />
                                            )}
                                        </Flex>
                                    </GridItem>
                                    <GridItem
                                        bgColor={bgColor}
                                        p={scaledPixel(8)}
                                        justifyContent="start"
                                        alignItems="center"
                                        display="flex"
                                    >
                                        <Box
                                            color={
                                                parsed.value?.label != null ? undefined : "rythm"
                                            }
                                            fontSize={
                                                parsed.value?.label != null ? undefined : "80%"
                                            }
                                        >
                                            {parsed.value?.label != null
                                                ? parsed.value?.label
                                                : "(None)"}
                                        </Box>
                                    </GridItem>
                                    <GridItem
                                        bgColor={bgColor}
                                        p={scaledPixel(8)}
                                        textAlign="right"
                                    >
                                        {parsed.value?.amount} (
                                        {(
                                            (parsed.value?.amount / collection?.itemsAmount) *
                                            100
                                        ).toFixed(2)}
                                        %)
                                    </GridItem>
                                </Fragment>
                            );
                        })}
                    </Grid>
                </GridItem>
            </Grid>
        </>
    );
};

export default TokenTraitsTabPanel;
