import React from "react";
import { Flex } from "@chakra-ui/react";
import { BreadcrumbChip } from "@components/ui";

type TokenIdsListProps = {
    tokenStringIds: TokenStringId[];
    onRemove?: (number) => void;
};

const TokenIdsList = ({ tokenStringIds, onRemove }: TokenIdsListProps): JSX.Element => {
    const onRemoveChip = (tokenStringId: TokenStringId): void => {
        onRemove?.(tokenStringId);
    };

    if (!tokenStringIds || tokenStringIds.length === 0) return null;

    return (
        <Flex gap="2px">
            <BreadcrumbChip label="Token ID" notRemovable />
            {tokenStringIds.map((tokenStringId) => (
                <BreadcrumbChip
                    key={tokenStringId}
                    value={`#${tokenStringId}`}
                    onClick={onRemoveChip}
                    onClickParam={tokenStringId}
                />
            ))}
        </Flex>
    );
};

export default TokenIdsList;
