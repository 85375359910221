import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { TokenListItemMobile } from "@components/ui";
import useCollectionTokensStore, { getTokenById } from "@store/collectionTokensStore";
import useChartStore, { setChartActiveToken } from "@store/chartStore";
import { useSessionStore } from "@store/sessionStore";
import TokenListSkeleton from "./TokenListSkeleton";
import useTokenModal from "@hooks/useTokenModal";
import useCollectionStore from "@store/collectionStore";
import { useVirtualizer } from "@tanstack/react-virtual";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import TokenListMobileExpandButton from "./TokenListMobileExpandButton";

const TokenListMobile = (): JSX.Element => {
    const scrollRef = useRef<HTMLDivElement | null>();
    const mobileLastCenteredToken = useRef<number>();
    const loading = useCollectionTokensStore((state) => state.loading);
    const collection = useCollectionStore((state) => state.collection);
    const tokens: Token[] = useCollectionTokensStore((state) => state.filteredSortedTokens);
    const mobileTooltipTokenStringId = useChartStore((state) => state.mobileTooltipTokenStringId);
    const expanded = useSessionStore((state) => state.mobileTokenItemExpanded);

    const { showModalToken } = useTokenModal();
    const { windowWidth } = useDebouncedWindowSize(
        { useIsDesktop: true, useWindowWidth: true },
        // { debounceRateMilliseconds: 0 },
    );

    const rowVirtualizer = useVirtualizer({
        horizontal: true,
        count: tokens.length,
        overscan: 6,
        getScrollElement: () => scrollRef.current,
        estimateSize: () => 165,
        scrollPaddingStart: -70,
        paddingStart: Math.round(165 / 2 + 50),
        paddingEnd: Math.round(165 / 2 + 50),
        onChange: (virtualizer) => onListScroll(virtualizer),
    });

    // Restores the scroll to the begining of the list when the filtered tokens list changes.
    useLayoutEffect(() => {
        if (rowVirtualizer) {
            rowVirtualizer.scrollElement?.scrollTo(0, 0);
            rowVirtualizer.measure();
        }
    }, [tokens, tokens.length, rowVirtualizer, windowWidth]);

    // fires a virtual list remeasuiring on window resize
    useEffect(() => {
        if (rowVirtualizer) {
            rowVirtualizer?.measure();
            onListScroll(rowVirtualizer);
        }
    }, [windowWidth]);

    useEffect(() => {
        if (mobileTooltipTokenStringId) {
            const tokenIndex = tokens.findIndex(
                (token) => token.tokenStringId === mobileTooltipTokenStringId,
            );

            if (tokenIndex != null) {
                const token = tokens[tokenIndex];

                setChartActiveToken({
                    tokenStringId: token.tokenStringId,
                    currentPrice: token.currentPrice,
                    activationType: "hover",
                });

                rowVirtualizer.scrollToIndex(tokenIndex, { align: "center", behavior: "auto" });
            }
        }
    }, [mobileTooltipTokenStringId]);

    const onListScroll = (virtualizer): void => {
        if (!scrollRef.current) return;

        const { startIndex, endIndex } = virtualizer.range;
        let middleIndex = Math.round((startIndex + endIndex) / 2);

        // fixes the highlight of the first item when the scroll is at the begining
        if (scrollRef.current.scrollLeft < 50) middleIndex = 0;

        if (middleIndex !== mobileLastCenteredToken.current && scrollRef.current) {
            mobileLastCenteredToken.current = middleIndex;
            const activeToken = tokens[middleIndex];

            if (activeToken?.tokenStringId) {
                debouncedTokenMouseInteraction({
                    tokenStringId: activeToken.tokenStringId,
                    currentPrice: activeToken.currentPrice,
                    activationType: "hover",
                });
            }
        }
    };

    const onShowTokenModal = (tokenStringId: TokenStringId, itemIndex: number) => {
        if (getTokenById(tokenStringId)) {
            showModalToken(tokenStringId);
            if (rowVirtualizer) rowVirtualizer.scrollToIndex(itemIndex, { align: "center" });
        }
    };

    // eslint-disable-next-line
    const debouncedTokenMouseInteraction = useCallback(
        debounce((data?: ChartActiveToken) => {
            if (data) {
                setChartActiveToken(data);
            } else {
                setChartActiveToken(null);
            }
        }, 100),
        [],
    );

    const onMobileListExpandedChange = (expanded: boolean): void => {
        if (scrollRef.current) {
            scrollRef.current.style.height = expanded ? "330px" : "215px";
        }
    };

    // Returns a skeleton list if token data is still fetching
    if (!tokens || loading) return <TokenListSkeleton isDesktop={false} />;

    return (
        <Box w="100vw">
            <Box
                ref={scrollRef}
                h={!expanded ? "215px" : "330px"}
                width="100%"
                maxW="100vw"
                bgColor="blue.darker"
                overflow="auto"
            >
                <Box h="100%" w={`${rowVirtualizer.getTotalSize()}px`} position="relative">
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                        const token = tokens[virtualItem.index];

                        return (
                            <Box
                                key={virtualItem.key}
                                position="absolute"
                                top="14px"
                                left={0}
                                width={`${virtualItem.size}px`}
                                height={`${virtualItem.size}px`}
                                style={{ transform: `translateX(${virtualItem.start}px)` }}
                            >
                                <TokenListItemMobile
                                    token={token}
                                    itemIndex={virtualItem.index}
                                    collection={collection}
                                    onTokenSelected={onShowTokenModal}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            <TokenListMobileExpandButton onExpandedChange={onMobileListExpandedChange} />
        </Box>
    );
};

TokenListMobile.displayName = "TokenListMobile";

export default TokenListMobile;
