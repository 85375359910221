import { useEffect, useRef, useState } from "react";
import {
    Box,
    Grid,
    GridItem,
    Text,
    Icon,
    Flex,
    Spacer,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    HStack,
} from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";
import { useSessionStore } from "@store/sessionStore";
import TokenLayoutValue from "@components/tokenCommon/TokenLayoutValue";
import TokenHotColdVerticalBand from "@components/tokenCommon/TokenHotColdVerticalBand";
import TokenHotColdBadge from "@components/tokenCommon/TokenHotColdBadge";
import TokenImagePlayer from "@components/tokenCommon/TokenImagePlayer";
import useTokenModal from "@hooks/useTokenModal";
import { ClickHoverPopover, ClickHoverPopoverTrigger } from "@components/ui";
import PortalTooltip from "@components/ui/PortalTooltip";
import OwnedDiamondImage from "@components/images/OwnedDiamondImage";

const EthIcon = () => <Icon as={FaEthereum} marginTop="-1px" />;

const TokenListItemTooltip = ({ token, collection }: TokenListItemProps): JSX.Element => {
    const { showModalToken } = useTokenModal();
    const isConnected = useSessionStore((state) => state.connected);
    const playerRef = useRef(null);

    const [briteInfoIsOpen, setBriteInfoOpen] = useState(false);

    const tokenIdContainerRef = useRef(null);
    const tokenIdRef = useRef(null);
    const [isTokenIdOverflown, setTokenIdOverflown] = useState(false);

    useEffect(() => {
        const element = tokenIdRef?.current;
        // console.debug("offset width: ", element?.offsetWidth);
        // console.debug("scroll width: ", element?.scrollWidth);
        // console.debug("client width: ", element?.clientWidth);
        setTokenIdOverflown(element?.scrollWidth > element?.clientWidth);
    }, [tokenIdRef, tokenIdContainerRef, token?.tokenId]);

    const handleOnTokenClick = (e) => {
        showModalToken(token.tokenStringId);
    };

    const getTooltipContent = (value: string) => (
        <Box maxW="400px" textAlign="center" lineHeight="120%">
            {value}
        </Box>
    );

    return (
        <Box
            position="relative"
            flex={1}
            mt={token.currentPrice != null && isConnected ? "8px" : "4px"}
            _hover={{ cursor: "pointer" }}
            onClick={handleOnTokenClick}
        >
            <Box p="2px" borderRadius="12px" _hover={{ bgColor: "gold.500" }} overflow="hidden">
                <Grid
                    templateColumns="98px 1fr"
                    bgColor="gray.tokenListItem"
                    borderRadius="10px"
                    overflow="hidden"
                >
                    <GridItem>
                        <TokenImagePlayer
                            name={token.name}
                            collectionName={collection.name}
                            tokenStringId={token.tokenStringId}
                            imageUrl={token.imageUrl?.replace(/(\?.*)(w=.+)(&.+)/gm, "$1w=100$3")}
                            animationUrl={token.animationUrl}
                        />
                    </GridItem>
                    <GridItem p="6px 6px 4px 6px">
                        <Flex direction="column" h="100%">
                            <Box px="10px">
                                <Grid
                                    templateColumns="auto auto"
                                    templateRows="auto auto auto"
                                    templateAreas={`
                                    "tokenIdLabel rankLabel"
                                    "tokenId rank"
                                `}
                                >
                                    <GridItem
                                        area="tokenIdLabel"
                                        pb="2px"
                                        textStyle="tokenListItem.label"
                                    >
                                        <HStack textAlign="center" spacing="10px">
                                            <p>Token ID</p>
                                        </HStack>
                                    </GridItem>
                                    <GridItem
                                        area="rankLabel"
                                        textAlign="right"
                                        pb="2px"
                                        textStyle="tokenListItem.label"
                                    >
                                        Rarity Rank
                                    </GridItem>
                                    <GridItem area="tokenId" textStyle="tokenListItem.id">
                                        <Box w="130px">
                                            {isTokenIdOverflown ? (
                                                <PortalTooltip
                                                    content={getTooltipContent(token.tokenStringId)}
                                                    whiteSpace="normal"
                                                    allowSelect
                                                >
                                                    <Box
                                                        ref={tokenIdRef}
                                                        textOverflow="ellipsis"
                                                        overflow="hidden"
                                                        whiteSpace="nowrap"
                                                        lineHeight="100%"
                                                    >
                                                        {token.tokenStringId}
                                                    </Box>
                                                </PortalTooltip>
                                            ) : (
                                                <Box
                                                    ref={tokenIdRef}
                                                    textOverflow="ellipsis"
                                                    overflow="hidden"
                                                    whiteSpace="nowrap"
                                                    lineHeight="100%"
                                                >
                                                    {token.tokenStringId}
                                                </Box>
                                            )}
                                        </Box>
                                    </GridItem>
                                    <GridItem
                                        area="rank"
                                        textAlign="right"
                                        textStyle="tokenListItem.rank"
                                    >
                                        {token.rank}
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Spacer />
                            <Box>
                                <Grid
                                    templateColumns="1fr 1fr"
                                    templateRows="auto auto"
                                    templateAreas={`
                                    "price projection"
                                    "priceLabel projectionLabel"
                                    `}
                                    columnGap="2px"
                                >
                                    <GridItem
                                        area="price"
                                        display={briteInfoIsOpen ? "none" : undefined}
                                        borderLeftRadius="10px"
                                        textAlign="center"
                                        bgColor="spaceCadet"
                                        px="4px"
                                        py="8px"
                                        textStyle="tokenListItem.price"
                                    >
                                        <TokenLayoutValue
                                            value={token.currentPrice}
                                            requiresAuth={false}
                                        />
                                    </GridItem>
                                    <GridItem
                                        area="projection"
                                        borderRightRadius="10px"
                                        textAlign="center"
                                        bgColor="spaceCadet"
                                        px="4px"
                                        py="8px"
                                        textStyle="tokenListItem.projection"
                                    >
                                        <TokenLayoutValue value={token.priceProjection} />
                                    </GridItem>
                                    <GridItem
                                        area="priceLabel"
                                        display={briteInfoIsOpen ? "none" : undefined}
                                        pt="3px"
                                    >
                                        <Flex
                                            w="100%"
                                            h="100%"
                                            textStyle="tokenListItem.label"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <EthIcon /> List Price
                                        </Flex>
                                    </GridItem>
                                    <GridItem area="projectionLabel" pt="3px">
                                        <Flex
                                            w="100%"
                                            h="100%"
                                            textStyle="tokenListItem.label"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <EthIcon /> <b>BR</b>ite Price
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </Flex>
                    </GridItem>
                </Grid>
                {isConnected ? (
                    <>
                        <TokenHotColdVerticalBand
                            show={token.currentPrice != null && isConnected}
                            isHot={token.deviation > 0}
                            width="4px"
                            top="0px"
                            left={`${98 + 2}px`}
                        />
                        <TokenHotColdBadge
                            show={token.currentPrice != null && isConnected}
                            isHot={token.deviation > 0}
                            width="20px"
                            height="20px"
                            top="-8px"
                            left={`${98 - 6}px`}
                            padding="3px"
                        />
                    </>
                ) : null}
                {token.own && (
                    <Box
                        position="absolute"
                        width="25px"
                        height="25px"
                        top="-10px"
                        left="-10px"
                        background="linear-gradient(180deg, #FFFFFF75 0%, #927CFF75 100%)"
                        borderRadius="50%"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <OwnedDiamondImage width="12px" />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default TokenListItemTooltip;
