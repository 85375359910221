import { Grid, GridItem, Icon, IconProps, Link } from "@chakra-ui/react";
import { GemXyzIcon, LooksRareIcon, OpenSeaIcon, X2Y2Icon } from "@components/icons";
import useCollectionStore from "@store/collectionStore";
import useSessionStore from "@store/sessionStore";
import chakraTheme from "@theme";

type TokenModalMarketplaceButtonsProps = {
    token: Token;
    isDesktop?: boolean;
};

export const TokenModalMarketplaceButtons = ({
    token,
    isDesktop = false,
    ...props
}: TokenModalMarketplaceButtonsProps & IconProps) => {
    const collection = useCollectionStore((state) => state.collection);
    const isConnected = useSessionStore((state) => state.connected);
    const isListed = token?.currentPrice != null;
    const listedHighlightColor = !isListed
        ? null
        : !isConnected
        ? chakraTheme.colors.chart.datapoint.listed.disconnected.point
        : token.deviation > 0
        ? "#D5B464"
        : chakraTheme.colors.cold;

    const boxSize = isDesktop ? "32px" : "20px";

    let boxShadow = undefined;
    if (listedHighlightColor) {
        isDesktop
            ? (boxShadow = `0px 0px 20px 0px ${listedHighlightColor}`)
            : (boxShadow = `0px 0px 10px 0px ${listedHighlightColor}`);
    }
    const marketplaceBoxShadow = new Array(5)
        .fill(boxShadow)
        .map((boxShadow, index) => (token.marketplace == index ? boxShadow : undefined));

    return (
        <>
            <Grid templateColumns="repeat(4, 1fr)" columnGap={isDesktop ? "10px" : "8px"}>
                <GridItem>
                    <Link
                        isExternal
                        href={`https://opensea.io/assets/ethereum/${collection.contract}/${token.tokenStringId}`}
                    >
                        <Icon
                            as={OpenSeaIcon}
                            boxSize={boxSize}
                            bgColor="gray.light"
                            color="blue.mid"
                            _hover={{
                                cursor: "pointer",
                                bgColor: "gold.500",
                            }}
                            boxShadow={marketplaceBoxShadow[1]}
                            rounded="50%"
                            p={isDesktop ? "4px" : "3px"}
                            {...props}
                        />
                    </Link>
                </GridItem>
                <GridItem>
                    <Link
                        isExternal
                        href={`https://looksrare.org/collections/${collection.contract}/${token.tokenStringId}`}
                    >
                        <Icon
                            as={LooksRareIcon}
                            boxSize={boxSize}
                            bgColor="gray.light"
                            color="blue.mid"
                            _hover={{
                                cursor: "pointer",
                                bgColor: "gold.500",
                            }}
                            boxShadow={marketplaceBoxShadow[2]}
                            rounded="50%"
                            p="2px"
                            {...props}
                        />
                    </Link>
                </GridItem>
                <GridItem>
                    <Link
                        isExternal
                        href={`https://x2y2.io/eth/${collection.contract}/${token.tokenStringId}`}
                    >
                        <Icon
                            as={X2Y2Icon}
                            boxSize={boxSize}
                            bgColor="gray.light"
                            color="blue.mid"
                            _hover={{
                                cursor: "pointer",
                                bgColor: "gold.500",
                            }}
                            boxShadow={marketplaceBoxShadow[3]}
                            rounded="50%"
                            p={isDesktop ? "4px" : "3px"}
                            {...props}
                        />
                    </Link>
                </GridItem>
                <GridItem>
                    <Link
                        isExternal
                        href={`https://www.gem.xyz/asset/${collection.contract}/${token.tokenStringId}`}
                    >
                        <Icon
                            as={GemXyzIcon}
                            boxSize={boxSize}
                            bgColor="gray.light"
                            color="blue.mid"
                            _hover={{
                                cursor: "pointer",
                                bgColor: "gold.500",
                            }}
                            rounded="50%"
                            p="2px"
                            {...props}
                        />
                    </Link>
                </GridItem>
            </Grid>
        </>
    );
};

export default TokenModalMarketplaceButtons;
