import { AspectRatio, AspectRatioProps, Box, Flex, Icon, Spinner } from "@chakra-ui/react";
import Image from "next/image";
import { memo, useEffect, useRef, useState } from "react";
import { MdOutlineImageNotSupported } from "react-icons/md";
import ReactPlayer from "react-player";

type TokenImagePlayerProps = {
    name: string;
    collectionName: string;
    tokenStringId: TokenStringId;
    imageUrl?: string;
    animationUrl?: string;
    aspectRatioProps?: AspectRatioProps;
    useFallback?: boolean;
};

const TokenImagePlayer = ({
    name,
    collectionName,
    tokenStringId,
    imageUrl,
    animationUrl,
    aspectRatioProps,
}: TokenImagePlayerProps) => {
    // console.debug("image: ", imageUrl)
    // console.debug("animationUrl: ", animationUrl)

    const [imageStatus, setImageStatus] = useState<"loading" | "loaded" | "error">("loading");
    const videoRef = useRef<HTMLVideoElement>();

    const isVideo = animationUrl && ReactPlayer.canPlay(animationUrl);

    const tokenImgAlt = collectionName
        ? `Beyond Rarity - ${collectionName} - Token #${tokenStringId}`
        : `Beyond Rarity - ${name} - Token #${tokenStringId}`;

    useEffect(() => {
        if (!imageUrl && !animationUrl) setImageStatus("error");

        const video = videoRef.current;

        const revealThumbnail = () => {
            video.style.opacity = "1";
            setImageStatus("loaded");
        };

        if (video) video.addEventListener("canplay", revealThumbnail);

        return () => {
            if (video) {
                video.src = "";
                video.removeEventListener("canplay", revealThumbnail);
            }
        };
    }, []);

    const onImageError = () => {
        setImageStatus("error");
    };

    const onImageLoad = () => {
        setImageStatus("loaded");
    };

    const renderImageElement = () => {
        switch (imageStatus) {
            case "loading":
                return (
                    <Box w="32px" h="32px">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="steelblue.500"
                        />
                    </Box>
                );

            case "error":
                return (
                    <Flex layerStyle="tokenModal.imageLoadError" flexDir="column">
                        <Icon
                            as={MdOutlineImageNotSupported}
                            fontSize="40px"
                            color="whiteAlpha.500"
                        />
                        <Box
                            py="6px"
                            color="whiteAlpha.500"
                            fontSize="13px"
                            textAlign="center"
                            lineHeight="110%"
                        >
                            No Image Available
                        </Box>
                    </Flex>
                );

            default:
                return null;
        }
    };

    const renderImage = () => (
        <Box
            opacity={imageStatus === "loaded" ? 1 : 0}
            transition="opacity .25s"
            borderTopRadius="10px"
            overflow="hidden"
        >
            {imageUrl && (
                <Image
                    src={imageUrl}
                    alt={tokenImgAlt}
                    objectFit="contain"
                    layout="fill"
                    onError={onImageError}
                    onLoadingComplete={onImageLoad}
                    priority
                    unoptimized
                />
            )}
        </Box>
    );

    const renderVideo = () => {
        const style = { opacity: 0, transition: "opacity .35s", objectFit: "contain" as const };

        return (
            <video
                ref={videoRef}
                height="100%"
                width="100%"
                src={animationUrl}
                muted
                style={style}
            />
        );
    };

    return (
        <AspectRatio w="100%" ratio={1} {...aspectRatioProps} bgColor="blackAlpha.100">
            <>
                {renderImageElement()}
                {isVideo ? renderVideo() : renderImage()}
            </>
        </AspectRatio>
    );
};

export default memo(TokenImagePlayer);
