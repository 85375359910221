import React from "react";
import { Text, Box, Icon } from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";

type FilterInputTitleProps = {
    label: string;
    showEthIcon?: boolean;
};

const renderEthIcon = (): JSX.Element => (
    <span>
        (<Icon as={FaEthereum} height="10px" verticalAlign="text-top" />)
    </span>
);

const FilterInputTitle = ({ label, showEthIcon }: FilterInputTitleProps): JSX.Element => {
    return (
        <Box flexDir="row" position="relative" textAlign="center" mb="3px">
            <Box
                position="absolute"
                w="100%"
                borderBottom="1px solid"
                borderBottomColor="rythm"
                top="calc(50% + 1.5px)"
                zIndex={1}
            />
            <Text
                textStyle="label"
                position="relative"
                display="inline"
                mb="3px"
                bg="spaceCadetLight"
                px="7px"
                zIndex={2}
            >
                {label} {showEthIcon && renderEthIcon()}
            </Text>
        </Box>
    );
};

export default FilterInputTitle;
