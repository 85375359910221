import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { useCollectionTokensStore } from "@store/collectionTokensStore";
import chakraTheme from "@theme";
import { ChartType } from "chart.js/auto";
import useSessionStore from "@store/sessionStore";
import { timeSince } from "@store/helpers";
import { getOverRangeValues, isDesktopSize } from "@utils/helpers";
import "@utils/number.extensions";
import useCollectionStore from "./collectionStore";

const MAX_PRICE_ABOVE_PROJECTION_FACTOR = 2;
const MAX_PRICE_ABOVE_FLOOR_FACTOR = 10;

type ChartState = {
    tooltipData: ChartTooltipData | null;
    mobileTooltipTokenStringId: TokenStringId | null;
    activeToken: ChartActiveToken | null;
    clearSelectedMobileToken: boolean;
    overRangeDataMap: Map<string, ChartTokenPointData> | null;
    hotDataMap: Map<string, ChartTokenPointData> | null;
    ownedDataMap: Map<string, ChartTokenPointData> | null;
    coldDataMap: Map<string, ChartTokenPointData> | null;
    projectionDataMap: Map<string, ChartTokenPointData> | null;
    ownedProjectionDataMap: Map<string, ChartTokenPointData> | null;
    ownedOverRangeDataMap: Map<string, ChartTokenPointData> | null;
    isZoomed: boolean;
    chartData?: any;
    maxY: number;
    overRangePrice: number;
};

// Store for Charts
export const useChartStore = create<ChartState>()(
    subscribeWithSelector((set) => ({
        tooltipData: null,
        mobileTooltipTokenStringId: null,
        activeToken: null,
        clearSelectedMobileToken: false,
        overRangeDataMap: null,
        hotDataMap: null,
        ownedDataMap: null,
        coldDataMap: null,
        projectionDataMap: null,
        ownedProjectionDataMap: null,
        ownedOverRangeDataMap: null,
        isZoomed: false,
        chartData: null,
        maxY: 0,
        overRangePrice: null,
    })),
);

export const setChartTooltipData = (newTooltipData: ChartTooltipData) => {
    useChartStore.setState(() => ({ tooltipData: newTooltipData }));
};

export const setMobileTooltipTokenStringId = (newMobileTooltipTokenStringId: TokenStringId) => {
    useChartStore.setState(() => ({ mobileTooltipTokenStringId: newMobileTooltipTokenStringId }));
};

export const setChartActiveToken = (newActiveToken: ChartActiveToken) => {
    useChartStore.setState((store) => {
        if (store.activeToken?.tokenStringId != newActiveToken?.tokenStringId)
            return { activeToken: newActiveToken };
    });
};

export const setListedDataMap = (newListedDataMap: Map<string, ChartTokenPointData>) => {
    useChartStore.setState(() => ({ hotDataMap: newListedDataMap }));
};

export const setProjectionDatasMap = (newProjectionDatasMap: Map<string, ChartTokenPointData>) => {
    useChartStore.setState(() => ({ projectionDataMap: newProjectionDatasMap }));
};

export const setIsZoomed = (newIsZoomed: boolean) => {
    useChartStore.setState(() => ({ isZoomed: newIsZoomed }));
};

const unsubscribeChangeFilteredTokens = useCollectionTokensStore.subscribe(
    (state) => state.filteredTokens,
    (newFilteredTokens) => {
        buildChartDatasets();
    },
);

const unsubscribeChangeAuthStatus = useSessionStore.subscribe(
    (state) => state.connected,
    (newConnectedStatus) => {
        buildChartDatasets();
    },
);

const buildChartDatasets = async () => {
    // Let's create some chart-specific data lists starting from the the tokens data
    // in the collectionTokensStore.
    let now = performance.now();

    const isConnected = useSessionStore.getState().connected;
    const collection = useCollectionStore.getState().collection;
    const allTokens = useCollectionTokensStore.getState().tokensList;
    const filteredTokens = useCollectionTokensStore.getState().filteredTokens;
    const lowestPrice = useCollectionTokensStore.getState().lowestPrice;
    const highestProjection = useCollectionTokensStore.getState().highestProjection;
    const bottomRank = useCollectionTokensStore.getState().bottomRank;

    // const InactiveOverRangeImage = new Image(12, 12);
    // InactiveOverRangeImage.src = "/ui/OverRangeList_Inactive.svg";

    // const ActiveOverRangeImage = new Image(18, 18);
    // ActiveOverRangeImage.src = "/ui/OverRangeList_Active.svg";

    // const ActiveDisconnectedOverRangeImage = new Image(18, 18);
    // ActiveDisconnectedOverRangeImage.src = "/ui/OverRangeList_Active-Disconnected.svg";

    const overRangePrice = isConnected
        ? highestProjection * MAX_PRICE_ABOVE_PROJECTION_FACTOR
        : (collection.floorPrice ? collection.floorPrice : lowestPrice ? lowestPrice : 1) *
          MAX_PRICE_ABOVE_FLOOR_FACTOR;

    let maxY = 0;

    // 1a. Creates a sublist of all filtered tokens that are listed (have currentPrice)
    // Also map the data for quick search througout the app.
    const hotDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const hotData = filteredTokens
        .filter(
            (token) =>
                token.currentPrice != null &&
                token.currentPrice <= token.priceProjection &&
                !token.own,
        )
        .map((token, index) => {
            const point: ChartTokenPointData = {
                token: token,
                x: token.rank,
                y: token.currentPrice,
                own: false,
                isInFiltered: true,
                datasetIndex: 0, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
                index: index,
                isCold: false,
                isOverRange: false,
            };
            if (token.currentPrice > maxY) maxY = token.currentPrice;

            hotDataMap.set(token.tokenStringId, point);
            return point;
        });

    const ownedDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const ownedData = filteredTokens
        .filter(
            (token) =>
                token.currentPrice != null && token.currentPrice < overRangePrice && token.own,
        )
        .map((token, index) => {
            const point: ChartTokenPointData = {
                token: token,
                x: token.rank,
                y: token.currentPrice,
                own: true,
                isInFiltered: true,
                datasetIndex: 5, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
                index: index,
                isCold: false,
                isOverRange: false,
            };
            if (token.currentPrice > maxY) maxY = token.currentPrice;

            ownedDataMap.set(token.tokenStringId, point);
            return point;
        });

    const coldDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const coldData = filteredTokens
        .filter(
            (token) =>
                token.currentPrice != null &&
                token.currentPrice > token.priceProjection &&
                token.currentPrice < overRangePrice &&
                !token.own,
        )
        .map((token, index) => {
            const point: ChartTokenPointData = {
                token: token,
                x: token.rank,
                y: token.currentPrice,
                own: false,
                isInFiltered: true,
                datasetIndex: 1, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
                index: index,
                isCold: true,
                isOverRange: false,
            };
            if (token.currentPrice > maxY) maxY = token.currentPrice;

            coldDataMap.set(token.tokenStringId, point);
            return point;
        });

    maxY = Math.max(maxY, highestProjection);

    // 1.5. Creates a sublist of all OVER RANGE filtered tokens that are listed (have currentPrice)
    // Also map the data for quick search througout the app.
    const overRangeDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const overRangeTempData = filteredTokens.filter(
        (token) =>
            token.currentPrice !== null && token.currentPrice >= overRangePrice && !token.own,
    );

    const { overRangePointY, maxY: calculatedMaxY } = getOverRangeValues(
        overRangePrice,
        isDesktopSize("app"),
    );
    maxY = calculatedMaxY;

    const overRangeData = overRangeTempData.map((token, index) => {
        const point: ChartTokenPointData = {
            token: token,
            x: token.rank,
            y: overRangePointY,
            own: false,
            isInFiltered: true,
            datasetIndex: 2, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
            index: index,
            isCold: true,
            isOverRange: true,
        };

        overRangeDataMap.set(token.tokenStringId, point);
        return point;
    });

    const ownedOverRangeDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const ownedOverRangeTempData = filteredTokens.filter(
        (token) => token.currentPrice !== null && token.currentPrice >= overRangePrice && token.own,
    );

    // const { overRangePointY, maxY: ownedCalculatedMaxY } = getOverRangeValues(
    //     overRangePrice,
    //     isDesktopSize("app"),
    // );
    // maxY = calculatedMaxY;

    const ownedOverRangeData = ownedOverRangeTempData.map((token, index) => {
        const point: ChartTokenPointData = {
            token: token,
            x: token.rank,
            y: overRangePointY,
            own: true,
            isInFiltered: true,
            datasetIndex: 6, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
            index: index,
            isCold: true,
            isOverRange: true,
        };

        ownedOverRangeDataMap.set(token.tokenStringId, point);
        return point;
    });

    // if (maxY > 1) {
    //     maxY = maxY.round(0);
    // } else {
    //     maxY = maxY.round(4);
    // }

    // 2. Ceate the projection line data based on all data (sorted).
    //    y axis is the the price projection which should only be available if the user is authorized
    //    Also map the data for quick search througout the app.

    const projectionDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const projectionData = allTokens
        .map((token, index) => {
            const point: ChartTokenPointData = {
                token: token,
                x: token.rank,
                y: isConnected ? token.priceProjection : maxY * 0.015,
                own: token.own,
                isInFiltered: filteredTokens.includes(token),
                datasetIndex: 3, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
                index: index,
                isCold: false,
                isOverRange: false,
            };

            projectionDataMap.set(token.tokenStringId, point);
            return point;
        })
        .sort((a, b) => b.x - a.x);
    //Need to reindex after sort
    projectionData.forEach((point, index) => (point.index = index));

    // 2A. Ceate the OWNED projection line data based on all data (sorted).
    //    y axis is the the price projection which should only be available if the user is authorized
    //    Also map the data for quick search througout the app.
    const ownedProjectionDataMap = new Map<TokenStringId, ChartTokenPointData>();
    const ownedProjectionData = filteredTokens
        .filter((token) => token.own && token.currentPrice == null)
        .map((token, index) => {
            // console.log("TEST TOKEN", token);
            const point: ChartTokenPointData = {
                token: token,
                x: token.rank,
                y: isConnected ? token.priceProjection : maxY * 0.015,
                own: true,
                isInFiltered: filteredTokens.includes(token),
                datasetIndex: 4, // MUST BE SAME AS INDEX ORDER ADDED TO THE "datasets" ARRAY
                index: index,
                isCold: false,
                isOverRange: false,
            };

            ownedProjectionDataMap.set(token.tokenStringId, point);
            return point;
        })
        .sort((a, b) => b.x - a.x);
    //Need to reindex after sort
    ownedProjectionData.forEach((point, index) => (point.index = index));

    // 3. Create the point hit radius and point radius for the points in projection line.
    //    Only unlisted/unowend points should be visible & interactive (listed points are in seperate dataset)
    const projectionDataHitRadius = new Array<number>();
    const projectionDataPointRadius = new Array<number>();
    projectionData.forEach((point) => {
        // set array of hit radius based on logic
        let hitRadius = 3;
        if (point.token.currentPrice != null) hitRadius = 0; // if it is listed, do not allow hit
        if (!point.isInFiltered) hitRadius = 0; // if it is not in filtered list, do not allow hit
        if (point.own) hitRadius = 0; // if it is owned, do not allow hit
        projectionDataHitRadius.push(hitRadius);

        // set array of point radius based on logic
        let pointRadius = 3;
        if (point.token.currentPrice != null) pointRadius = 0; // if it is listed, do not allow hit
        if (point.own) pointRadius = 0; // if it is owned, do not show
        if (!point.isInFiltered) pointRadius = 0; // if it is not in filtered list, do not allow hit
        projectionDataPointRadius.push(pointRadius);
    });

    const chartData = {
        datasets: [
            {
                data: hotData,
                order: 2,
                type: "scatter" as ChartType,
                label: "Listed Tokens",
                parsing: false as const,
                pointStyle: "circle",
                pointRadius: 7,
                pointBorderWidth: 1,
                pointBorderColor: chakraTheme.colors.chart.datapoint.listed.hot.pointBorder,
                pointBackgroundColor: chakraTheme.colors.chart.datapoint.listed.hot.point,
                pointHoverRadius: isDesktopSize("app") ? 10 : 10,
                pointHoverBorderWidth: isDesktopSize("app") ? 2 : 2,
                pointHoverBorderColor: hotData.map((point) =>
                    isDesktopSize("app")
                        ? isConnected
                            ? chakraTheme.colors.chart.datapoint.listed.hot.hoverBorder
                            : chakraTheme.colors.chart.datapoint.listed.disconnected.pointBorder
                        : isConnected
                        ? chakraTheme.colors.chart.datapoint.listed.hot.hoverBorder
                        : chakraTheme.colors.chart.datapoint.listed.disconnected.hoverBorder,
                ),
                pointHoverBackgroundColor: hotData.map((point) =>
                    isDesktopSize("app")
                        ? isConnected
                            ? chakraTheme.colors.chart.datapoint.listed.hot.hover
                            : chakraTheme.colors.chart.datapoint.listed.disconnected.point
                        : isConnected
                        ? chakraTheme.colors.chart.datapoint.listed.hot.hover
                        : chakraTheme.colors.chart.datapoint.listed.disconnected.hover,
                ),
                pointHitRadius: 7,
            },
            {
                data: coldData,
                order: 5,
                type: "scatter" as ChartType,
                label: "Listed Tokens",
                parsing: false as const,
                pointStyle: "circle",
                pointRadius: 5,
                pointBorderWidth: 1,
                pointBorderColor: coldData.map((point) =>
                    isConnected
                        ? chakraTheme.colors.chart.datapoint.listed.cold.pointBorder
                        : chakraTheme.colors.chart.datapoint.listed.disconnected.pointBorder,
                ),
                pointBackgroundColor: coldData.map((point) =>
                    isConnected
                        ? chakraTheme.colors.chart.datapoint.listed.cold.point
                        : chakraTheme.colors.chart.datapoint.listed.disconnected.point,
                ),
                pointHoverRadius: isDesktopSize("app") ? 10 : 10,
                pointHoverBorderWidth: isDesktopSize("app") ? 2 : 2,
                pointHoverBorderColor: coldData.map((point) =>
                    isDesktopSize("app")
                        ? isConnected
                            ? chakraTheme.colors.chart.datapoint.listed.cold.hoverBorder
                            : chakraTheme.colors.chart.datapoint.listed.disconnected.pointBorder
                        : isConnected
                        ? chakraTheme.colors.chart.datapoint.listed.cold.hoverBorder
                        : chakraTheme.colors.chart.datapoint.listed.disconnected.hoverBorder,
                ),
                pointHoverBackgroundColor: coldData.map((point) =>
                    isDesktopSize("app")
                        ? isConnected
                            ? chakraTheme.colors.chart.datapoint.listed.cold.hover
                            : chakraTheme.colors.chart.datapoint.listed.disconnected.point
                        : isConnected
                        ? chakraTheme.colors.chart.datapoint.listed.cold.hover
                        : chakraTheme.colors.chart.datapoint.listed.disconnected.hover,
                ),
                pointHitRadius: 5,
            },
            {
                data: overRangeData,
                order: 7,
                type: "scatter" as ChartType,
                label: "Over Range Tokens",
                pointStyle: (point) => {
                    if (point.active) {
                        if (isConnected) return document.getElementById("ActiveOverRangeImage");

                        return document.getElementById("ActiveDisconnectedOverRangeImage");
                    }
                    return document.getElementById("InactiveOverRangeImage");
                },
                pointRadius: 3,
                pointHitRadius: 5,
                pointHoverRadius: 5,
            },
            {
                data: projectionData,
                order: 4,
                type: "line" as ChartType,
                label: "Brite Line & Unlisted Tokens",
                // parsing: false as const, // TODO: Try to disable. Right now, if disabled, data is not drawn when zoomed in.
                tension: 0.2,
                showLine: isConnected,
                spanGaps: true as const,
                borderColor: chakraTheme.colors.sorbet,
                borderWidth: isConnected ? (isDesktopSize("app") ? 2 : 2) : 0,
                // pointStyle: "line" as const,
                // pointRotation: 90,
                pointRadius: projectionDataPointRadius,
                pointBorderWidth: 0,
                pointBorderColor: isConnected
                    ? chakraTheme.colors.chart.datapoint.unlisted.connected.pointBorder
                    : chakraTheme.colors.chart.datapoint.unlisted.disconnected.pointBorder,
                pointBackgroundColor: isConnected
                    ? chakraTheme.colors.chart.datapoint.unlisted.connected.point
                    : chakraTheme.colors.chart.datapoint.unlisted.disconnected.point,
                pointHoverRadius: isDesktopSize("app") ? 10 : 10,
                pointHoverBorderWidth: isDesktopSize("app") ? 2 : 2,
                pointHoverBorderColor: isConnected
                    ? chakraTheme.colors.chart.datapoint.unlisted.connected.hoverBorder
                    : chakraTheme.colors.chart.datapoint.unlisted.disconnected.hoverBorder,
                pointHoverBackgroundColor: isConnected
                    ? chakraTheme.colors.chart.datapoint.unlisted.connected.hover
                    : chakraTheme.colors.chart.datapoint.unlisted.disconnected.hover,
                pointHitRadius: projectionDataHitRadius,
            },
            {
                data: ownedProjectionData,
                order: 3,
                type: "scatter" as ChartType,
                label: "User Owned Tokens - Unlisted",
                pointStyle: (point) => {
                    if (point.active) {
                        return document.getElementById("OwnedDiamondHover");
                    }
                    return document.getElementById("OwnedDiamond");
                },
                pointRadius: 5,
                pointHitRadius: 5,
                pointHoverRadius: 10,
            },
            {
                data: ownedData,
                order: 1,
                type: "scatter" as ChartType,
                label: "User Owned Tokens - Listed",
                parsing: false as const,
                pointStyle: (point) => {
                    if (point.active) {
                        return document.getElementById("ListedOwnedDiamondHover");
                    }
                    return document.getElementById("ListedOwnedDiamond");
                },
                pointRadius: 5,
                pointHitRadius: 5,
                pointHoverRadius: 10,
            },
            {
                data: ownedOverRangeData,
                order: 6,
                type: "scatter" as ChartType,
                label: "Listed Tokens",
                parsing: false as const,
                pointStyle: (point) => {
                    if (point.active) {
                        return document.getElementById("ActiveOwnedOverRangeImage");
                    }
                    return document.getElementById("OwnedOverangeImage");
                },
                pointRadius: 4,
                pointHitRadius: 4,
                pointHoverRadius: 8,
            },
            {
                data: [
                    { x: bottomRank, y: maxY },
                    { x: 1, y: maxY },
                ],
                order: 8,
                type: "scatter" as ChartType,
                label: "anchor",
                pointRadius: 10,
                pointHitRadius: 0,
                pointBackgroundColor: "#fff0",
            },
        ],
    };

    /*
    console.info(
        `%cChart-Specific lists built in ${timeSince(now)}`,
        "background:#a53B4C;color:#eabd4e;font-size:.9rem;font-weight:bold;",
    );
    */

    useChartStore.setState(() => ({
        tooltipData: null,
        mobileTooltipTokenStringId: null,
        activeToken: null,
        clearSelectedMobileToken: false,
        isZoomed: false,
        projectionDataMap: projectionDataMap,
        hotDataMap: hotDataMap,
        ownedDataMap: ownedDataMap,
        ownedProjectionDataMap: ownedProjectionDataMap,
        coldDataMap: coldDataMap,
        overRangeDataMap: overRangeDataMap,
        chartData: chartData,
        maxY: maxY,
        overRangePrice: overRangePrice,
        ownedOverRangeDataMap: ownedOverRangeDataMap,
    }));
};

export default useChartStore;
