import { Box, Flex, Grid, GridItem, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useState, RefObject, ReactNode, useRef } from "react";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";
import Tab from "./Tab";
import TokenTraitsTabPanel from "./TokenTraitsTabPanel";
import TokenVibesTabPanel from "./TokenVibesTabPanel";
import config from "@components/tokenModal/tokenModalConfig";
import useCollectionStore from "@store/collectionStore";

type TokenTraitsVibesLandscapeProps = {
    collection: CollectionData;
    token: Token;
    scale: number;
    rootHeight: number;
    headerHeight: number;
};

export const TokenTraitsVibesLandsape = ({
    collection,
    token,
    scale,
    rootHeight,
    headerHeight,
}: TokenTraitsVibesLandscapeProps) => {
    const traitsMap = useCollectionStore((state) => state.traitsMap);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [tableHeight, setTableHeight] = useState(0);
    const tabsContainerRef = useRef();

    useEffect(() => {
        const tabsContainer: HTMLElement = tabsContainerRef.current;

        if (tabsContainer) {
            // console.debug("rootContainer height: ", rootContainer.clientWidth);
            // console.debug("headerContainer height: ", headerContainer.scrollHeight);
            // console.debug("tabsContainer height: ", tabsContainer.scrollHeight);
            const newH =
                rootHeight -
                headerHeight -
                tabsContainer.scrollHeight -
                scale * (scale > 1 ? 1.3 : 1) * config.landscape.infoSectionPadding * 2;
            setTableHeight(newH);
        }
    }, [rootHeight, headerHeight, scale]);

    // console.debug("table height: ", tableHeight);

    const handleTabClick = (tabIndex, e) => {
        e.stopPropagation();
        setSelectedTabIndex(tabIndex);
    };

    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    const parsedTraits: { trait: TraitMapItem; value: TraitValue }[] = token?.traits?.map(
        (tokenTrait, index) => {
            const trait = traitsMap.get(tokenTrait[0]);
            const traitValue = trait?.values.get(tokenTrait[1]);

            return { trait: trait, value: traitValue };
        },
    );

    return (
        <>
            <Grid templateRows={`auto ${tableHeight}px`} w="100%">
                <GridItem
                    w="100%"
                    fontSize="18px"
                    // borderBottom="1px solid"
                    // borderBottomColor="whiteAlpha.300"
                    ref={tabsContainerRef}
                >
                    <Flex
                        w="100%"
                        gap={scaledPixel(10)}
                        fontSize={scalePercentText}
                        fontWeight="bold"
                    >
                        <Tab
                            tabIndex={0}
                            isSelected={selectedTabIndex == 0}
                            onTabSelect={handleTabClick}
                            scale={scale}
                            isSkeleton={token.isInvalid}
                        >
                            <Box fontFamily="regular" fontWeight="normal">
                                Traits{" "}
                                <Text as="span" fontFamily="condensed" fontWeight="light">
                                    (
                                    {parsedTraits.reduce(
                                        (previousValue, currentValue) =>
                                            previousValue +
                                            (currentValue.value?.label != null ? 1 : 0),
                                        0,
                                    )}
                                    )
                                </Text>
                            </Box>
                        </Tab>
                        {token.vibes && token.vibes?.length > 0 && (
                            <Tab
                                tabIndex={1}
                                isSelected={selectedTabIndex == 1}
                                onTabSelect={handleTabClick}
                                isDisabled={!token.vibes?.length}
                                scale={scale}
                                isSkeleton={token.isInvalid}
                            >
                                <Box fontFamily="regular" fontWeight="normal">
                                    Vibes{" "}
                                    <Text as="span" fontFamily="condensed" fontWeight="light">
                                        ({token.vibes?.length || 0})
                                    </Text>
                                </Box>
                            </Tab>
                        )}
                    </Flex>
                </GridItem>
                <GridItem
                    bgColor="blue.dark"
                    borderBottomRadius={scaledPixel(8)}
                    overflowY="hidden"
                >
                    {token.isInvalid ? (
                        <Skeleton w="100%" h="100%" />
                    ) : token.vibes?.length ? (
                        {
                            0: (
                                <TokenTraitsTabPanel
                                    collection={collection}
                                    token={token}
                                    scale={scale}
                                />
                            ),
                            1: (
                                <TokenVibesTabPanel
                                    collection={collection}
                                    token={token}
                                    scale={scale}
                                />
                            ),
                        }[selectedTabIndex]
                    ) : (
                        <TokenTraitsTabPanel collection={collection} token={token} scale={scale} />
                    )}
                </GridItem>
            </Grid>
        </>
    );
};

export default TokenTraitsVibesLandsape;
