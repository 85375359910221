import { useEffect, useRef, useState } from "react";
import {
    Box,
    Center,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Skeleton,
    Text,
    VStack,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useCollectionStore from "@store/collectionStore";
import TokenPriceInfo from "./tokenPriceInfo/TokenPriceInfo";
import TokenModalImagePlayer from "@components/tokenCommon/TokenModalImagePlayer";
import TokenModalMarketplaceButtons from "@components/tokenModal/TokenModalMarketplaceButtons";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";
import config from "@components/tokenModal/tokenModalConfig";
import TokenTraitsVibesLandsape from "./tokenTraitsVibes/TokenTraitsVibesLandscape";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import useCollectionTokensStore from "@store/collectionTokensStore";
import useTokenModal from "@hooks/useTokenModal";
import { useSwipeable } from "react-swipeable";
import usePersistedStore from "@store/persistedStore";
import PortalTooltip from "@components/ui/PortalTooltip";
import OwnedDiamondImage from "@components/images/OwnedDiamondImage";

type TokenModalLandscapeProps = {
    token?: Token;
    onModalClose?: () => void;
};

export const TokenModalLandscape = ({ token, onModalClose }: TokenModalLandscapeProps) => {
    const collection = useCollectionStore((state) => state.collection);
    const userOwnedTokens = useCollectionTokensStore((state) => state.userOwnedTokens);
    const filteredSortedPrevNextMap = useCollectionTokensStore(
        (state) => state.filteredSortedPrevNextMap,
    );
    const { showModalToken, closeTokenModal } = useTokenModal();
    const { windowWidth, windowHeight } = useDebouncedWindowSize({
        useWindowHeight: true,
        useWindowWidth: true,
    });
    const [explicitClosed, setExplicitClosed] = useState<boolean>();

    const [headerHeight, setHeaderHeight] = useState(0);
    const imageRef = useRef();
    const headerRef = useRef();

    const tokenNameRef = useRef(null);
    const [istokenNameOverflown, settokenNameOverflown] = useState(false);

    const tokenIdRef = useRef(null);
    const [isTokenIdOverflown, setTokenIdOverflown] = useState(false);

    // added to fix some states where explicitly closed is stuck to true
    // if token is changed, it should always reset explicityly closed back to false.
    useEffect(() => {
        setExplicitClosed(false);
    }, [token]);

    useEffect(() => {
        const element = tokenNameRef?.current;
        if (element) {
            settokenNameOverflown(element?.scrollWidth > element?.clientWidth);
        }
    }, [tokenNameRef, windowWidth, token?.name]);

    useEffect(() => {
        const element = tokenIdRef?.current;
        // console.debug("detected token id ref change: ", element);
        if (element) {
            // console.debug("updating id overflown: ", element?.scrollWidth > element?.clientWidth);
            setTokenIdOverflown(element?.scrollWidth > element?.clientWidth);
        }
    }, [tokenIdRef, windowWidth, token?.tokenStringId]);

    // eslint-disable-next-line
    useEffect(() => {
        const header: HTMLElement = headerRef.current;
        if (header) setHeaderHeight(header.scrollHeight);
    });

    const scale = windowWidth / config.landscape.baseWidth;
    const imageWidth = Math.min(windowWidth * 0.4, windowHeight * 0.8);
    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);
    const detailsPadding = config.landscape.infoSectionPadding * scale;

    const handleOnClose = () => {
        setExplicitClosed(true);
    };

    const handleOnCloseComplete = () => {
        onModalClose();
    };

    // console.debug("token: ", token)

    const prevNextData = filteredSortedPrevNextMap.get(token?.tokenStringId);

    const handleOnPrev = () => {
        if (prevNextData?.prevToken) showModalToken(prevNextData?.prevToken?.tokenStringId);
    };

    const handleOnNext = () => {
        if (prevNextData?.nextToken) showModalToken(prevNextData?.nextToken?.tokenStringId);
    };

    const setSwiped = () => {
        usePersistedStore.setState((oldState) => {
            if (oldState.tokenModalSwiped) return;

            return {
                tokenModalSwiped: true,
            };
        });
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            setSwiped();
            handleOnNext();
        },
        onSwipedRight: () => {
            setSwiped();
            handleOnPrev();
        },
    });

    if (!token) return null;

    const isOpen = !!token && !explicitClosed;

    return (
        <>
            <Modal
                onClose={handleOnClose}
                onCloseComplete={handleOnCloseComplete}
                isOpen={isOpen}
                isCentered
            >
                <ModalOverlay bgColor="#000a" backdropFilter="auto" backdropBlur="2px" />
                <ModalContent
                    w="90%"
                    maxW="90vw"
                    maxH="90vh"
                    bgColor="transparent"
                    {...swipeHandlers}
                >
                    <ModalBody p="0px" position="relative">
                        <Box
                            position="relative"
                            fontWeight="light"
                            bgColor="spaceCadetLight"
                            borderRadius={scaledPixel(10)}
                            overflow="hidden"
                        >
                            {token && (
                                <Grid
                                    templateColumns={`${imageWidth}px 1fr`}
                                    maxH={imageWidth + "px"}
                                >
                                    <GridItem ref={imageRef}>
                                        <TokenModalImagePlayer
                                            name={token.name}
                                            collectionName={collection.name}
                                            tokenStringId={token.tokenStringId}
                                            imageUrl={token?.imageUrl}
                                            animationUrl={token?.animationUrl}
                                        />
                                        {(token?.own ||
                                            userOwnedTokens?.includes(token.tokenStringId)) && (
                                            <Flex
                                                position="absolute"
                                                direction="row"
                                                bgColor="raisinBlackAlpha.70"
                                                borderRadius={scaledPixel(4)}
                                                p={scaledPixel(6)}
                                                top={scaledPixel(12)}
                                                left={scaledPixel(12)}
                                                justify="center"
                                                align="center"
                                                gap={1.5}
                                            >
                                                <OwnedDiamondImage w={scaledPixel(16)} />
                                                <Box
                                                    fontSize={scaledPixel(25)}
                                                    lineHeight="100%"
                                                    mt={1}
                                                >
                                                    Owned
                                                </Box>
                                            </Flex>
                                        )}
                                    </GridItem>
                                    <GridItem
                                        position="relative"
                                        p={`${scaledPixel(detailsPadding)} ${scaledPixel(
                                            detailsPadding * 1.2,
                                        )}`}
                                        overflow="hidden"
                                    >
                                        {token.isInvalid && (
                                            <Center layerStyle="tokenModal.invalid.overlay">
                                                <Text textStyle="invalidTokenModal.title">
                                                    Oops!
                                                </Text>
                                                <Text textStyle="invalidTokenModal.copy">
                                                    Looks like you encountered
                                                    <br />
                                                    an Invalid Token ID...
                                                </Text>
                                            </Center>
                                        )}
                                        <Grid h="100%" templateRows="auto 1fr">
                                            <GridItem pb={scaledPixel(40)} ref={headerRef}>
                                                <Grid gap={scaledPixel(20)}>
                                                    <GridItem>
                                                        <Grid
                                                            templateColumns={
                                                                token.isInvalid
                                                                    ? "70% 30%"
                                                                    : "1fr auto"
                                                            }
                                                            columnGap={scaledPixel(8)}
                                                        >
                                                            <GridItem>
                                                                <Skeleton
                                                                    h="100%"
                                                                    w={
                                                                        token.isInvalid
                                                                            ? "60%"
                                                                            : "100%"
                                                                    }
                                                                    isLoaded={!token.isInvalid}
                                                                    rounded="md"
                                                                >
                                                                    <Flex
                                                                        h="100%"
                                                                        maxW="100%"
                                                                        align="center"
                                                                        overflow="hidden"
                                                                    >
                                                                        <Box
                                                                            textStyle="tokenModalLandscape.name"
                                                                            maxW={scaledPixel(400)}
                                                                        >
                                                                            {istokenNameOverflown ||
                                                                            token.name.length >
                                                                                35 ? (
                                                                                <PortalTooltip
                                                                                    content={
                                                                                        token.name
                                                                                    }
                                                                                    whiteSpace="normal"
                                                                                    wordBreak="break-all"
                                                                                    allowSelect
                                                                                >
                                                                                    <Box
                                                                                        fontSize={
                                                                                            scalePercentText
                                                                                        }
                                                                                        ref={
                                                                                            tokenNameRef
                                                                                        }
                                                                                        textOverflow="ellipsis"
                                                                                        overflow="hidden"
                                                                                        whiteSpace="nowrap"
                                                                                    >
                                                                                        {token.name}
                                                                                    </Box>
                                                                                </PortalTooltip>
                                                                            ) : (
                                                                                <Box
                                                                                    fontSize={
                                                                                        scalePercentText
                                                                                    }
                                                                                    ref={
                                                                                        tokenNameRef
                                                                                    }
                                                                                    textOverflow="ellipsis"
                                                                                    overflow="hidden"
                                                                                    whiteSpace="nowrap"
                                                                                >
                                                                                    {token.name}
                                                                                </Box>
                                                                            )}
                                                                        </Box>
                                                                    </Flex>
                                                                </Skeleton>
                                                            </GridItem>
                                                            <GridItem>
                                                                <Skeleton
                                                                    isLoaded={!token.isInvalid}
                                                                    h="100%"
                                                                    rounded="md"
                                                                >
                                                                    <TokenModalMarketplaceButtons
                                                                        token={token}
                                                                        isDesktop
                                                                        boxSize={scaledPixel(32)}
                                                                    />
                                                                </Skeleton>
                                                            </GridItem>
                                                        </Grid>
                                                    </GridItem>
                                                    <GridItem>
                                                        <Grid
                                                            templateColumns={`auto 65%`}
                                                            // templateRows="auto 1fr"
                                                            templateAreas={`
                                                            "idAndRank prices"
                                                        `}
                                                            gap={scaledPixel(8)}
                                                        >
                                                            <GridItem
                                                                area="idAndRank"
                                                                pt={scaledPixel(4)}
                                                            >
                                                                <VStack gap={0}>
                                                                    <Skeleton
                                                                        isLoaded={!token.isInvalid}
                                                                        h="100%"
                                                                        w={
                                                                            token.isInvalid
                                                                                ? "70%"
                                                                                : "100%"
                                                                        }
                                                                        rounded="md"
                                                                    >
                                                                        <Flex
                                                                            maxW="100%"
                                                                            justify="start"
                                                                            overflow="hidden"
                                                                        >
                                                                            <Box textStyle="tokenModalLandscape.idLabel">
                                                                                <Box
                                                                                    fontSize={
                                                                                        scalePercentText
                                                                                    }
                                                                                >
                                                                                    Token ID&nbsp;
                                                                                </Box>
                                                                            </Box>
                                                                            <Box
                                                                                textStyle="tokenModalLandscape.id"
                                                                                w={scaledPixel(100)}
                                                                            >
                                                                                {isTokenIdOverflown ||
                                                                                token.tokenStringId
                                                                                    .length > 10 ? (
                                                                                    <PortalTooltip
                                                                                        content={
                                                                                            token.tokenStringId
                                                                                        }
                                                                                        whiteSpace="normal"
                                                                                        wordBreak="break-all"
                                                                                        allowSelect
                                                                                    >
                                                                                        <Box
                                                                                            fontSize={
                                                                                                scalePercentText
                                                                                            }
                                                                                            ref={
                                                                                                tokenIdRef
                                                                                            }
                                                                                            textOverflow="ellipsis"
                                                                                            overflow="hidden"
                                                                                            whiteSpace="nowrap"
                                                                                        >
                                                                                            {
                                                                                                token.tokenStringId
                                                                                            }
                                                                                        </Box>
                                                                                    </PortalTooltip>
                                                                                ) : (
                                                                                    <Box
                                                                                        fontSize={
                                                                                            scalePercentText
                                                                                        }
                                                                                        ref={
                                                                                            tokenIdRef
                                                                                        }
                                                                                        textOverflow="ellipsis"
                                                                                        overflow="hidden"
                                                                                        whiteSpace="nowrap"
                                                                                    >
                                                                                        {
                                                                                            token.tokenStringId
                                                                                        }
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        </Flex>
                                                                    </Skeleton>
                                                                    <Skeleton
                                                                        isLoaded={!token.isInvalid}
                                                                        h="100%"
                                                                        w={
                                                                            token.isInvalid
                                                                                ? "90%"
                                                                                : "100%"
                                                                        }
                                                                        rounded="md"
                                                                    >
                                                                        <Flex pr={scaledPixel(20)}>
                                                                            <Box textStyle="tokenModalLandscape.rankLabel">
                                                                                <Box
                                                                                    fontSize={
                                                                                        scalePercentText
                                                                                    }
                                                                                >
                                                                                    Rarity
                                                                                    Rank&nbsp;
                                                                                </Box>
                                                                            </Box>
                                                                            <Box textStyle="tokenModalLandscape.rank">
                                                                                <Box
                                                                                    fontSize={
                                                                                        scalePercentText
                                                                                    }
                                                                                >
                                                                                    #{token.rank}
                                                                                </Box>
                                                                            </Box>
                                                                        </Flex>
                                                                    </Skeleton>
                                                                </VStack>
                                                            </GridItem>
                                                            <GridItem area="prices">
                                                                <TokenPriceInfo
                                                                    token={token}
                                                                    scale={scale}
                                                                />
                                                            </GridItem>
                                                        </Grid>
                                                    </GridItem>
                                                </Grid>
                                            </GridItem>
                                            <GridItem>
                                                <TokenTraitsVibesLandsape
                                                    collection={collection}
                                                    token={token}
                                                    scale={scale}
                                                    rootHeight={imageWidth}
                                                    headerHeight={headerHeight}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                </Grid>
                            )}
                        </Box>
                        <Flex
                            w="100%"
                            pt={scaledPixel(10)}
                            gap={scaledPixel(30)}
                            fontSize={scaledPixel(16)}
                            justify="center"
                            align="center"
                        >
                            <Flex
                                zIndex="15"
                                fontFamily="regular"
                                color="gray.faint"
                                _hover={
                                    prevNextData?.prevToken
                                        ? { cursor: "pointer", color: "gold.500" }
                                        : undefined
                                }
                                opacity={prevNextData?.prevToken ? "1" : ".3"}
                                justifyContent="center"
                                alignItems="center"
                                onClick={handleOnPrev}
                            >
                                <Icon as={FiChevronLeft} mx={scaledPixel(-8)} fontSize="300%" />
                                Prev
                            </Flex>
                            <Flex
                                zIndex="15"
                                fontFamily="regular"
                                color="gray.faint"
                                _hover={
                                    prevNextData?.nextToken
                                        ? { cursor: "pointer", color: "gold.500" }
                                        : undefined
                                }
                                opacity={prevNextData?.nextToken ? "1" : ".3"}
                                justifyContent="center"
                                alignItems="center"
                                onClick={handleOnNext}
                            >
                                Next
                                <Icon as={FiChevronRight} mx={scaledPixel(-8)} fontSize="300%" />
                            </Flex>
                        </Flex>
                        <Flex
                            pos="absolute"
                            top={scaledPixel(-18)}
                            right={scaledPixel(-18)}
                            gap={scaledPixel(10)}
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Flex
                                w={scaledPixel(36)}
                                h={scaledPixel(36)}
                                borderRadius="50%"
                                bgColor="gray.light"
                                color="blue.dark"
                                justifyContent="center"
                                alignItems="center"
                                zIndex="15"
                                _hover={{ bgColor: "gold.500", cursor: "pointer" }}
                                onClick={handleOnClose}
                            >
                                <CloseIcon w={scaledPixel(14)} h={scaledPixel(14)} />
                            </Flex>
                        </Flex>

                        {/* {token.own && (
                            <Flex
                                pos="absolute"
                                top={scaledPixel(-18)}
                                left={scaledPixel(-18)}
                                gap={scaledPixel(10)}
                                direction="column"
                                justify="center"
                                align="center"
                            >
                                <Flex
                                    w={scaledPixel(36)}
                                    h={scaledPixel(36)}
                                    justifyContent="center"
                                    alignItems="center"
                                    zIndex="15"
                                >
                                    <Box
                                        width="32px"
                                        height="32px"
                                        transform="matrix(0.5, 0.71, -0.5, 0.71, 0, 0)"
                                        background="transparent linear-gradient(180deg, var(--white) 0%, #927CFF 100%) 0% 0% no-repeat padding-box"
                                        border="1px solid var(--white)"
                                        background="transparent linear-gradient(180deg, #FFFFFF 0%, #927CFF 100%) 0% 0% no-repeat padding-box"
                                        border="1px solid #FFFFFF"
                                        opacity="1"
                                    />
                                </Flex>
                            </Flex>
                        )} */}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default TokenModalLandscape;

{
    /* <OwnedDiamondImage
position="absolute"
top={scaledPixel(0)}
left={scaledPixel(30)}
w={scaledPixel(30)}
/> */
}
