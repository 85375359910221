import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import debounce from "lodash.debounce";
import { TokenListItem } from "@components/ui";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { setChartActiveToken } from "@store/chartStore";
import TokenListSkeleton from "./TokenListSkeleton";
import useTokenModal from "@hooks/useTokenModal";
import useCollectionStore from "@store/collectionStore";

const MemoTokenListItem = memo(TokenListItem);
const virtuosoListStyle = { width: "100%", height: "100%" };

const TokenListDesktop = (): JSX.Element => {
    const loading = useCollectionTokensStore((state) => state.loading);
    const collection = useCollectionStore((state) => state.collection);
    const tokens: Token[] = useCollectionTokensStore((state) => state.filteredSortedTokens);
    const { showModalToken } = useTokenModal();
    const [isScrolling, setIsScrolling] = useState(false);
    const desktopList = useRef<VirtuosoHandle>(null);

    useEffect(() => {
        desktopList.current?.scrollToIndex(0);
    }, [tokens, tokens.length]);

    const onShowTokenModal = (tokenStringId: TokenStringId) => {
        showModalToken(tokenStringId);
    };

    // eslint-disable-next-line
    const debouncedTokenMouseInteraction = useCallback(
        debounce((data?: ChartActiveToken) => {
            if (data) {
                setChartActiveToken(data);
            } else {
                setChartActiveToken(null);
            }
        }, 100),
        [],
    );

    const onTokenMouseOver = (data) => {
        debouncedTokenMouseInteraction(data);
    };

    const onTokenMouseLeave = () => {
        debouncedTokenMouseInteraction();
    };

    // Returns a skeleton list if token data is still fetching
    if (!tokens || loading) return <TokenListSkeleton isDesktop={true} />;

    return (
        <Box w="100%" h="100%" p="0 10px">
            <Virtuoso
                ref={desktopList}
                style={virtuosoListStyle}
                isScrolling={setIsScrolling}
                increaseViewportBy={{ bottom: 200, top: 1000 }}
                totalCount={tokens.length}
                fixedItemHeight={145}
                itemContent={(index) => (
                    <Box p="10px 5px 0px 0px">
                        <MemoTokenListItem
                            key={tokens[index].tokenStringId}
                            itemIndex={index}
                            token={tokens[index]}
                            collection={collection}
                            triggerTooltip={true}
                            isParentScrolling={isScrolling}
                            onMouseEnter={onTokenMouseOver}
                            onMouseLeave={onTokenMouseLeave}
                            onTokenSelected={onShowTokenModal}
                        />
                    </Box>
                )}
            />
        </Box>
    );
};

export default TokenListDesktop;
