import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import useCollectionStore from "@store/collectionStore";
import { formatToSimpleDuration } from "@utils";
import useInterval from "@hooks/useInterval";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const LastListingsUpdate = (): JSX.Element => {
    const [now, setNow] = useState(() => Date.now());
    const dataLastUpdate = useCollectionStore(state => state.dataLastUpdate);
    const lastUpdateDate = new Date(dataLastUpdate);

    // console.debug("dataLastUpdate: ", dataLastUpdate);

    useInterval(() => setNow(Date.now()), 1000);

    const Refreshing = () => {
        return (
            <>
                <Box>
                    Refreshing
                    <MotionBox
                        overflow="hidden"
                        display="inline-flex"
                        h="100%"
                        width="0"
                        animate={{ width: "1em" }}
                        transition={{ duration: 1, times: "steps(3,end)", repeat: Infinity }}
                    >
                        ...
                    </MotionBox>
                </Box>
            </>
        );
    };

    const LiveIndicator = () => {
        const duration = Math.max(now - lastUpdateDate.getTime(), 0);
        const threshhold = 1000 * 60 * 15; // 15 min

        // if (duration > threshhold && !alreadyShowingLiveUdate) {
        if (duration > threshhold) {
            return <Refreshing />;
        }

        useCollectionStore.setState(() => ({ alreadyShowingLiveUdate: true }));

        return (
            <>
                <Box className="pulse-dot" rounded="50em" w="8px" h="8px" mr="4px" />{" "}
                <Text>LIVE {formatToSimpleDuration(duration)}</Text>
            </>
        );
    };

    return (
        <Flex fontFamily="regular" color="rythm" fontSize="13px" justify="left">
            {!dataLastUpdate ? (
                <Refreshing />
            ) : (
                <Flex h="18px" fontSize="12px" lineHeight="100%" alignItems="center">
                    <LiveIndicator />
                </Flex>
            )}
        </Flex>
    );
};

export default LastListingsUpdate;
