import React, { useState, useRef, useEffect } from "react";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { NumericInput, Button, FilterInputTitle } from "@components/ui";
import useFiltersStore, { resetPriceFilter, setPriceFilter } from "@store/filtersStore";
import useCollectionTokensStore from "@store/collectionTokensStore";

const PriceFilters = ({}: PriceFiltersProps): JSX.Element => {
    const minValueInput = useRef<NumericInputExposedMethods>();
    const maxValueInput = useRef<NumericInputExposedMethods>();
    const currentMinPrice = useFiltersStore((state) => state.minPrice);
    const currentMaxPrice = useFiltersStore((state) => state.maxPrice);
    const highestPrice: number = useCollectionTokensStore((state) => Math.ceil(state.highestPrice));
    const lowestPrice: number = useCollectionTokensStore((state) => Math.floor(state.lowestPrice));
    // TODO: Define types for these 2 values!
    const highest10PctPrices = useCollectionTokensStore((state) => state.highest10PctPrices);
    const lowest10PctPrices = useCollectionTokensStore((state) => state.lowest10PctPrices);

    const [min, setMin] = useState<number>(0);
    const [max, setMax] = useState<number>(0);
    const [applyBtnEnabled, setApplyBtnEnabled] = useState<boolean>(true);

    const resetButtonDisabled = currentMaxPrice === null && currentMinPrice === null;

    useEffect(() => {
        minValueInput.current?.setValue(Math.floor(lowestPrice));
        maxValueInput.current?.setValue(Math.ceil(highestPrice));
        setMin(Math.floor(lowestPrice));
        setMax(Math.ceil(highestPrice));
    }, [lowestPrice, highestPrice]);

    useEffect(() => {
        if (currentMinPrice === min && currentMaxPrice === max) {
            if (applyBtnEnabled) setApplyBtnEnabled(false);
        } else if (!applyBtnEnabled) {
            setApplyBtnEnabled(true);
        }
    }, [currentMinPrice, currentMaxPrice, min, max]);

    const onMinChange = (value: number): void => {
        setMin(value);
    };

    const onMaxChange = (value: number): void => {
        setMax(value);
    };

    const onResetClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        minValueInput.current?.setValue(lowestPrice);
        maxValueInput.current?.setValue(highestPrice);
        resetPriceFilter();
    };

    const onApplyClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        setPriceFilter(min, max);
    };

    const onLowest10Click = (e: React.MouseEvent<HTMLButtonElement>): void => {
        minValueInput.current?.setValue(lowest10PctPrices.min);
        maxValueInput.current?.setValue(lowest10PctPrices.max);
        setPriceFilter(lowest10PctPrices.min, lowest10PctPrices.max /*, "Lowest 10%"*/);
    };

    const onHighest10Click = (e: React.MouseEvent<HTMLButtonElement>): void => {
        minValueInput.current?.setValue(highest10PctPrices.min);
        maxValueInput.current?.setValue(highest10PctPrices.max);
        setPriceFilter(highest10PctPrices.min, highest10PctPrices.max /*, "Highest 10%"*/);
    };

    return (
        <VStack display="inline-flex" gap={3} alignItems="flex-start" w="100%">
            <HStack gap={2} w="100%">
                <Box w="50%">
                    <FilterInputTitle label="Min" showEthIcon />
                    <NumericInput
                        ref={minValueInput}
                        minValue={lowestPrice}
                        maxValue={Math.min(highestPrice, max)}
                        defaultValue={min}
                        onChange={onMinChange}
                    />
                </Box>
                <Box w="50%">
                    <FilterInputTitle label="Max" showEthIcon />
                    <NumericInput
                        ref={maxValueInput}
                        minValue={Math.max(lowestPrice, min)}
                        maxValue={highestPrice}
                        defaultValue={max}
                        onChange={onMaxChange}
                    />
                </Box>
            </HStack>
            <HStack w="100%">
                <Button
                    size="sm"
                    variant="solid"
                    fullWidth
                    disabled={resetButtonDisabled}
                    onClick={onResetClick}
                >
                    Reset
                </Button>
                <Button
                    size="sm"
                    variant="solid"
                    colorScheme="gold"
                    fullWidth
                    disabled={!applyBtnEnabled}
                    onClick={onApplyClick}
                >
                    Apply
                </Button>
            </HStack>
            <Box w="100%" position="relative">
                <FilterInputTitle label="Shortcuts" />
                <HStack alignItems="flex-start" w="100%">
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="dimmed"
                        fullWidth
                        onClick={onLowest10Click}
                    >
                        Lowest 10%
                    </Button>
                    <Button
                        size="sm"
                        variant="solid"
                        colorScheme="dimmed"
                        fullWidth
                        onClick={onHighest10Click}
                    >
                        Highest 10%
                    </Button>
                </HStack>
            </Box>
        </VStack>
    );
};

export default PriceFilters;
