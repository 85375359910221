import React from "react";
import { IconButton, Icon, Link } from "@chakra-ui/react";

const colorSchemes = {
    gold: {
        bg: "rythm",
        color: "steelblue.900",
        _hover: {
            bg: "gold.500",
        },
    },
    sorbet: {
        bg: "rythm",
        color: "steelblue.900",
        _hover: {
            bg: "sorbet",
        },
    },
};

const CircleIconButton = ({
    icon,
    iconColor = "steelblue.900",
    bgColor = "rythm",
    size = "26px",
    href,
    title,
}: CircleIconButtonProps): JSX.Element => {
    return (
        <Link isExternal href={href} title={title} h={size}>
            <IconButton
                icon={<Icon as={icon} width="65%" height="65%" />}
                size="md"
                minW={size}
                maxW={size}
                h={size}
                maxH={size}
                isRound
                verticalAlign="initial"
                {...colorSchemes.gold}
                aria-label="icon"
            />
        </Link>
    );
};

export default CircleIconButton;
