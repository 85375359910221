import { AspectRatio, AspectRatioProps, Box, Flex, Icon, Spinner } from "@chakra-ui/react";
import Image from "next/image";
import { memo, useLayoutEffect, useState } from "react";
import { MdOutlineImageNotSupported, MdPlayCircleOutline } from "react-icons/md";
import ReactPlayer, { ReactPlayerProps } from "react-player";

type TokenModalImagePlayerProps = {
    name: string;
    collectionName: string;
    tokenStringId: TokenStringId;
    imageUrl?: string;
    animationUrl?: string;
    aspectRatioProps?: AspectRatioProps;
    playerOptions?: ReactPlayerProps;
};

const TokenModalImagePlayer = ({
    name,
    collectionName,
    tokenStringId,
    imageUrl,
    animationUrl,
    aspectRatioProps,
    playerOptions,
}: TokenModalImagePlayerProps) => {
    const [imageStatus, setImageStatus] = useState<"loading" | "loaded" | "error">("loading");
    const isVideo = animationUrl && ReactPlayer.canPlay(animationUrl);

    useLayoutEffect(() => {
        if (!imageUrl && !animationUrl) setImageStatus("error");
    }, []);

    const onImageError = () => {
        setImageStatus("error");
    };

    const onImageLoad = () => {
        setImageStatus("loaded");
    };

    const tokenImgAlt = collectionName
        ? `Beyond Rarity - ${collectionName} - Token #${tokenStringId}`
        : `Beyond Rarity - ${name} - Token #${tokenStringId}`;

    const renderImageElement = () => {
        switch (imageStatus) {
            case "loading":
                return (
                    <Box w="32px" h="32px">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="steelblue.500"
                        />
                    </Box>
                );

            case "error":
                return (
                    <Flex layerStyle="tokenModal.imageLoadError" flexDir="column">
                        <Icon
                            as={MdOutlineImageNotSupported}
                            fontSize="100px"
                            color="whiteAlpha.500"
                        />
                        <Box p="10px" color="whiteAlpha.500">
                            No Image Available
                        </Box>
                    </Flex>
                );
        }
    };

    const renderImage = () => {
        return isVideo ? (
            <ReactPlayer
                url={animationUrl}
                playIcon={<MdPlayCircleOutline size="20%" />}
                controls={true}
                playing={true}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playsinline={true}
                {...playerOptions}
            />
        ) : (
            <>
                <Box opacity={imageStatus === "loaded" ? 1 : 0} transition="opacity .3s">
                    {imageUrl && (
                        <Image
                            src={imageUrl}
                            objectFit="contain"
                            layout="fill"
                            alt={tokenImgAlt}
                            onError={onImageError}
                            onLoadingComplete={onImageLoad}
                            unoptimized
                        />
                    )}
                </Box>
                {renderImageElement()}
            </>
        );
    };

    return (
        <AspectRatio w="100%" ratio={1} {...aspectRatioProps} bgColor="blue.darker">
            {renderImage()}
        </AspectRatio>
    );
};

export default memo(TokenModalImagePlayer);
