import React from "react";
import { Flex } from "@chakra-ui/react";
import { BreadcrumbChip } from "@components/ui";

type TraitValuesListProps = {
    traitId: TraitId;
    traitName: string;
    values: Map<TraitValueId, TraitValueFilter>;
    onRemove?: (TraitId, TraitValueId) => void;
};

const TraitValuesList = ({
    traitId,
    traitName,
    values,
    onRemove,
}: TraitValuesListProps): JSX.Element => {
    const onRemoveTraitValue = (traitValueId: number): void => {
        onRemove?.(traitId, traitValueId);
    };
    return (
        <Flex gap="2px" flexWrap="wrap">
            <BreadcrumbChip label={traitName} notRemovable />
            {[...values.values()].map((value) => (
                <BreadcrumbChip
                    key={`${value.label}-${value.id}`}
                    value={value.label}
                    onClick={onRemoveTraitValue}
                    onClickParam={value.id}
                />
            ))}
        </Flex>
    );
};

export default TraitValuesList;
