import { Box, Flex, Grid, GridItem, Icon, IconProps } from "@chakra-ui/react";
import PortalTooltip from "@components/ui/PortalTooltip";
import useSessionStore from "@store/sessionStore";
import { MdDoNotDisturb as NullValueIcon, MdLock } from "react-icons/md";
import "@utils/number.extensions";
import { ETHIcon } from "@components/icons";

type TokenLayoutValueProps = {
    value: number;
    mode?: "fixed" | "free";
    digits?: number;
    requiresAuth?: boolean;
    showPositiveSign?: boolean;
    // noDecimalTruncation?: boolean;
    showOutOfRangeTooltip?: boolean;
    // useElipsesForSmallDecimals?: boolean;
    splitSignValue?: boolean;
    useTooltipPortal?: boolean;
    showEthIcon?: boolean;
    showTooltipEthIcon?: boolean;
    ethIconProps?: IconProps;
};

export const TokenLayoutValue = ({
    value,
    mode = "fixed",
    digits = 3,
    requiresAuth = true,
    showPositiveSign = false,
    // noDecimalTruncation = false,
    showOutOfRangeTooltip = false,
    // useElipsesForSmallDecimals = false,
    splitSignValue = false,
    useTooltipPortal = false,
    showEthIcon = false,
    showTooltipEthIcon = true,
    ethIconProps,
}: TokenLayoutValueProps) => {
    const isConnected = useSessionStore((state) => state.connected);

    const maxRange = 10 ** digits - 1;

    const isInRange = false;
    /*    
    value === 0 ||
    (Math.abs(value) <= maxRange && Math.abs(value) >= 1) ||
    (Math.abs(value) > 0 && Math.abs(value) < 1 && Math.abs(value).countDecimals() < 4);
    */

    const stopPropagation = (e: React.MouseEvent<any>): void => {
        e.stopPropagation();
    };

    const InRangeLayout = () => {
        if (mode === "free") {
            return (
                <>
                    <Flex justifyContent="center" alignItems="center">
                        <Box display={showEthIcon ? "block" : "none"}>
                            <ETHIcon maxH="100%" {...ethIconProps} />
                        </Box>
                        <Box>{value < 0 ? "-" : value > 0 && showPositiveSign ? "+" : ""}</Box>
                        <Box flex={splitSignValue ? 1 : undefined}>{Math.abs(value)}</Box>
                    </Flex>
                </>
            );
        }

        return (
            <>
                <Grid w="100%" templateColumns="1fr auto 1fr">
                    <GridItem textAlign="right">
                        <Flex w="100%">
                            <Flex display={showEthIcon ? "flex" : "none"}>
                                <ETHIcon maxH="100%" {...ethIconProps} />
                            </Flex>
                            <Flex flex={1} justifyContent="end">
                                <Box>
                                    {value < 0 ? "-" : value > 0 && showPositiveSign ? "+" : ""}
                                </Box>
                                <Box flex={splitSignValue ? 1 : undefined}>
                                    {value > 0
                                        ? Math.abs(Math.floor(value)).toFixed(0)
                                        : Math.abs(Math.ceil(value)).toFixed(0)}
                                </Box>
                            </Flex>
                        </Flex>
                    </GridItem>
                    <GridItem textAlign="center">.</GridItem>
                    <GridItem textAlign="left">
                        {Math.abs(value % 1)
                            .toFixed(digits)
                            .replace("0.", "")}
                    </GridItem>
                </Grid>
            </>
        );
    };

    const OutOfRangeLayout = ({ onClick }: { onClick?: Function }) => {
        const onValueClick = () => {
            onClick?.();
        };

        if (mode == "free" || Math.abs(value) > maxRange) {
            return (
                <Flex justifyContent="center" alignItems="center" onClick={onValueClick}>
                    <Box display={showEthIcon ? "block" : "none"}>
                        <ETHIcon maxH="100%" {...ethIconProps} />
                    </Box>
                    <Box>{value < 0 ? "-" : value > 0 && showPositiveSign ? "+" : ""}</Box>
                    <Box flex={splitSignValue ? 1 : undefined}>
                        {Math.abs(value) > maxRange
                            ? Math.abs(value).abbreviate(3,3,3)
                            : Math.abs(value).toFixed(digits)}
                    </Box>
                </Flex>
            );
        }

        return (
            <>
                <Grid w="100%" templateColumns="1fr auto 1fr">
                    <GridItem textAlign="right">
                        <Flex w="100%">
                            <Flex display={showEthIcon ? "flex" : "none"}>
                                <ETHIcon maxH="100%" {...ethIconProps} />
                            </Flex>
                            <Flex flex={1} justifyContent="end">
                                <Box>
                                    {value < 0 ? "-" : value > 0 && showPositiveSign ? "+" : ""}
                                </Box>
                                <Box flex={splitSignValue ? 1 : undefined}>
                                    {value > 0
                                        ? Math.abs(Math.floor(value)).toFixed(0)
                                        : Math.abs(Math.ceil(value)).toFixed(0)}
                                </Box>
                            </Flex>
                        </Flex>
                    </GridItem>
                    <GridItem textAlign="center">.</GridItem>
                    <GridItem textAlign="left">
                        {Math.abs(value % 1)
                            .toFixed(digits)
                            .replace("0.", "")}
                    </GridItem>
                </Grid>
            </>
        );
    };

    const OutOfRangeLayoutWithtooltip = () => {
        const tooltipContent = (
            <Flex w="100%" justify="center" align="center" fontSize="14px">
                <Box display={showTooltipEthIcon ? "flex" : "none"}>
                    <ETHIcon maxH="100%" fontSize="13px" />
                </Box>
                {value.toLocaleString("en-us", {
                    minimumFractionDigits: Math.min(value.countDecimals(), 20),
                })}
                <br />
            </Flex>
        );

        return (
            <Box position="relative" onClick={stopPropagation}>
                <PortalTooltip content={tooltipContent}>
                    <OutOfRangeLayout />
                </PortalTooltip>
            </Box>
        );
    };

    if (requiresAuth && !isConnected) return <Icon as={MdLock} />;

    if (value == null) return <Icon as={NullValueIcon} />;

    return (
        <>
            {isInRange ? (
                <InRangeLayout />
            ) : showOutOfRangeTooltip ? (
                <OutOfRangeLayoutWithtooltip />
            ) : (
                <OutOfRangeLayout />
            )}
        </>
    );
};

export default TokenLayoutValue;
