import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { IconType } from "react-icons";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Input as ChakraInput, Button, Box, Icon, Flex } from "@chakra-ui/react";
import { isNumber, roundNumber } from "@utils/helpers";

const ArrowButtonAddon = ({ variant, onClick, disabled }: ArrowButtonAddonProps): JSX.Element => {
    const borderTopRadius: string = variant === "up" ? "3px" : "0";
    const borderBottomRadius: string = variant === "up" ? "0" : "3px";
    const verticalPosition = variant === "up" ? { top: "3px" } : { bottom: "3px" };
    const icon: IconType = variant === "up" ? IoMdArrowDropup : IoMdArrowDropdown;

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick?.();
    };

    return (
        <Button
            size="xs"
            variant="solid"
            colorScheme="gold"
            disabled={disabled}
            borderTopRadius={borderTopRadius}
            borderBottomRadius={borderBottomRadius}
            position="absolute"
            overflow="hidden"
            {...verticalPosition}
            right="3px"
            w="24px"
            zIndex="2"
            p="0"
            onClick={onClickHandler}
        >
            <Icon as={icon} color="steelblue.900" height="26px" width="18px" />
        </Button>
    );
};

const NumericInput = forwardRef<NumericInputExposedMethods, NumericInputProps>(
    (
        {
            placeholder,
            defaultValue,
            minValue,
            maxValue,
            onChange,
            onError,
            disabled,
        }: NumericInputProps,
        ref,
    ): JSX.Element => {
        useImperativeHandle(ref, () => ({
            setValue: (value: number): void => {
                setFieldValue(value);
            },
        }));

        const inputContainer = useRef<HTMLDivElement>();
        const input = useRef<HTMLInputElement>();
        const [value, setValue] = useState<number | undefined>(
            isNaN(defaultValue) ? undefined : defaultValue,
        );

        const setFieldValue = (value: number | undefined): void => {
            if (isNaN(value) || !input.current) return;
            input.current.value = roundNumber(value, 3).toString();
            setValue(value);
        };

        const onIncrease = (): void => {
            if (value !== 0 && !value) {
                setFieldValue(0);
                return;
            }

            if (!isNaN(maxValue) && value < maxValue) setFieldValue(value + 1);
        };

        const onDecrease = (): void => {
            if (value !== 0 && !value) {
                setFieldValue(0);
                return;
            }

            if (!isNaN(minValue) && value > minValue) setFieldValue(value - 1);
        };

        const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = e.target;

            if (value === "" || !isNumber(value)) {
                onError?.();
                // setValue(0);
            } else {
                setValue(Number(value));
            }
        };

        const onInputFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
            inputContainer.current.style.outlineColor = "#bb9b48";
        };

        const onInputBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
            inputContainer.current.style.outlineColor = "rgba(0,0,0,0)";
            if (e.target.value === "" || !isNumber(e.target.value)) {
                // setValue(0);
            } else {
                setValue(Number(e.target.value));
            }
        };

        useEffect(() => {
            if (value === 0 || !!value) onChange?.(value);
        }, [value]);

        return (
            <Flex
                ref={inputContainer}
                position="relative"
                bg="#3B4153"
                rounded="4px"
                outline="1px solid"
                outlineColor="rgba(0,0,0,0)"
                transition="outline-color .2s"
                boxShadow="inset 0px 1px 3px rgba(0, 0, 0, 0.5), inset 0px -12px 8px rgba(0, 0, 0, 0.115931), inset 0px -1px 0px rgba(255, 255, 255, 0.143357)"
            >
                <ChakraInput
                    width="calc(100% - 25px)"
                    ref={input}
                    defaultValue={defaultValue}
                    type="string"
                    min={minValue}
                    max={maxValue}
                    bg="transparent"
                    rounded="4px"
                    border="none"
                    outline="none"
                    fontFamily="regular"
                    // fontSize="8px"
                    fontWeight={400}
                    placeholder={placeholder}
                    onFocus={onInputFocus}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    disabled={disabled}
                    _focus={{ boxShadow: "none" }}
                />

                <ArrowButtonAddon
                    variant="up"
                    disabled={(!isNaN(maxValue) && maxValue === value) || disabled}
                    onClick={onIncrease}
                />
                <ArrowButtonAddon
                    variant="down"
                    disabled={(!isNaN(minValue) && minValue === value) || disabled}
                    onClick={onDecrease}
                />
            </Flex>
        );
    },
);

NumericInput.displayName = "NumericInput";

export default NumericInput;
