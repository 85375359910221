import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";

const CheckBox = ({ checked, onClick }): JSX.Element => {
    return (
        <Box
            layerStyle="filters.traits.value.checkbox"
            backgroundColor={checked ? "gold.500" : "none"}
            onClick={onClick}
        >
            {checked && <FaCheck size="9px" />}
        </Box>
    );
};

const TraitFilterCheckbox = ({
    traitValueId,
    label,
    amount,
    isChecked,
    onChecked,
    onUnchecked,
}: TraitFilterCheckboxProps): JSX.Element => {
    const [checked, setChecked] = useState<boolean>(isChecked);

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    const onCheckboxChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!checked) {
            onChecked?.(Number(traitValueId));
        } else {
            onUnchecked?.(Number(traitValueId));
        }
    };

    return (
        <Flex layerStyle="filters.traits.value.item" fontFamily="regular" title={label}>
            <CheckBox checked={isChecked} onClick={onCheckboxChange} />
            <Box flex="1 0" textStyle="filters.traits.value.label" onClick={onCheckboxChange}>
                {label}
            </Box>
            <Text textStyle="filters.traits.value.amount">{amount}</Text>
        </Flex>
    );
};

export default TraitFilterCheckbox;
