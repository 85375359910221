import { useEffect, useRef, useState } from "react";
import { Box, Grid, GridItem, Text, Icon, Flex, Spacer, HStack } from "@chakra-ui/react";
import { FaEthereum } from "react-icons/fa";
import { useSessionStore } from "@store/sessionStore";
import { ClickHoverIconButton } from "@components/ui";
import TokenLayoutValue from "@components/tokenCommon/TokenLayoutValue";
import TokenHotColdBadge from "@components/tokenCommon/TokenHotColdBadge";
import TokenHotColdVerticalBand from "@components/tokenCommon/TokenHotColdVerticalBand";
import TokenImagePlayer from "@components/tokenCommon/TokenImagePlayer";
import PortalTooltip from "@components/ui/PortalTooltip";
import OwnedDiamondImage from "@components/images/OwnedDiamondImage";

const EthIcon = () => <Icon as={FaEthereum} marginTop="-1px" />;

const TokenListItem = ({
    token,
    itemIndex,
    collection,
    triggerTooltip = false,
    onMouseEnter,
    onMouseLeave,
    onTokenSelected,
}: TokenListItemProps): JSX.Element => {
    const isConnected = useSessionStore((state) => state.connected);

    const [briteInfoIsOpen, setBriteInfoOpen] = useState(false);
    const [deviationInfoIsOpen, setDeviationInfoOpen] = useState(false);

    const tokenNameRef = useRef(null);
    const [istokenNameOverflown, settokenNameOverflown] = useState(false);

    const tokenIdRef = useRef(null);
    const [isTokenIdOverflown, setTokenIdOverflown] = useState(false);

    useEffect(() => {
        const element = tokenNameRef?.current;
        settokenNameOverflown(element?.scrollWidth > element?.clientWidth);
    }, [tokenNameRef, token?.name]);

    useEffect(() => {
        const element = tokenIdRef?.current;
        setTokenIdOverflown(element?.scrollWidth > element?.clientWidth);
    }, [tokenIdRef, token?.tokenId]);

    const handleTokenEnter = () => {
        if (triggerTooltip) {
            onMouseEnter({
                tokenStringId: token.tokenStringId,
                rank: token.rank,
                currentPrice: token.currentPrice,
                activationType: "selected",
            });
        }
    };

    const handleTokenLeave = () => {
        if (triggerTooltip) {
            onMouseLeave();
        }
    };

    const handleTokenClick = () => {
        onTokenSelected(token.tokenStringId, itemIndex);
    };

    const getTooltipContent = (value: string) => (
        <Box maxW="400px" textAlign="center" lineHeight="120%">
            {value}
        </Box>
    );

    const TokenItemHeader = () => {
        return (
            <>
                <Grid
                    templateColumns="auto auto"
                    templateRows="auto auto 1fr"
                    templateAreas={`
                        "tokenIdLabel rankLabel"
                        "tokenId rank"
                        "name name"
                    `}
                >
                    <GridItem area="tokenIdLabel" pb="2px" textStyle="tokenListItem.label">
                        <HStack textAlign="center" spacing="10px">
                            <p>Token ID</p>
                        </HStack>
                    </GridItem>
                    <GridItem
                        area="rankLabel"
                        textAlign="right"
                        pb="2px"
                        textStyle="tokenListItem.label"
                    >
                        Rarity Rank
                    </GridItem>
                    <GridItem area="tokenId" textStyle="tokenListItem.id">
                        <Box w="180px">
                            {isTokenIdOverflown ? (
                                <PortalTooltip
                                    content={getTooltipContent(token.tokenStringId)}
                                    whiteSpace="normal"
                                    allowSelect
                                >
                                    <Box
                                        ref={tokenIdRef}
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                        lineHeight="100%"
                                    >
                                        {token.tokenStringId}
                                    </Box>
                                </PortalTooltip>
                            ) : (
                                <Box
                                    ref={tokenIdRef}
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    lineHeight="100%"
                                >
                                    {token.tokenStringId}
                                </Box>
                            )}
                        </Box>
                    </GridItem>
                    <GridItem area="rank" textAlign="right" textStyle="tokenListItem.rank">
                        {token.rank}
                    </GridItem>
                    <GridItem area="name" paddingTop="4px" textStyle="tokenListItem.name">
                        <Box w="260px">
                            {istokenNameOverflown ? (
                                <PortalTooltip
                                    content={getTooltipContent(token.name)}
                                    whiteSpace="normal"
                                    allowSelect
                                >
                                    <Box
                                        ref={tokenNameRef}
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                        lineHeight="100%"
                                        onClick={handleTokenClick}
                                    >
                                        {token.name}
                                    </Box>
                                </PortalTooltip>
                            ) : (
                                <Box
                                    ref={tokenNameRef}
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    lineHeight="100%"
                                    onClick={handleTokenClick}
                                >
                                    {token.name}
                                </Box>
                            )}
                        </Box>
                    </GridItem>
                </Grid>
            </>
        );
    };

    const DeviationInfoHeader = () => {
        return (
            <>
                <Flex direction="column" h="100%" w="100%" justifyContent="center">
                    <Box textStyle="tokenListItem.deviationInfo.header">
                        What&apos;s the Deviation?
                    </Box>
                    <Box textStyle="tokenListItem.deviationInfo.subheader">
                        The difference between the List &amp; <b>BR</b>ITE Prices.
                    </Box>
                    <Flex textStyle="tokenListItem.deviationInfo.body">
                        Bigger deviations are farther from the&nbsp;<b>BR</b>ITE Price.
                    </Flex>
                </Flex>
            </>
        );
    };

    const BriteInfoHeader = () => {
        return (
            <>
                <Flex direction="column" h="100%" w="100%" justifyContent="center">
                    <Box textStyle="tokenListItem.briteInfo.header">
                        What&apos;s the <b>BR</b>ITE Price?
                    </Box>
                    <Box textStyle="tokenListItem.briteInfo.subheader">
                        Beyond Rarity&apos;s Intelligent Trusted Estimate
                    </Box>
                </Flex>
            </>
        );
    };

    const BriteInfoDescription = () => {
        if (!briteInfoIsOpen) return null;

        return (
            <>
                <GridItem
                    area="briteInfo"
                    display={briteInfoIsOpen ? undefined : "none"}
                    pl="16px"
                    textStyle="tokenListItem.briteInfo.body"
                >
                    <Flex h="100%" w="100%" mt="-2px">
                        <Box>
                            Our algorithm integrates sales history and other complex factors to
                            create the most accurate price projection and help you make your best
                            decisions.
                        </Box>
                    </Flex>
                </GridItem>
            </>
        );
    };

    return (
        <Box position="relative" _hover={{ cursor: "pointer" }} onClick={handleTokenClick}>
            <Box
                borderRadius="12px"
                border="2px solid transparent"
                transition="border-color .12s"
                _hover={{ borderColor: "gold.500" }}
                overflow="hidden"
            >
                <Grid
                    templateColumns="145px auto"
                    bgColor="gray.tokenListItem"
                    borderRadius="10px"
                    overflow="hidden"
                    onMouseEnter={handleTokenEnter}
                    onMouseLeave={handleTokenLeave}
                    onBlur={handleTokenLeave}
                >
                    <GridItem>
                        <TokenImagePlayer
                            name={token.name}
                            collectionName={collection.name}
                            tokenStringId={token.tokenStringId}
                            imageUrl={token.imageUrl?.replace(/(\?.*)(w=.+)(&.+)/gm, "$1w=150$3")}
                            animationUrl={token.animationUrl}
                        />
                        {token?.own && (
                            <Flex
                                position="absolute"
                                direction="row"
                                bgColor="raisinBlackAlpha.70"
                                borderRadius="4px"
                                p="4px"
                                top="8px"
                                left="8px"
                                justify="center"
                                align="center"
                                gap="3px"
                            >
                                <OwnedDiamondImage w="8px" />
                                <Box fontSize="10px" lineHeight="100%" mt=".2em">
                                    Owned
                                </Box>
                            </Flex>
                        )}
                    </GridItem>
                    <GridItem p="8px">
                        <Flex direction="column" h="100%">
                            <Box paddingLeft="10px" flex={briteInfoIsOpen ? undefined : 1}>
                                {briteInfoIsOpen ? (
                                    <BriteInfoHeader />
                                ) : deviationInfoIsOpen ? (
                                    <DeviationInfoHeader />
                                ) : (
                                    <TokenItemHeader />
                                )}
                            </Box>
                            {briteInfoIsOpen || deviationInfoIsOpen ? null : <Spacer />}
                            <Box
                                flex={briteInfoIsOpen ? 1 : undefined}
                                pt={briteInfoIsOpen ? "8px" : undefined}
                            >
                                <Grid
                                    h="100%"
                                    templateColumns={
                                        deviationInfoIsOpen
                                            ? "1fr auto 1fr auto 1fr"
                                            : "1fr 1fr 1fr"
                                    }
                                    templateRows={
                                        briteInfoIsOpen ? "1fr auto auto" : "auto auto auto"
                                    }
                                    templateAreas={
                                        briteInfoIsOpen
                                            ? `
                                            "briteInfo briteInfo confidence"
                                            "briteInfo briteInfo projection"
                                            "briteInfo briteInfo projectionLabel"
                                            `
                                            : deviationInfoIsOpen
                                            ? `
                                                "deviationInfo deviationInfo deviationInfo deviationInfo deviationInfo"
                                                "price add deviation equal projection"
                                                "priceLabel . deviationLabel . projectionLabel"
                                                `
                                            : `
                                                "confidence confidence confidence"
                                                "price deviation projection"
                                                "priceLabel deviationLabel projectionLabel"
                                            `
                                    }
                                    columnGap="4px"
                                >
                                    <GridItem
                                        area="confidence"
                                        textAlign="right"
                                        textStyle="tokenListItem.confidence"
                                        pb="2px"
                                        display={deviationInfoIsOpen ? "none" : undefined}
                                    >
                                        <Flex h="100%" justifyContent="end" alignItems="end">
                                            {isConnected ? (
                                                <>
                                                    <Box>Confidence&nbsp;</Box>
                                                    <Text as="span" color="white">
                                                        {token.confidence?.toFixed(0)}%
                                                    </Text>
                                                </>
                                            ) : null}
                                        </Flex>
                                    </GridItem>
                                    <GridItem
                                        area="price"
                                        display={briteInfoIsOpen ? "none" : undefined}
                                        borderRadius="6px"
                                        textAlign="center"
                                        bgColor="blue.dark"
                                        p="8px"
                                        textStyle="tokenListItem.price"
                                    >
                                        <TokenLayoutValue
                                            value={token.currentPrice}
                                            requiresAuth={false}
                                            showOutOfRangeTooltip
                                        />
                                    </GridItem>
                                    <GridItem
                                        area="add"
                                        display={deviationInfoIsOpen ? undefined : "none"}
                                    >
                                        <Flex h="100%" justifyContent="center" alignItems="center">
                                            +
                                        </Flex>
                                    </GridItem>
                                    <GridItem
                                        area="deviation"
                                        display={briteInfoIsOpen ? "none" : undefined}
                                        borderRadius="6px"
                                        textAlign="center"
                                        bgColor="blue.med"
                                        p="8px"
                                        textStyle="tokenListItem.deviation"
                                    >
                                        <TokenLayoutValue
                                            value={token.deviation}
                                            showPositiveSign
                                            showOutOfRangeTooltip
                                        />
                                    </GridItem>
                                    <GridItem
                                        area="equal"
                                        display={deviationInfoIsOpen ? undefined : "none"}
                                    >
                                        <Flex h="100%" justifyContent="center" alignItems="center">
                                            =
                                        </Flex>
                                    </GridItem>
                                    <GridItem
                                        area="projection"
                                        borderRadius="6px"
                                        textAlign="center"
                                        bgColor="blue.dark"
                                        p="8px"
                                        textStyle="tokenListItem.projection"
                                    >
                                        <TokenLayoutValue
                                            value={token.priceProjection}
                                            showOutOfRangeTooltip
                                        />
                                    </GridItem>
                                    <GridItem
                                        area="priceLabel"
                                        display={briteInfoIsOpen ? "none" : undefined}
                                        pt="3px"
                                    >
                                        <Flex
                                            w="100%"
                                            h="100%"
                                            textStyle="tokenListItem.label"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {deviationInfoIsOpen ? null : (
                                                <>
                                                    <EthIcon /> List Price
                                                </>
                                            )}
                                        </Flex>
                                    </GridItem>
                                    <GridItem
                                        area="deviationLabel"
                                        display={briteInfoIsOpen ? "none" : undefined}
                                        pt="3px"
                                    >
                                        <Flex
                                            w="100%"
                                            h="100%"
                                            textStyle="tokenListItem.label"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <EthIcon /> Deviation{" "}
                                            <ClickHoverIconButton
                                                mode="click"
                                                onOpen={() => setDeviationInfoOpen(true)}
                                                onClose={() => setDeviationInfoOpen(false)}
                                                iconProps={{ boxSize: "16px" }}
                                                pl="2px"
                                            />
                                        </Flex>
                                    </GridItem>
                                    <GridItem area="projectionLabel" pt="3px">
                                        <Flex
                                            w="100%"
                                            h="100%"
                                            textStyle="tokenListItem.label"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {deviationInfoIsOpen ? null : (
                                                <>
                                                    <EthIcon />
                                                    <b>BR</b>ITE Price{" "}
                                                    <ClickHoverIconButton
                                                        mode="click"
                                                        onOpen={() => setBriteInfoOpen(true)}
                                                        onClose={() => setBriteInfoOpen(false)}
                                                        iconProps={{ boxSize: "16px" }}
                                                        pl="2px"
                                                    />
                                                </>
                                            )}
                                        </Flex>
                                    </GridItem>
                                    <BriteInfoDescription />
                                </Grid>
                            </Box>
                        </Flex>
                    </GridItem>
                </Grid>
                {isConnected ? (
                    <>
                        <TokenHotColdVerticalBand
                            show={token.currentPrice != null && isConnected}
                            isHot={token.deviation > 0}
                            top="0px"
                            left="147px"
                        />
                        <TokenHotColdBadge
                            show={token.currentPrice != null && isConnected}
                            isHot={token.deviation > 0}
                            width="26px"
                            height="26px"
                            top="-8px"
                            left="137px"
                            padding="3px"
                        />
                    </>
                ) : null}
            </Box>
        </Box>
    );
};

export default TokenListItem;
