import React, { useEffect, useState } from "react";
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Text,
    HStack,
} from "@chakra-ui/react";
import { BreadcrumbsList, SkeletonBox } from "@components/ui";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { hasFiltersApplied, getFiltersCount } from "@store/filtersStore";
import LastListingsUpdate from "./LastListingsUpdate";

// TODO: Move the formatter to an external helper to avoid creating multiple instances
// native JS number formatter
const numberFormatter = new Intl.NumberFormat();

const MobileBreadcrumbs = ({ loading = false, defaultIsOpen = true, ...props }): JSX.Element => {
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });
    const [isOpen, setIsOpen] = useState<boolean>(() => defaultIsOpen && hasFilters);

    const hasFilters = hasFiltersApplied();
    const filtersCount = getFiltersCount();
    const filteredSortedTokens: Token[] = useCollectionTokensStore(
        (state) => state.filteredSortedTokens,
    );

    useEffect(() => {
        if (hasFilters) {
            if (!isOpen) setIsOpen(true);
        } else if (isOpen) setIsOpen(false);
    }, [hasFilters]);

    const onAccordionChange = (expandedIndex): void => {
        setIsOpen(expandedIndex === 0);
    };

    const accordionButtonLabel = `${filtersCount} Filters`;

    if (!loading)
        return (
            <Box w="100%" bg="blue.darker" px={4} pt={3} pb={1} mb={4} {...props}>
                <Accordion
                    index={isOpen ? 0 : null}
                    allowToggle
                    reduceMotion={!isDesktop}
                    onChange={onAccordionChange}
                    w="100%"
                >
                    <AccordionItem border="none" mb={2}>
                        <AccordionButton _focus={{ ring: "none" }} w="100%" p="0">
                            <Box flex="1 0 auto" flexDir="row" textAlign="left" fontSize="14px">
                                <HStack>
                                    <Text textStyle="itemsAmount">
                                        {numberFormatter.format(filteredSortedTokens.length)} items
                                    </Text>
                                    <LastListingsUpdate />
                                </HStack>
                            </Box>
                            <HStack gap="0">
                                <Text
                                    textStyle="itemsAmount"
                                    color={filtersCount ? "#ccc" : "gray"}
                                >
                                    {accordionButtonLabel}
                                </Text>
                                <AccordionIcon color={filtersCount ? "#ccc" : "gray"} />
                            </HStack>
                        </AccordionButton>
                        <AccordionPanel pb={1} px={0}>
                            <BreadcrumbsList />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        );

    // Skeleton
    return <SkeletonBox h="44px" borderRadius="0" mb={4} />;
};

export default MobileBreadcrumbs;
