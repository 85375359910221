import React from "react";
import { Flex, Text, IconButton, Icon } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import { FaEthereum } from "react-icons/fa";

type BreadcrumbChipProps = {
    label?: string;
    value?: string | number;
    showEthIcon?: boolean;
    notRemovable?: boolean;
    onClick?: Function;
    onClickParam?: any;
    maxWidth?: number;
};

const RemoveButton = ({ onClick }: { onClick?: () => void }): JSX.Element => {
    const size = "14px";

    return (
        <IconButton
            icon={<Icon as={RiCloseLine} width="85%" height="85%" />}
            minW={size}
            maxW={size}
            h={size}
            maxH={size}
            isRound
            bg="blue.dark"
            aria-label="remove"
            onClick={onClick}
            color="rgba(255,255,255,.25)"
            _hover={{ color: "gold.500" }}
            _active={{ bg: "gold.500", color: "blue.dark" }}
        />
    );
};

const BreadcrumbChip = ({
    label,
    value,
    showEthIcon,
    notRemovable,
    onClick,
    onClickParam,
    maxWidth,
}: BreadcrumbChipProps): JSX.Element => {
    const onRemoveFilter = () => {
        onClick?.(onClickParam);
    };

    const chipMaxWidth = maxWidth ? `${maxWidth}px` : "140px";

    return (
        <Flex
            direction="row"
            layerStyle="breadcrumbs.chip"
            title={String(value) || label || ""}
            maxW={chipMaxWidth}
        >
            {label && <Text textStyle="chipLabel">{label}:</Text>}
            {showEthIcon && <Icon as={FaEthereum} height="11px" verticalAlign="text-top" />}
            {value && <Text textStyle="chip">{value}</Text>}
            {!notRemovable && <RemoveButton onClick={onRemoveFilter} />}
        </Flex>
    );
};

export default BreadcrumbChip;
