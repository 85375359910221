import React, { FC, ReactElement } from "react";
import { SimpleGrid, Box } from "@chakra-ui/react";

const ToggleButton: FC<ToggleButtonProps> = ({
    optionA,
    optionB,
    value,
    onChange,
    fullWidth,
    height = "20px",
}): ReactElement => {
    const togglePosition = value === optionA ? { left: "2px" } : { left: "calc(50% + 2px)" };
    const optionAColor = value === optionB ? "rgba(255,255,255,.5)" : "steelblue.900";
    const optionBColor = value === optionA ? "rgba(255,255,255,.5)" : "steelblue.900";

    const onClick = (): void => {
        const newSelectedOption = value === optionA ? optionB : optionA;
        onChange?.(newSelectedOption);
    };

    return (
        <Box
            position="relative"
            bg="steelblue.700"
            rounded="15px"
            h={height}
            w={fullWidth ? "100%" : "auto"}
            display={fullWidth ? "block" : "inline-flex"}
            fontSize="12px"
            fontFamily="regular"
            lineHeight={height}
            cursor="pointer"
            boxShadow="inset 0px 1px 2px rgba(0, 0, 0, 0.5), inset 0px -10px 7px rgba(0, 0, 0, 0.115931), inset 0px -1px 0px rgba(255, 255, 255, 0.143357)"
            onClick={onClick}
        >
            <Box
                position="absolute"
                top="2px"
                {...togglePosition}
                rounded="50rem"
                width="calc(50% - 4px)"
                height="calc(100% - 4px)"
                bg="gold.500"
                transition="all .2s"
                boxShadow="0px 1px 4px rgba(0, 0, 0, 0.5), inset 0px -1px 0px rgba(226, 226, 226, 0.439303), inset 0px 0px 8px #FFB706"
            ></Box>
            <SimpleGrid
                columns={2}
                spacing={0}
                justifyContent="center"
                textAlign="center"
                zIndex={2}
                userSelect="none"
                fontWeight={400}
            >
                <Box px="12px" zIndex="4" color={optionAColor} transition="color .2s">
                    {optionA}
                </Box>
                <Box px="12px" zIndex="4" color={optionBColor} transition="color .2s">
                    {optionB}
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default ToggleButton;
