import { Image, ImageProps } from '@chakra-ui/react';
import { encodeSvg } from '@utils';
import chakraTheme from '@theme';

type OwnedDiamondImageProps = {
    borderColor?: string;
    strokeWidth?: number;
    startFillColor?: string;
    endFillColor?: string;
};

export const OwnedDiamondImage = ({
    borderColor = chakraTheme.colors.chart.datapoint.owned.pointBorder,
    strokeWidth = 1,
    startFillColor = chakraTheme.colors.chart.datapoint.owned.from,
    endFillColor = chakraTheme.colors.chart.datapoint.owned.to,
    ...props
}: OwnedDiamondImageProps & ImageProps) => {
    const halfStrokeWidth = strokeWidth / 2;

    const svg = `
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='${-halfStrokeWidth} ${-halfStrokeWidth} ${11.400002 + strokeWidth} ${15.200002 + strokeWidth}'>
        <defs id='defs10'>
            <linearGradient id='gradient1'
                gradientUnits='userSpaceOnUse'
                x1='2.8518367'
                y1='11.326122'
                x2='8.5689793'
                y2='4.290204'
            >
                <stop style='stop-color:${endFillColor};stop-opacity:1' offset='0' id='stop837' />
                <stop style='stop-color:${startFillColor};stop-opacity:1' offset='1' id='stop839' />
            </linearGradient>
        </defs>
        <g
            id='g5'
            style='fill:url(#gradient1);fill-opacity:1;stroke:${borderColor};stroke-width:${strokeWidth};stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1'>
            <path
                id='Rectangle1'
                d='M 5.7,0 11.4,7.6 5.7,15.2 0,7.6 Z'
                style='fill:url(#gradient1);fill-opacity:1;stroke:${borderColor};stroke-width:${strokeWidth};stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1' />
        </g>
        </svg>`;

    return (
        <>
            <Image
                src={`data:image/svg+xml;utf8,${encodeSvg(svg)}`}
                layerStyle='fill'
                alt='Beyond Rarity - Diamond'
                {...props}
            />
        </>
    );
};

export default OwnedDiamondImage;
