import { Box, Flex, Grid, GridItem, Image, Skeleton, Text } from "@chakra-ui/react";
import { SkeletonBox } from "@components/ui";
import TokenHotColdBadge from "@components/tokenCommon/TokenHotColdBadge";
import TokenLayoutValue from "@components/tokenCommon/TokenLayoutValue";
import { ClickHoverIconButton } from "@components/ui";
import useSessionStore from "@store/sessionStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import TokenPriceInfoDeviationTooltip from "./TokenPriceInfoDeviationTooltip";
import TokenPriceInfoBriteTooltip from "./TokenPriceInfoBriteTooltip";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";

type TokenPriceInfoProps = {
    token: Token;
    scale?: number;
    tooltipPosition?: "top" | "bottom";
};

export const TokenPriceInfo = ({
    token,
    scale = 1,
    tooltipPosition = "bottom",
}: TokenPriceInfoProps) => {
    if (token.tokenId == 300) console.debug("token: ", token);
    const isConnected = useSessionStore((state) => state.connected);
    const isListed = token?.currentPrice != null;
    const isHotOrCold = !isListed || !isConnected ? "" : token.deviation > 0 ? "hot" : "cold";
    const isAboveOrBelow = !isListed || !isConnected ? "" : token.deviation > 0 ? "Under" : "Over";

    const [briteInfoIsOpen, setBriteInfoOpen] = useState(false);
    const [deviationInfoIsOpen, setDeviationInfoOpen] = useState(false);

    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    const TokenPriceInfoSkeleton = () => {
        return (
            <>
                <Flex w="100%" h="100%">
                    <Grid
                        w="100%"
                        h="100%"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={scaledPixel(8)}
                    >
                        <GridItem>
                            <Skeleton w="100%" h="100%" rounded="md" />
                        </GridItem>
                        <GridItem>
                            <Skeleton w="100%" h="100%" rounded="md" />
                        </GridItem>
                        <GridItem>
                            <Skeleton w="100%" h="100%" rounded="md" />
                        </GridItem>
                    </Grid>
                </Flex>
            </>
        );
    };

    if (token.isInvalid) return <TokenPriceInfoSkeleton />;

    return (
        <>
            <Flex w="100%" position="relative">
                <Grid
                    w="100%"
                    templateColumns="repeat(3, 1fr)"
                    templateRows={`auto auto ${scaledPixel(20)}`}
                    templateAreas={`
                        "currentPriceLabel deviationLabel projectionLabel"
                        "currentPrice deviation projection"
                        "currentPriceTag deviationTag projectionTag"
                    `}
                    columnGap={scaledPixel(8)}
                    textAlign="center"
                >
                    <GridItem
                        area="currentPriceLabel"
                        textStyle="tokenModalLandscape.prices.label"
                        // bgColor="#2F3546"
                        bgColor="spaceCadet"
                        borderTopLeftRadius={scaledPixel(6)}
                        pt={scaledPixel(8)}
                        pl={scaledPixel(8)}
                        pr={scaledPixel(8)}
                    >
                        <Text fontSize={scalePercentText}>List Price</Text>
                    </GridItem>
                    <GridItem
                        area="deviationLabel"
                        textStyle="tokenModalLandscape.prices.label"
                        // bgColor="#2F35467f"
                        bgColor="spaceCadet"
                        // borderTopRadius={scaledPixel(6)}
                        pt={scaledPixel(8)}
                        pl={scaledPixel(8)}
                        pr={scaledPixel(8)}
                    >
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            Deviation&nbsp;
                            <ClickHoverIconButton
                                mode="click"
                                isOpen={deviationInfoIsOpen}
                                onOpen={() => setDeviationInfoOpen(true)}
                                onClose={() => setDeviationInfoOpen(false)}
                                iconProps={{ boxSize: scaledPixel(16) }}
                                pl="2px"
                            />
                        </Flex>
                    </GridItem>
                    <GridItem
                        area="projectionLabel"
                        textStyle="tokenModalLandscape.prices.label"
                        // bgColor="#2F3546"
                        bgColor="spaceCadet"
                        borderTopRightRadius={scaledPixel(6)}
                        pt={scaledPixel(8)}
                        pl={scaledPixel(8)}
                        pr={scaledPixel(8)}
                    >
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            <b>BR</b>ITE Price&nbsp;
                            <ClickHoverIconButton
                                mode="click"
                                isOpen={briteInfoIsOpen}
                                onOpen={() => setBriteInfoOpen(true)}
                                onClose={() => setBriteInfoOpen(false)}
                                iconProps={{ boxSize: scaledPixel(16) }}
                                pl="2px"
                            />
                        </Flex>
                    </GridItem>

                    <GridItem
                        area="currentPrice"
                        textStyle="tokenModalLandscape.prices.price"
                        // bgColor="#2F3546"
                        bgColor="spaceCadet"
                        // pt={scaledPixel(6)}
                        pb={scaledPixel(6)}
                        pl={scaledPixel(15)}
                        pr={scaledPixel(15)}
                    >
                        <Flex
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            <TokenLayoutValue
                                value={token.currentPrice}
                                requiresAuth={false}
                                showOutOfRangeTooltip
                                showEthIcon
                                ethIconProps={{
                                    w: scaledPixel(11),
                                    mr: scaledPixel(4),
                                    mt: scaledPixel(-3),
                                    color: "rythm",
                                }}
                                mode="free"
                            />
                        </Flex>
                    </GridItem>
                    <GridItem
                        area="deviation"
                        textStyle="tokenModalLandscape.prices.deviation"
                        // bgColor="#2F35467f"
                        bgColor="spaceCadet"
                        // pt={scaledPixel(6)}
                        pb={scaledPixel(6)}
                        pl={scaledPixel(15)}
                        pr={scaledPixel(15)}
                    >
                        <Flex
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            <TokenLayoutValue
                                value={token.deviation}
                                showPositiveSign
                                showOutOfRangeTooltip
                                showEthIcon
                                ethIconProps={{
                                    w: scaledPixel(11),
                                    mr: scaledPixel(4),
                                    mt: scaledPixel(-3),
                                    color: "rythm",
                                }}
                                mode="free"
                            />
                        </Flex>
                    </GridItem>
                    <GridItem
                        area="projection"
                        textStyle="tokenModalLandscape.prices.projection"
                        // bgColor="#2F3546"
                        bgColor="spaceCadet"
                        // pt={scaledPixel(6)}
                        pb={scaledPixel(6)}
                        pl={scaledPixel(15)}
                        pr={scaledPixel(15)}
                    >
                        <Flex
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            <TokenLayoutValue
                                value={token.priceProjection}
                                showOutOfRangeTooltip
                                showEthIcon
                                ethIconProps={{
                                    w: scaledPixel(11),
                                    mr: scaledPixel(4),
                                    mt: scaledPixel(-3),
                                    color: "rythm",
                                }}
                                mode="free"
                            />
                        </Flex>
                    </GridItem>

                    <GridItem
                        area="currentPriceTag"
                        textStyle="tokenModalLandscape.prices.tag"
                        // bgColor={isHotOrCold == "" ? "#2F3546" : isHotOrCold == "hot" ? "#FD6000" : isHotOrCold}
                        bgColor={
                            isHotOrCold == ""
                                ? "spaceCadet"
                                : isHotOrCold == "hot"
                                ? "#FD6000"
                                : isHotOrCold
                        }
                        borderTop={
                            isHotOrCold == ""
                                ? "1px solid #6666"
                                : isHotOrCold == "hot"
                                ? "#FD6000"
                                : isHotOrCold
                        }
                        borderBottomRadius={scaledPixel(6)}
                    >
                        <Flex
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            {isHotOrCold == "" ? null : (
                                <>
                                    <b>{isHotOrCold}</b>&nbsp;Deal
                                </>
                            )}
                        </Flex>
                    </GridItem>
                    <GridItem
                        area="deviationTag"
                        textStyle="tokenModalLandscape.prices.tag"
                        // bgColor={isHotOrCold == "" ? "#2F35467f" : isHotOrCold == "hot" ? "#FD6000" : isHotOrCold}
                        bgColor={
                            isHotOrCold == ""
                                ? "spaceCadet"
                                : isHotOrCold == "hot"
                                ? "#FD6000"
                                : isHotOrCold
                        }
                        borderTop={
                            isHotOrCold == ""
                                ? "1px solid #6666"
                                : isHotOrCold == "hot"
                                ? "#FD6000"
                                : isHotOrCold
                        }
                        borderBottomRadius={scaledPixel(6)}
                    >
                        <Flex
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            {isAboveOrBelow == "" ? null : (
                                <>
                                    <b>{isAboveOrBelow}</b>priced
                                </>
                            )}
                        </Flex>
                    </GridItem>
                    <GridItem
                        area="projectionTag"
                        textStyle="tokenModalLandscape.prices.tag"
                        // bgColor={!isConnected ? "#2F3546" : "sorbet"}
                        bgColor={!isConnected ? "spaceCadet" : "sorbet"}
                        borderTop={isHotOrCold == "" ? "1px solid #6666" : "sorbet"}
                        borderBottomRadius={scaledPixel(6)}
                    >
                        <Flex
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            fontSize={scalePercentText}
                        >
                            {isConnected ? (
                                <>
                                    Confidence:&nbsp;
                                    <b>{token.confidence.toFixed(0)}%</b>
                                </>
                            ) : null}
                        </Flex>
                    </GridItem>
                </Grid>
                <TokenHotColdBadge
                    show={isListed && isConnected}
                    isHot={token.deviation > 0}
                    border={`1px solid white`}
                    padding={scaledPixel(4)}
                    w={scaledPixel(30)}
                    h={scaledPixel(30)}
                    bottom={scaledPixel(-5)}
                    left={scaledPixel(-12)}
                />
                {briteInfoIsOpen ? (
                    <TokenPriceInfoBriteTooltip
                        isOpen={briteInfoIsOpen}
                        onClose={(e) => setBriteInfoOpen(false)}
                        scale={scale}
                        position={tooltipPosition}
                    />
                ) : null}
                {deviationInfoIsOpen ? (
                    <TokenPriceInfoDeviationTooltip
                        isOpen={deviationInfoIsOpen}
                        onClose={(e) => setDeviationInfoOpen(false)}
                        scale={scale}
                        position={tooltipPosition}
                    />
                ) : null}
            </Flex>
        </>
    );
};

export default TokenPriceInfo;
