import React, { useEffect } from "react";
import useCollectionTokensStore from "@store/collectionTokensStore";
import TokenModalLandscape from "./TokenModalLandscape";
import TokenModalPortrait from "./TokenModalPortrait";
import useTokenModal from "@hooks/useTokenModal";
import { useRouter } from "next/router";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import Head from "next/head";
import useCollectionStore from "@store/collectionStore";

const TokenModal = (): JSX.Element => {
    const router = useRouter();
    const { tokenId } = router.query;
    const tokenStringId = tokenId;

    const collection = useCollectionStore((state) => state.collection);
    const queryParamsToken = tokenStringId
        ? useCollectionTokensStore.getState().tokensMap?.get(String(tokenStringId))
        : null;

    const selectedToken = useCollectionTokensStore.getState().selectedToken;

    const token = queryParamsToken || selectedToken;

    const { closeTokenModal } = useTokenModal();
    const { isPortrait, windowWidth } = useDebouncedWindowSize({
        useIsPortrait: true,
        useWindowWidth: true,
    });

    // handle the browser back button by closing the modal BEFORE changing history.
    useEffect(() => {
        if (tokenStringId) {
            router.beforePopState(() => {
                closeTokenModal();
                return true;
            });
        } else {
            router.beforePopState(null);
        }
    }, [tokenStringId]);

    if (tokenStringId == null) return null;

    return isPortrait || windowWidth < 500 ? (
        <>
            {token?.name ? (
                <Head>
                    <title key="title">Beyond Rarity - {token.name}</title>
                </Head>
            ) : tokenStringId ? (
                <Head>
                    <title key="title">
                        Beyond Rarity - {collection.name} - Token #{tokenStringId}
                    </title>
                </Head>
            ) : null}
            <TokenModalPortrait token={token} onModalClose={closeTokenModal} />
        </>
    ) : (
        <>
            {token?.name ? (
                <Head>
                    <title key="title">Beyond Rarity - {token.name}</title>
                </Head>
            ) : tokenStringId != null ? (
                <Head>
                    <title key="title">
                        Beyond Rarity - {collection.name} - Token #{tokenStringId}
                    </title>
                </Head>
            ) : null}
            <TokenModalLandscape token={token} onModalClose={closeTokenModal} />
        </>
    );
};

export default TokenModal;
