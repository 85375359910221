import React, { useEffect, useState } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { roundNumber } from "@utils/helpers";
import { FaCheck } from "react-icons/fa";

const CheckBox = ({ checked, onClick }): JSX.Element => {
    return (
        <Box
            layerStyle="filters.traits.value.checkbox"
            backgroundColor={checked ? "gold.500" : "none"}
            onClick={onClick}
        >
            {checked && <FaCheck size="9px" />}
        </Box>
    );
};

const MarketplaceCheckbox = ({
    marketplaceId,
    label,
    amount,
    tokensAmount,
    isChecked,
    onChecked,
    onUnchecked,
}: MarketplaceCheckboxProps): JSX.Element => {
    const [checked, setChecked] = useState<boolean>(isChecked);

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    const onCheckboxChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!checked) {
            onChecked?.(Number(marketplaceId));
        } else {
            onUnchecked?.(Number(marketplaceId));
        }
    };

    const percent = roundNumber((amount / tokensAmount) * 100, 2);

    return (
        <Flex layerStyle="filters.marketplaces.item" title={label}>
            <CheckBox checked={isChecked} onClick={onCheckboxChange} />
            <Box flex="1 0" textStyle="filters.traits.value.label" onClick={onCheckboxChange}>
                {label}
            </Box>
            <Text textStyle="filters.traits.value.amount">
                {amount} ({percent}%)
            </Text>
        </Flex>
    );
};

export default MarketplaceCheckbox;
