import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    Box,
} from "@chakra-ui/react";
import { TokenListSortMenu } from "@components/ui";
import useFiltersStore from "@store/filtersStore";
import shallow from "zustand/shallow";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { getSortOptions } from "@store/sessionStore";
import { getSortOrderLabels } from "@utils/helpers";

type TokenListSortAccordionProps = {};

const TokenListSortAccordion = ({}: TokenListSortAccordionProps): JSX.Element => {
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });
    const sortOptions = getSortOptions();

    const { sortBy, sortOrder } = useFiltersStore(
        (state) => ({
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
        }),
        shallow,
    );

    // TODO: Also use the generator in TokenListSortBar.tsx
    const { sortLabel, orderLabel } = getSortOrderLabels(sortBy, sortOrder);

    return (
        <Accordion allowToggle rounded="lg" mb={6} reduceMotion={!isDesktop}>
            <AccordionItem border="1px solid #464E6C" rounded="md" mb={2} bg="#2F3546">
                <AccordionButton _focus={{ ring: "none" }} py={2}>
                    <Box flex="1" textAlign="left" fontSize="14px">
                        Sort by {sortLabel} ({orderLabel})
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <TokenListSortMenu options={sortOptions} isMobile={true} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default TokenListSortAccordion;
