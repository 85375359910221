import {
    Box,
    Center,
    Flex,
    Grid,
    GridItem,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Skeleton,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useCollectionStore from "@store/collectionStore";
import TokenModalImagePlayer from "@components/tokenCommon/TokenModalImagePlayer";
import TokenModalMarketplaceButtons from "./TokenModalMarketplaceButtons";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";
import config from "@components/tokenModal/tokenModalConfig";
import TokenPriceInfo from "./tokenPriceInfo/TokenPriceInfo";
import TokenTraitsVibesPortrait from "./tokenTraitsVibes/TokenTraitsVibesPortrait";
import { useEffect, useRef, useState } from "react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import useCollectionTokensStore from "@store/collectionTokensStore";
import useTokenModal from "@hooks/useTokenModal";
import { useSwipeable } from "react-swipeable";
import usePersistedStore from "@store/persistedStore";
import PortalTooltip from "@components/ui/PortalTooltip";
import OwnedDiamondImage from "@components/images/OwnedDiamondImage";

type TokenModalPortraitProps = {
    token?: Token;
    onModalClose: () => void;
};

export const TokenModalPortrait = ({ token, onModalClose }: TokenModalPortraitProps) => {
    const [hasHover] = useMediaQuery(["(hover: hover)"]);
    const collection = useCollectionStore((state) => state.collection);
    const userOwnedTokens = useCollectionTokensStore((state) => state.userOwnedTokens);
    const tokenModalSwiped = usePersistedStore((state) => state.tokenModalSwiped);
    const filteredSortedPrevNextMap = useCollectionTokensStore(
        (state) => state.filteredSortedPrevNextMap,
    );
    const { showModalToken, closeTokenModal } = useTokenModal();
    const { windowWidth, windowHeight } = useDebouncedWindowSize(
        {
            useWindowHeight: true,
            useWindowWidth: true,
        },
        // { debounceRateMilliseconds: 0 },
    );
    const [explicitClosed, setExplicitClosed] = useState(false);

    const scale = Math.min(
        windowWidth / config.portrait.baseWidth,
        (windowHeight * 0.7) / config.portrait.baseWidth,
        1.2,
    );
    const imageWidth = Math.min(windowWidth * 0.9, windowHeight * 0.6);
    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    const tokenNameRef = useRef(null);
    const [istokenNameOverflown, settokenNameOverflown] = useState(false);

    const tokenIdRef = useRef(null);
    const [isTokenIdOverflown, setTokenIdOverflown] = useState(false);

    // added to fix some states where explicitly closed is stuck to true
    // if token is changed, it should always reset explicityly closed back to false.
    useEffect(() => {
        setExplicitClosed(false);
    }, [token]);

    useEffect(() => {
        const element = tokenNameRef?.current;
        if (element) {
            settokenNameOverflown(element?.scrollWidth > element?.clientWidth);
        }
    }, [tokenNameRef, windowWidth, token?.name]);

    useEffect(() => {
        const element = tokenIdRef?.current;
        // console.debug("detected token ref change: ", element);
        if (element) {
            // console.debug("updating id overflown: ", element?.scrollWidth > element?.clientWidth);
            setTokenIdOverflown(element?.scrollWidth > element?.clientWidth);
        }
    }, [tokenIdRef, windowWidth, token?.tokenStringId]);

    const handleOnClose = () => {
        setExplicitClosed(true);
    };

    const handleOnCloseComplete = () => {
        onModalClose();
    };

    const prevNextData = filteredSortedPrevNextMap.get(token?.tokenStringId);

    const handleOnPrev = () => {
        if (prevNextData?.prevToken) showModalToken(prevNextData?.prevToken?.tokenStringId);
    };

    const handleOnNext = () => {
        if (prevNextData?.nextToken) showModalToken(prevNextData?.nextToken?.tokenStringId);
    };

    const setSwiped = () => {
        usePersistedStore.setState((oldState) => {
            if (oldState.tokenModalSwiped) return;

            return {
                tokenModalSwiped: true,
            };
        });
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            setSwiped();
            handleOnNext();
        },
        onSwipedRight: () => {
            setSwiped();
            handleOnPrev();
        },
    });

    if (!token) return null;

    const isOpen = !!token && !explicitClosed;

    return (
        <Modal
            onClose={handleOnClose}
            onCloseComplete={handleOnCloseComplete}
            isOpen={isOpen}
            size="full"
        >
            <ModalOverlay bgColor="#000c" backdropFilter="auto" backdropBlur="4px" />
            <ModalContent bgColor="transparent" {...swipeHandlers}>
                <ModalBody
                    display="flex"
                    w="100%"
                    p="20px 15px 50px 15px"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Flex position="relative" direction="column">
                        <Flex
                            w={imageWidth + "px"}
                            minH="calc(100vh - 40px)"
                            direction="column"
                            position="relative"
                            fontWeight="light"
                            bgColor="spaceCadetLight"
                            borderRadius={scaledPixel(10)}
                            overflow="hidden"
                        >
                            {token && (
                                <Grid flex={1} templateRows={`${imageWidth}px 1fr`}>
                                    <GridItem>
                                        <TokenModalImagePlayer
                                            name={token.name}
                                            collectionName={collection.name}
                                            tokenStringId={token.tokenStringId}
                                            imageUrl={token?.imageUrl}
                                            animationUrl={token?.animationUrl}
                                        />
                                        {(token?.own ||
                                            userOwnedTokens?.includes(token.tokenStringId)) && (
                                            <Flex
                                                position="absolute"
                                                direction="row"
                                                bgColor="raisinBlackAlpha.70"
                                                borderRadius={scaledPixel(4)}
                                                p={scaledPixel(6)}
                                                top={scaledPixel(12)}
                                                left={scaledPixel(12)}
                                                justify="center"
                                                align="center"
                                                gap={1.5}
                                            >
                                                <OwnedDiamondImage w={scaledPixel(16)} />
                                                <Box
                                                    fontSize={scaledPixel(25)}
                                                    lineHeight="100%"
                                                    mt={0.4}
                                                >
                                                    Owned
                                                </Box>
                                            </Flex>
                                        )}
                                    </GridItem>

                                    <GridItem position="relative" p={scaledPixel(20)} pb="25px">
                                        {token.isInvalid && (
                                            <Center layerStyle="tokenModal.invalid.overlay">
                                                <Text textStyle="invalidTokenModal.title">
                                                    Oops!
                                                </Text>
                                                <Text textStyle="invalidTokenModal.copy">
                                                    Looks like you entered
                                                    <br />
                                                    an Invalid Token ID...
                                                </Text>
                                            </Center>
                                        )}
                                        <Grid
                                            h="100%"
                                            w="100%"
                                            gap={scaledPixel(20)}
                                            templateRows="auto auto 1fr"
                                        >
                                            <GridItem>
                                                <Grid
                                                    templateColumns="1fr auto"
                                                    templateAreas={`
                                                    "name links"
                                                `}
                                                    columnGap={scaledPixel(20)}
                                                >
                                                    <GridItem area="name">
                                                        <Skeleton
                                                            h="100%"
                                                            w={token.isInvalid ? "60%" : "100%"}
                                                            isLoaded={!token.isInvalid}
                                                            rounded="md"
                                                        >
                                                            <Flex
                                                                h="100%"
                                                                textStyle="tokenModalPortrait.name"
                                                                alignItems="end"
                                                            >
                                                                <Box
                                                                    textStyle="tokenModalPortrait.name"
                                                                    w={scaledPixel(220)}
                                                                >
                                                                    {istokenNameOverflown ||
                                                                    token.name.length > 22 ? (
                                                                        <PortalTooltip
                                                                            content={token.name}
                                                                            whiteSpace="normal"
                                                                            wordBreak="break-all"
                                                                            allowSelect
                                                                        >
                                                                            <Box
                                                                                fontSize={
                                                                                    scalePercentText
                                                                                }
                                                                                ref={tokenNameRef}
                                                                                textOverflow="ellipsis"
                                                                                overflow="hidden"
                                                                                whiteSpace="nowrap"
                                                                            >
                                                                                {token.name}
                                                                            </Box>
                                                                        </PortalTooltip>
                                                                    ) : (
                                                                        <Box
                                                                            fontSize={
                                                                                scalePercentText
                                                                            }
                                                                            ref={tokenNameRef}
                                                                            textOverflow="ellipsis"
                                                                            overflow="hidden"
                                                                            whiteSpace="nowrap"
                                                                        >
                                                                            {token.name}
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </Flex>
                                                        </Skeleton>
                                                    </GridItem>
                                                    <GridItem area="links">
                                                        <Skeleton
                                                            isLoaded={!token.isInvalid}
                                                            h="100%"
                                                            rounded="md"
                                                        >
                                                            <TokenModalMarketplaceButtons
                                                                token={token}
                                                                boxSize={scaledPixel(24)}
                                                            />
                                                        </Skeleton>
                                                    </GridItem>
                                                </Grid>
                                                <Grid
                                                    mt={scaledPixel(10)}
                                                    templateColumns="1fr auto"
                                                    templateAreas={`
                                                    "tokenId rank"
                                                `}
                                                    columnGap={scaledPixel(20)}
                                                >
                                                    <GridItem area="tokenId">
                                                        <Skeleton
                                                            isLoaded={!token.isInvalid}
                                                            h="100%"
                                                            w={token.isInvalid ? "70%" : "100%"}
                                                            rounded="md"
                                                        >
                                                            <Flex h="100%" maxW="100%" align="end">
                                                                <Box textStyle="tokenModalPortrait.idLabel">
                                                                    <Box
                                                                        fontSize={scalePercentText}
                                                                    >
                                                                        Token ID&nbsp;
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    textStyle="tokenModalPortrait.id"
                                                                    w={scaledPixel(150)}
                                                                >
                                                                    {isTokenIdOverflown ||
                                                                    token.tokenStringId.length >
                                                                        15 ? (
                                                                        <PortalTooltip
                                                                            content={
                                                                                token.tokenStringId
                                                                            }
                                                                            whiteSpace="normal"
                                                                            wordBreak="break-all"
                                                                            allowSelect
                                                                        >
                                                                            <Box
                                                                                fontSize={
                                                                                    scalePercentText
                                                                                }
                                                                                ref={tokenIdRef}
                                                                                textOverflow="ellipsis"
                                                                                overflow="hidden"
                                                                                whiteSpace="nowrap"
                                                                            >
                                                                                {
                                                                                    token.tokenStringId
                                                                                }
                                                                            </Box>
                                                                        </PortalTooltip>
                                                                    ) : (
                                                                        <Box
                                                                            fontSize={
                                                                                scalePercentText
                                                                            }
                                                                            ref={tokenIdRef}
                                                                            textOverflow="ellipsis"
                                                                            overflow="hidden"
                                                                            whiteSpace="nowrap"
                                                                        >
                                                                            {token.tokenStringId}
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </Flex>
                                                        </Skeleton>
                                                    </GridItem>
                                                    <GridItem area="rank" textAlign="right">
                                                        <Flex
                                                            h="100%"
                                                            justifyContent="end"
                                                            alignItems="end"
                                                        >
                                                            <Skeleton
                                                                isLoaded={!token.isInvalid}
                                                                h="100%"
                                                                w={token.isInvalid ? "90%" : "100%"}
                                                                rounded="md"
                                                                justifyContent="end"
                                                            >
                                                                <Flex
                                                                    h="100%"
                                                                    justifyContent="end"
                                                                    alignItems="end"
                                                                >
                                                                    <Box textStyle="tokenModalPortrait.rankLabel">
                                                                        <Box
                                                                            fontSize={
                                                                                scalePercentText
                                                                            }
                                                                        >
                                                                            Rarity Rank&nbsp;
                                                                        </Box>
                                                                    </Box>
                                                                    <Box textStyle="tokenModalPortrait.rank">
                                                                        <Box
                                                                            fontSize={
                                                                                scalePercentText
                                                                            }
                                                                        >
                                                                            #{token.rank}
                                                                        </Box>
                                                                    </Box>
                                                                </Flex>
                                                            </Skeleton>
                                                        </Flex>
                                                    </GridItem>
                                                </Grid>
                                            </GridItem>

                                            <GridItem>
                                                <Flex
                                                    w="100%"
                                                    h="100%"
                                                    minH={
                                                        token.isInvalid
                                                            ? scaledPixel(120)
                                                            : undefined
                                                    }
                                                >
                                                    <TokenPriceInfo
                                                        token={token}
                                                        scale={scale}
                                                        tooltipPosition="top"
                                                    />
                                                </Flex>
                                            </GridItem>

                                            <GridItem>
                                                <TokenTraitsVibesPortrait
                                                    collection={collection}
                                                    token={token}
                                                    scale={scale * 0.8}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                </Grid>
                            )}
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
            <Flex
                zIndex="2000"
                display={isOpen ? undefined : "none"}
                position="fixed"
                bottom="10px"
                right="10px"
                w="40px"
                h="40px"
                minH="40px"
                rounded="50em"
                color="steelblue.900"
                bgColor="gold.500"
                boxShadow="0px 0px 20px 10px #0009"
                justifyContent="center"
                alignItems="center"
                _hover={{ bgColor: "gold.500", cursor: "pointer" }}
                onClick={handleOnClose}
            >
                <CloseIcon w="12px" h="12px" />
            </Flex>
            {!tokenModalSwiped &&
                (prevNextData?.prevToken != null || prevNextData?.nextToken != null) && (
                    <Flex
                        zIndex="1900"
                        display={isOpen ? undefined : "none"}
                        position="fixed"
                        bottom="0px"
                        left="auto"
                        right="auto"
                        justify="center"
                        align="center"
                        p="20px"
                        w="100%"
                        bgGradient="linear(to-b, #0000 0%, #000C 60%)"
                        color="lavenderGray"
                    >
                        Swipe <Icon as={FiChevronLeft} fontSize={scaledPixel(30)} /> or{" "}
                        <Icon as={FiChevronRight} fontSize={scaledPixel(30)} /> to change token
                    </Flex>
                )}
        </Modal>
    );
};

export default TokenModalPortrait;
