import { Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { scaledPixel as scaledPixelRoot, getScaleValues } from "@utils/helpers";

type TokenPriceInfoBriteTooltipProps = {
    isOpen?: boolean;
    onClose?: Function;
    scale?: number;
    position?: "top" | "bottom";
};

export const TokenPriceInfoBriteTooltip = ({
    isOpen,
    onClose,
    scale = 1,
    position = "bottom",
}: TokenPriceInfoBriteTooltipProps) => {
    const wrapperRef = useRef(null);

    const [internalIsOpen, setInternalIsOpen] = useState(isOpen);

    const handleOnClose = (event) => {
        setInternalIsOpen(false);
        if (onClose) onClose(event);
    };

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleOnClose(event);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    useOutsideAlerter(wrapperRef);

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            event.stopPropagation();
            handleOnClose(event);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, true);

        return () => {
            document.removeEventListener("keydown", escFunction, true);
        };
    }, []);

    const scaledPixel = (pixels) => {
        return scaledPixelRoot(pixels, scale);
    };
    const { scalePercent, scalePercentText } = getScaleValues(scale);

    return (
        <>
            <Flex
                ref={wrapperRef}
                display={internalIsOpen ? undefined : "none"}
                position="absolute"
                direction="column"
                top={position == "bottom" ? "100%" : undefined}
                bottom={position == "top" ? "100%" : undefined}
                w="100%"
                filter={"drop-shadow(0px 0px 2px #000);"}
                zIndex="tooltip"
            >
                {position == "bottom" ? (
                    <Grid
                        templateColumns={`repeat(3, 1fr)`}
                        columnGap={scaledPixel(8)}
                        textAlign="center"
                    >
                        <GridItem />
                        <GridItem />
                        <GridItem>
                            <Flex
                                pos="relative"
                                w="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Flex
                                    w={scaledPixel(20)}
                                    h={scaledPixel(20)}
                                    mt={scaledPixel(10)}
                                    mb={scaledPixel(-10)}
                                    // outline="1px solid white"
                                    borderTopLeftRadius={scaledPixel(4)}
                                    transform="rotate(45deg)"
                                    zIndex={0}
                                />
                                <Flex
                                    pos="absolute"
                                    w={scaledPixel(20)}
                                    h={scaledPixel(20)}
                                    mt={scaledPixel(10)}
                                    mb={scaledPixel(-10)}
                                    borderTopLeftRadius={scaledPixel(4)}
                                    transform="rotate(45deg)"
                                    // bgGradient="linear(to-br, blue.darker 0%, blue.darker 50%, transparent 50%, transparent 100%)"
                                    bgColor="blue.darker"
                                    zIndex={2}
                                />
                            </Flex>
                        </GridItem>
                    </Grid>
                ) : null}
                <Flex
                    pos="relative"
                    w="100%"
                    // outline="1px solid white"
                    bgColor="blue.darker"
                    borderRadius={scaledPixel(10)}
                    zIndex={1}
                    overflow="hidden"
                >
                    <Flex
                        w="100%"
                        bgColor="blue.darker"
                        justifyContent="center"
                        alignItems="center"
                        p={scaledPixel(15)}
                        zIndex={3}
                    >
                        <Flex
                            direction="column"
                            h="100%"
                            w="100%"
                            justifyContent="center"
                            alignItems="center"
                            rowGap={scaledPixel(8)}
                        >
                            <Flex
                                textStyle="tokenModalLandscape.tooltip.header"
                                justifyContent="center"
                                fontSize={scalePercentText}
                            >
                                What&apos;s the&nbsp;<b>BR</b>ITE Price?
                            </Flex>
                            <Flex
                                w="100%"
                                textStyle="tokenModalLandscape.tooltip.subheader"
                                justifyContent="center"
                                fontSize={scalePercentText}
                            >
                                Beyond Rarity&apos;s Intelligent Trusted Estimate
                            </Flex>
                            <Flex w="100%">
                                <Flex
                                    w="100%"
                                    direction="column"
                                    textStyle="tokenModalLandscape.tooltip.body"
                                    justifyContent="center"
                                    alignItems="start"
                                    px={scaledPixel(16)}
                                    gap={scaledPixel(8)}
                                >
                                    <Flex fontSize={scalePercentText}>
                                        Our algorithm integrates sales history and other complex
                                        factors to create the most accurate price projection and
                                        help you make your best decisions.
                                    </Flex>
                                    <Flex fontSize={scalePercentText}>
                                        Confidence is based trading activity - the higher the
                                        frequency, the more reference points and better confidence.
                                    </Flex>
                                </Flex>
                                <Flex w={scaledPixel(250)} px={scaledPixel(20)}>
                                    <Image
                                        src="/images/brite-tooltip.png"
                                        alt="Beyond Rarity - BRITE (Beyond Rarity Intelligent Trusted Estimate)"
                                        objectFit="contain"
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex
                        position="absolute"
                        top={scaledPixel(8)}
                        right={scaledPixel(8)}
                        w={scaledPixel(16)}
                        h={scaledPixel(16)}
                        rounded="50%"
                        color="steelblue.900"
                        bgColor="gray.faint"
                        boxShadow={`0px 0px ${scaledPixel(20)} ${scaledPixel(10)} #0009`}
                        justifyContent="center"
                        alignItems="center"
                        _hover={{ bgColor: "gold.500", cursor: "pointer" }}
                        onClick={handleOnClose}
                        zIndex="tooltip"
                    >
                        <CloseIcon w={scaledPixel(8)} h={scaledPixel(8)} />
                    </Flex>
                </Flex>
                {position == "top" ? (
                    <Grid
                        templateColumns={`repeat(3, 1fr)`}
                        columnGap={scaledPixel(8)}
                        textAlign="center"
                    >
                        <GridItem />
                        <GridItem />
                        <GridItem>
                            <Flex
                                pos="relative"
                                w="100%"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Flex
                                    w={scaledPixel(20)}
                                    h={scaledPixel(20)}
                                    mt={scaledPixel(-10)}
                                    mb={scaledPixel(10)}
                                    // outline="1px solid white"
                                    borderTopLeftRadius={scaledPixel(4)}
                                    transform="rotate(45deg)"
                                    zIndex={0}
                                />
                                <Flex
                                    pos="absolute"
                                    w={scaledPixel(20)}
                                    h={scaledPixel(20)}
                                    mt={scaledPixel(-10)}
                                    mb={scaledPixel(10)}
                                    borderTopLeftRadius={scaledPixel(4)}
                                    transform="rotate(45deg)"
                                    // bgGradient="linear(to-br, blue.darker 0%, blue.darker 50%, transparent 50%, transparent 100%)"
                                    bgColor="blue.darker"
                                    zIndex={2}
                                />
                            </Flex>
                        </GridItem>
                    </Grid>
                ) : null}
            </Flex>
        </>
    );
};

export default TokenPriceInfoBriteTooltip;
