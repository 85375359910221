import React from "react";
import { Box } from "@chakra-ui/react";
import { SkeletonBox, ToggleButton } from "@components/ui";
import useFiltersStore, { setSaleStatusFilter } from "@store/filtersStore";

type SaleStatusFilterProp = {
    loading?: boolean;
};

const SaleStatusFilter = ({ loading }: SaleStatusFilterProp): JSX.Element => {
    const saleStatus = useFiltersStore((state) => state.saleStatus);

    const onSaleStatusChange = (saleStatus: string) => {
        if (saleStatus === "Show All") {
            setSaleStatusFilter("all");
        } else if (saleStatus === "For Sale") {
            setSaleStatusFilter("onSale");
        }
    };

    if (loading) return <SkeletonBox height="30px" mb={4} rounded="50em" />;

    return (
        <Box mb={4}>
            <ToggleButton
                optionA="Show All"
                optionB="For Sale"
                fullWidth
                height="30px"
                value={saleStatus === "all" ? "Show All" : "For Sale"}
                onChange={onSaleStatusChange}
            />
        </Box>
    );
};

export default SaleStatusFilter;
