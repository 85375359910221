import React from "react";
import { Flex } from "@chakra-ui/react";
import { BreadcrumbChip } from "@components/ui";

type VibesListProps = {
    values: FilteredVibes;
    onRemove?: (VibeId) => void;
};

const VibesList = ({ values, onRemove }: VibesListProps): JSX.Element => {
    const onRemoveVibe = (vibeId: VibeId): void => {
        onRemove?.(vibeId);
    };
    return (
        <Flex gap="2px" flexWrap="wrap">
            <BreadcrumbChip label="Vibes" notRemovable />
            {[...values.entries()].map(([id, name]) => (
                <BreadcrumbChip
                    key={`${name}-${id}`}
                    value={name}
                    onClick={onRemoveVibe}
                    onClickParam={id}
                />
            ))}
        </Flex>
    );
};

export default VibesList;
