import React, { useEffect, useRef, useState } from "react";
import shallow from "zustand/shallow";
import { MARKETPLACES } from "@config/app";
import useFiltersStore, {
    resetRankFilter,
    resetPriceFilter,
    removeTraitFilter,
    resetMinDeviationFilter,
    removeTokenIdFilter,
    removeMarketplaceFilter,
    resetFilters,
    hasFiltersApplied,
    removeVibeFilter,
    resetSaleStatusFilter,
    hasMarketplaceFilterApplied,
} from "@store/filtersStore";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import useCollectionTokensStore from "@store/collectionTokensStore";
import { HStack, VStack, Text, Flex } from "@chakra-ui/react";
import {
    BreadcrumbChip,
    TraitValuesList,
    VibesList,
    BreadcrumbButton,
    SkeletonBox,
} from "@components/ui";
import { isValidRange, isValidNumber } from "@utils/helpers";
import TokenIdsList from "./TokenIdsList";
import LastListingsUpdate from "./LastListingsUpdate";

// TODO: Move the formatter to an external helper to avoid creating multiple instances
// native JS number formatter
const numberFormatter = new Intl.NumberFormat();

const MIN_LIVE_UPDATE_WIDTH = 110;

const BreadcrumbsList = (): JSX.Element => {
    const {
        minPrice,
        maxPrice,
        priceFilterLabel,
        minRank,
        maxRank,
        rankFilterLabel,
        minDeviation,
        marketplaces,
        saleStatus,
        tokenStringIds,
        traits,
        vibes,
    } = useFiltersStore(
        (state) => ({
            minPrice: state.minPrice,
            maxPrice: state.maxPrice,
            priceFilterLabel: state.priceFilterLabel,
            minRank: state.minRank,
            maxRank: state.maxRank,
            rankFilterLabel: state.rankFilterLabel,
            minDeviation: state.minDeviation,
            marketplaces: state.marketplaces,
            saleStatus: state.saleStatus,
            tokenStringIds: state.tokenStringIds,
            traits: state.traits,
            vibes: state.vibes,
        }),
        shallow,
    );
    const [liveIndicator, setLiveIndicator] = useState<HTMLDivElement>();
    const [liveIndicatorMinWidth, setLiveIndicatorMinWidth] =
        useState<number>(MIN_LIVE_UPDATE_WIDTH);
    const liveIndicatorMinWidthRef = useRef<number>(MIN_LIVE_UPDATE_WIDTH);
    const liveIndicatorResizeObserver = useRef<ResizeObserver>();

    const loadingTokens = useCollectionTokensStore((state) => state.loading);
    const filteredTokens = useCollectionTokensStore((state) => state.filteredTokens);
    const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });

    useEffect(() => {
        if (liveIndicator && !liveIndicatorResizeObserver.current) {
            liveIndicatorResizeObserver.current = new ResizeObserver((entries) => {
                if (entries.length && entries[0]?.contentRect) {
                    const { width } = entries[0].contentRect;

                    if (width > liveIndicatorMinWidthRef.current) {
                        liveIndicatorMinWidthRef.current = Math.ceil(width + 5);
                        setLiveIndicatorMinWidth(Math.ceil(width + 5));
                    }
                }
            });

            liveIndicatorResizeObserver.current.observe(liveIndicator);
        }
    }, [liveIndicator]);

    useEffect(() => {
        return () => {
            if (liveIndicatorResizeObserver.current && liveIndicator) {
                liveIndicatorResizeObserver.current.unobserve(liveIndicator);
            }
        };
    }, []);

    const onRemoveTokenId = (tokenStringId: TokenStringId): void => {
        removeTokenIdFilter(tokenStringId);
    };

    const onRemoveTraitValue = (traitId: TraitId, traitValueId: number): void => {
        removeTraitFilter(traitId, traitValueId);
    };

    const onRemoveVibe = (vibeId: VibeId): void => {
        removeVibeFilter(vibeId);
    };

    const hasSaleStatusFilter = saleStatus === "onSale";
    const hasRankFilter = isValidRange(minRank, maxRank);
    const hasPriceFilter = isValidRange(minPrice, maxPrice);
    const hasMinDeviationFilter = isValidNumber(minDeviation);
    const hasMarketplaceFilter = marketplaces?.size > 0;
    const hasTokenIdsFilter = tokenStringIds?.length > 0;
    const hasTraitsFilter = traits?.size > 0;
    const hasVibesFilters = vibes?.size > 0;
    const hasBreadCrumbs = hasFiltersApplied() || hasMarketplaceFilterApplied();

    const renderChips = (): JSX.Element => {
        return (
            <>
                {hasTokenIdsFilter && (
                    <TokenIdsList tokenStringIds={tokenStringIds} onRemove={onRemoveTokenId} />
                )}
                {hasRankFilter && (
                    <BreadcrumbChip
                        label="Rarity Rank"
                        value={rankFilterLabel || `${minRank}-${maxRank}`}
                        onClick={resetRankFilter}
                    />
                )}
                {hasPriceFilter && (
                    <BreadcrumbChip
                        label="Price"
                        value={priceFilterLabel || `${minPrice}-${maxPrice}`}
                        showEthIcon
                        onClick={resetPriceFilter}
                    />
                )}
                {hasMinDeviationFilter && (
                    <BreadcrumbChip
                        label="Min Deviation"
                        value={String(minDeviation)}
                        showEthIcon
                        onClick={resetMinDeviationFilter}
                        maxWidth={160}
                    />
                )}
                {hasMarketplaceFilter && (
                    <Flex gap="2px" flexWrap="wrap">
                        <BreadcrumbChip label="Marketplace" notRemovable />
                        {[...marketplaces.keys()].map((marketplaceId) => (
                            <BreadcrumbChip
                                key={`marketplace-${marketplaceId}`}
                                value={MARKETPLACES[marketplaceId - 1].name}
                                onClick={removeMarketplaceFilter}
                                onClickParam={marketplaceId}
                            />
                        ))}
                    </Flex>
                )}
                {hasSaleStatusFilter && (
                    <BreadcrumbChip value="For Sale" onClick={resetSaleStatusFilter} />
                )}
                {hasTraitsFilter &&
                    [...traits.values()].map((trait) => (
                        <TraitValuesList
                            key={trait.name}
                            traitId={trait.id}
                            traitName={trait.name}
                            values={trait.values}
                            onRemove={onRemoveTraitValue}
                        />
                    ))}
                {hasVibesFilters && <VibesList values={vibes} onRemove={onRemoveVibe} />}
                {hasBreadCrumbs && <BreadcrumbButton label="CLEAR ALL" onClick={resetFilters} />}
            </>
        );
    };

    return isDesktop ? (
        <HStack gap="1px" minH="28px">
            {loadingTokens ? (
                <SkeletonBox w="80px" h="24px" bg="#464E6C" />
            ) : (
                <Flex
                    ref={setLiveIndicator}
                    direction="column"
                    h="28px"
                    minW={`${liveIndicatorMinWidth}px`}
                >
                    <Text textStyle="itemsAmount" lineHeight="100%">
                        {numberFormatter.format(filteredTokens.length)} items
                    </Text>
                    <LastListingsUpdate />
                </Flex>
            )}
            {renderChips()}
        </HStack>
    ) : hasBreadCrumbs ? (
        <VStack alignItems="flex-start">
            <HStack gap="1px" flexWrap="wrap" w="100%">
                {renderChips()}
            </HStack>
        </VStack>
    ) : (
        <Text
            display="block"
            textAlign="center"
            h="28px"
            lineHeight="32px"
            textStyle="itemsAmount"
            color="gray"
            fontWeight={300}
        >
            No filters applied.
        </Text>
    );
};

export default BreadcrumbsList;
