import { Box, Flex, Icon, IconProps } from "@chakra-ui/react";
import { TiSortNumerically } from "react-icons/ti";
import { FaHashtag } from "react-icons/fa";
import PortalTooltip from "./PortalTooltip";
import useCollectionStore from "@store/collectionStore";

type RankedNumericallyTooltipIconProps = { usePortal?: boolean; iconProps?: IconProps };

export const RankedNumericallyTooltipIcon = ({
    usePortal,
    iconProps,
}: RankedNumericallyTooltipIconProps) => {
    const collection = useCollectionStore((state) => state.collection);

    return (
        <>
            <PortalTooltip
                content={
                    <Flex
                        direction="column"
                        maxW={["85vw", "85vw", "360px"]}
                        color="lavenderGray"
                        fontSize={["sm", "sm", "md"]}
                        gap={3}
                    >
                        {collection.collectionId == "ginos-big-town-chef-genesis-chefs" ? (
                            <Box>
                                This trait is categorized as a utility/numerical trait and its
                                frequency/rarity is excluded from the Beyond Rarity ranking.
                            </Box>
                        ) : (
                            <Box>
                                This trait category is scored based on their numerical value, not by
                                its rarity/frequency of occurrences.
                            </Box>
                        )}
                    </Flex>
                }
                whiteSpace="normal"
                edgeGutter={10}
            >
                <Icon
                    as={FaHashtag}
                    color="lavenderGray"
                    border="1px solid"
                    borderColor="lavenderGray"
                    borderRadius="30%"
                    mt="-.15em"
                    p=".1em"
                    fontSize="90%"
                    {...iconProps}
                />
            </PortalTooltip>
        </>
    );
};

export default RankedNumericallyTooltipIcon;
